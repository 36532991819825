var iconData = [
    {icon: 'fa-solid fa-house'},
    {icon: 'fa-brands fa-facebook'},
    {icon: 'fa-solid fa-magnifying-glass'},
    {icon: 'fa-solid fa-user'},
    {icon: 'fa-brands fa-facebook'},
    {icon: 'fa-solid fa-check'},
    {icon: 'fa-brands fa-twitter'},
    {icon: 'fa-solid fa-image'},
    {icon: 'fa-regular fa-image'},
    {icon: 'fa-brands fa-instagram'},
    {icon: 'fa-solid fa-phone'},
    {icon: 'fa-brands fa-tiktok'},
    {icon: 'fa-solid fa-bars'},
    {icon: 'fa-solid fa-envelope'},
    {icon: 'fa-regular fa-heart'},
    {icon: 'fa-solid fa-arrow-right'},
    {icon: 'fa-brands fa-discord'},
    {icon: 'fa-brands fa-youtube'},
    {icon: 'fa-solid fa-cloud'},
    {icon: 'fa-solid fa-comment'},
    {icon: 'fa-regular fa-comment'},
    {icon: 'fa-solid fa-caret-up'},
    {icon: 'fa-solid fa-xmark'},
];
export default iconData ;