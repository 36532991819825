import React, { useEffect, useContext, useState } from "react";
import config from "../../services/config.js";
import { Translation } from "../../components/Translation";
import { MainTranslationContexts } from "../../context/MainTranslationContexts";

function MarketingMappingIndex() {

    const { translations } = useContext(MainTranslationContexts);
    const [data, setData] = useState([]);

    const marketingMappingHeaders = [
      {
        label :  "Lead Source"
      },
      {
        label :  "Lead Medium"
      },
      {
        label :  "UTM Source"
      },
      {
        label :  "UTM Medium"
      },
      {
        label :  "Final Lead Source"
      },
      {
        label :  "Final Lead Medium"
      }
    ]

    const getRules = async () => {
        const response = await fetch(`${config.serviceUrl}/marketing-mapping`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = await response.json();

        setData(data);
    }

    useEffect(() => {
        getRules();
    }, []);

    return (
        <div className="allUsers">
            <div className="container-fluid">
                <div className="row clearfix">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header borderblue">
                                <h3 className="card-title">
                                    {Translation(translations, "Marketing Mapping")}
                                </h3>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-hover js-basic-example dataTable table_custom border-style spacing5 td-align-middle">
                                        <thead>
                                            <tr>
                                                {marketingMappingHeaders.map((item, index) => {
                                                    return <td key={index}>{item.label}</td>;
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(data) && data.map((item) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>
                                                            <span>{item?.lead_source}</span>
                                                        </td>
                                                        <td>
                                                            <span>{item?.lead_medium}</span>
                                                        </td>
                                                        <td>
                                                            <span>{item?.utm_source}</span>
                                                        </td>
                                                        <td>
                                                            <span>{item?.utm_medium}</span>
                                                        </td>
                                                        <td>
                                                            <span>{item?.final_lead_source?.source_name}</span>
                                                        </td>
                                                        <td>
                                                            <span>{item?.final_lead_medium?.source_name}</span>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    {/* <Pagination current={currentPage} defaultCurrent={1} pageSize={Number(limit)} defaultPageSize={5} total={totalleads} onChange={submit1} /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MarketingMappingIndex;