import React, { useState, useEffect, useContext, useRef } from 'react'
import { MainAuthPermissionsContext } from '../context/MainAuthPermissionsContext';
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Modal from "react-bootstrap/Modal";
import { MainTranslationContexts } from '../context/MainTranslationContexts';
import { Translation } from '../components/Translation';
import { FaSearch } from "react-icons/fa";
import usePost from '../customHooks/usePost';
import config from "../services/config.js";
import { DatePicker } from 'antd';
import { toast } from "react-toastify";
import swal from 'sweetalert';
import GoogleSyn_event_modal from '../Calendar/GoogleSyn_event_modal';
import { getTokenSession } from '../utils/common';
import axios from 'axios';
dayjs.extend(utc);
dayjs.extend(timezone);
function EditLeadAction({ ...props }) {
  const { Id, datasAction, allData, actionData, modules, WebSocketAction, assignId, ForGooglesync } = { ...props };
  const { permissions } = useContext(MainAuthPermissionsContext)
  const { translations } = useContext(MainTranslationContexts);
  const [assigntoImg, setAssigntoImg] = useState();
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");


  const [dateValue, setDateValue] = useState(`${year}-${month}-${day}T${hours}:${minutes}`);
  const [ownerhidden, setOwnerhidden] = useState(assignId);
  const [searchval, setSearchval] = useState(datasAction?.assigned_to_name || datasAction?.f_name);
  const [listOpen, setListOpen] = useState(false);
  const [showEdit2, setShowEdit2] = useState(false);
  const [pipelines, setPipelines] = useState(Array.isArray(actionData?.actionPipelines) && actionData?.actionPipelines[0]?.db_id);
  const [priority, setPriority] = useState(Array.isArray(actionData?.actionPriorityData) && actionData?.actionPriorityData[0]?.priority_id);
  const [title, setTitle] = useState("");
  const [sync_user_modal, setsync_user_modal] = useState(false)
  const inputElement = useRef();
  const ownerRef = useRef(null);
  const [resowner, apiMethodowner] = usePost();
  const [createAction, apiMethodCreateAction] = usePost('')
  const [Assgns, setAssgns] = useState({
    "id": assignId,
    "image": modules === "meeting" ? datasAction?.avatar : datasAction?.assigned_to_avatar,
  })
  const handleClick = (item) => {
    if (item?.lead_follower !== "yes") {
      setSearchval(`${item.uname} (${item.role_name})`);
      setAssgns({
        "id": item.id,
        "image": item.avatar,
      })

    }
    setListOpen(false);
  };
  useEffect(() => {
    if (datasAction) {
      if (modules == "meeting") {
        setAssigntoImg(datasAction?.avatar)
        setSearchval(datasAction?.f_name)
        setAssgns({ id: assignId, image: datasAction?.avatar })
      } else {
        setAssigntoImg(datasAction?.assigned_to_avatar)
        setAssgns({ id: datasAction?.lead_assigned_to, image: datasAction?.assigned_to_avatar })
        setSearchval(datasAction?.assigned_to_name)
      }
    }
  }, [datasAction]);
  const handleClose = () => {
    setShowEdit2(false)
  }
  const handleSubmitAssign = () => {
    setOwnerhidden(Assgns.id);
    setAssigntoImg(Assgns.image);
    setShowEdit2(false)
  }
  const handleList = () => {
    let formdataOwner = new FormData();
    formdataOwner.append("userType", "typeSearch");
    formdataOwner.append("query", searchval);
    formdataOwner.append("uLead", Id);
    apiMethodowner("postSpecifiesUsers", formdataOwner);
    setListOpen(!listOpen);
  };
  const handleAssignModalOpen = () => {
    setShowEdit2(true)
  }
  const handleActionSubmit = () => {
    let formdata = new FormData();
    if (title.trim() != "") {
      formdata.append("title", title);
    } else {
      swal({
        title: "Fill required field",
        icon: "error"
      });
      return
    }
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    formdata.append("timezone", timeZone);
    formdata.append("event_type", "action");
    formdata.append("pipeline", pipelines);
    formdata.append("priority", priority);
    assignId && formdata.append("assigned_to", assignId);
    formdata.append("event_members", ownerhidden);
    formdata.append("module_id", Id);
    formdata.append("color_code", actionData?.actionPipelines.filter(item => item.db_id === pipelines)[0]?.pipeline_color);
    formdata.append("module", modules);
    formdata.append("create_event", "cReaTe_mOduLe_eVENt");
    if (datasAction?.lead_user_time_zone) {
      formdata.append("lead_user_time_zone", datasAction?.lead_user_time_zone);
      formdata.append("eve_start_date", dayjs(dateValue).tz(datasAction?.lead_user_time_zone ?? permissions["system-user_timezone"]?.setting_value).format('DD-MM-YYYY'));
      formdata.append("eve_start_time", dayjs(dateValue).tz(datasAction?.lead_user_time_zone ?? permissions["system-user_timezone"]?.setting_value).format('HH:mm:ss'));
      formdata.append("eve_end_date", dayjs(dateValue).add(1, 'hour').tz(datasAction?.lead_user_time_zone ?? permissions["system-user_timezone"]?.setting_value).format('DD-MM-YYYY'));
      formdata.append("eve_end_time", dayjs(dateValue).add(1, 'hour').tz(datasAction?.lead_user_time_zone ?? permissions["system-user_timezone"]?.setting_value).format('HH:mm:ss'));
    }
    else if (allData) {
      formdata.append("lead_user_time_zone", allData?.createdUserData?.usertimezone);
      formdata.append("eve_start_date", dayjs(dateValue).tz(allData?.createdUserData?.usertimezone ?? permissions["system-user_timezone"]?.setting_value).format('DD-MM-YYYY'));
      formdata.append("eve_start_time", dayjs(dateValue).tz(allData?.createdUserData?.usertimezone ?? permissions["system-user_timezone"]?.setting_value).format('HH:mm:ss'));
      formdata.append("eve_end_date", dayjs(dateValue).add(1, 'hour').tz(allData?.createdUserData?.usertimezone ?? permissions["system-user_timezone"]?.setting_value).format('DD-MM-YYYY'));
      formdata.append("eve_end_time", dayjs(dateValue).add(1, 'hour').tz(allData?.createdUserData?.usertimezone ?? permissions["system-user_timezone"]?.setting_value).format('HH:mm:ss'));
    }
    apiMethodCreateAction('postModuleCreateEvents', formdata)
  };

  useEffect(() => {
    if (Array.isArray(actionData?.actionPipelines) && Array.isArray(actionData?.actionPriorityData)) {
      setPipelines(actionData?.actionPipelines[0]?.db_id);
      setPriority(actionData?.actionPriorityData[0]?.priority_id);
    }

  }, [actionData?.actionPipelines, actionData?.actionPriorityData]);
  useEffect(() => {
    if (createAction.data) {
      if (createAction?.data?.status == 200 || createAction.data.message === "Event created successfully.") {
        toast.success(createAction.data.message);
        window.location.reload(true);
      }
      else if (createAction?.data?.message == "Google sync required for the users." && createAction?.data?.status == 422) {
        setsync_user_modal(true)
      }
      else {
        toast.success(createAction.data.message);
        if (modules === "meeting") {
          // WebSocketAction
          let data = {
            section: "Actions",
            user_id: "1",
            meeting_id: Id,
            data: createAction.data,
          };
          WebSocketAction(data);
          // setDateValue(date);
          setTitle("");
          setPipelines(Array.isArray(actionData?.actionPipelines) && actionData?.actionPipelines[0]?.db_id);
          setPriority(Array.isArray(actionData?.actionPriorityData) && actionData?.actionPriorityData[0]?.priority_id);
          setAssigntoImg(datasAction?.avatar)
        }
      }
    }
  }, [createAction.data])


  const handleGoogleSync = () => {
    let calendar = ForGooglesync?.filter(eve => eve.calendar_default === "1")
    localStorage.setItem(`GoogleSync`, calendar[0]?.cl_db_did);
    localStorage.setItem("SyncRedirectPath", window.location.pathname)

    axios.defaults.headers = {
      "Content-Type": "multipart/form-data",
      authentication: `${getTokenSession()}`,
    };
    axios.get(`${config.apiEndPoint}getGoogleSync/id=${calendar[0]?.cl_db_did}&type=calendar`)
      .then((response) => {
        window.location.href = `${response.data}`;
      })
      .catch((err) => {
        console.log('eerr', err)
      })
  }


  const onOk = (value) => {
    console.log(value, "cdshndffsdfjgdhfsgjidhfs")
    setDateValue(value)
  };
  const onOk2 = (e) => {
    console.log(e.target.value, "cdshnjgdhfsgjidhfs")
    setDateValue(e.target.value)
  };

  return (
    <>
      <div className=""
        style={{
          "display": "grid",
          gridTemplateColumns: "2fr 3fr 1fr 1fr 0.5fr 0.5fr",
          gap: "0.6rem",
        }}
      >

        <div className="">
          <div className="dropdown">
            <input type="datetime-local" className='dateClass form-control' value={dateValue} onChange={onOk2} />

            {/* <DatePicker
              allowClear={false}
              format="YYYY-MM-DD HH:mm"
              showTime={{ format: 'HH:mm' }}
              showOk={false}
              onChange={onOk2}
              disabledOk={false}
              value={dateValue}
            /> */}

          </div>
        </div>

        <div className="">
          <div className="form-group">
            <input type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="form-control"
              name="example-text-input"
              placeholder="What do you need to get done?"
            />
          </div>
        </div>

        <div className="">
          <div className="form-group multiselect_div">
            <select
              value={pipelines}
              onChange={(e) => setPipelines(e.target.value)}
              id="single-selection"
              name="single_selection"
              className="multiselect multiselect-custom form-control"
            >
              {
                Array.isArray(actionData?.actionPipelines) ?

                  actionData?.actionPipelines.map((item) => {
                    return (
                      <option key={item.db_id} value={item.db_id}>{item?.pipeline_title}</option>
                    )
                  })

                  :
                  <option></option>

              }
            </select>
          </div>
        </div>

        <div className="">
          <div className="form-group multiselect_div">
            <select
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
              id="single-selection"
              name="single_selection"
              className="multiselect multiselect-custom form-control"
            >
              {
                Array.isArray(actionData?.actionPriorityData) ?
                  actionData?.actionPriorityData.map((item) => {
                    return (
                      <option key={item.priority_id} value={item.priority_id}>{item?.priority_label}</option>
                    )
                  })
                  :
                  <option></option>

              }
            </select>
          </div>
        </div>

        <div className="">
          <img
            onClick={handleAssignModalOpen}
            className="avatar"
            src={assigntoImg ? assigntoImg.includes("http") ? assigntoImg : `${config.baseurl2}${assigntoImg}` : "https://www.gravatar.com/avatar/b39d3037b9a666e9944ac081e76e3e28?s=160"}
          />
        </div>

        <div className="">

          <button
            type="button"
            className="btn btn-icon btn-primary btn-success"
            onClick={() => {
              handleActionSubmit();
            }}
          >
            <i className="fe fe-plus"></i>
          </button>
        </div>
      </div>
      <div className="row clearfix">
      </div>
      {sync_user_modal && <GoogleSyn_event_modal handleGoogleSync={handleGoogleSync} setsync_user_modal={setsync_user_modal} sync_user_modal={sync_user_modal} data={createAction?.data} />}


      <Modal show={showEdit2} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12 mt-3">
            <label className="form-label">
              {Translation(translations, "Lead Assign to")}
            </label>
            <div ref={ownerRef} className="searchDropDown">
              <input
                type="text"
                className="form-control"
                ref={inputElement}
                name="contact_owner"
                value={searchval}
                onChange={(e) => setSearchval(e.target.value)}
              />
              <button
                className="nav-link clickButton"
                type="button"
                id="dropdownMenuButton"
                onClick={() => handleList()}
              >
                <FaSearch />
              </button>
            </div>
            <div className={`dropDownCustom ${listOpen && "active"}`}>
              {resowner.data && (
                <ul className="list gfdgd">
                  {resowner.isLoading ? (
                    ""
                  ) : !resowner.data.message ? (
                    resowner.data.map((item, index) => {
                      return (<li key={index} onClick={() => handleClick(item)} >  {Translation(translations, `${item.uname} (${item.role_name})`)}</li>);
                    })
                  ) : (
                    <li>
                      {Translation(
                        translations,
                        `${resowner.data.message}`
                      )}
                    </li>
                  )}
                </ul>


              )}
            </div>
          </div>

          <div className="modal-footer mt-3">
            <button type="button" onClick={handleClose} className="btn btn-default not_now">Not Now</button>
            <button type="button" onClick={handleSubmitAssign} className="btn btn-primary btn-leadassignst">Assign Action</button>
          </div>
        </Modal.Body>
      </Modal>

    </>



  )
}



export default EditLeadAction