import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";

const UpdateWorkScheduleModal = ({ 
    show, handleClose, initialCreateDate, initialStartTime, initialEndTime, frequency, onSave
 }) => {

    const [defaultCreateDate, setDefaultCreateDate] = useState(dayjs());
    const [defaultStartTime, setDefaultStartTime] = useState(dayjs());
    const [defaultEndTime, setDefaultEndTime] = useState(dayjs());
    const [selectedOption, setSelectedOption] = useState('do-not-repeat');
    const [selectRecurrencyOption, setSelectedRecurrencyOption] = useState('this-event');

    useEffect(() => {
        if (initialCreateDate) {
            setDefaultCreateDate(dayjs(initialCreateDate));
        }
        if (initialStartTime) {
            setDefaultStartTime(dayjs(initialStartTime, 'HH:mm'));
        }
        if (initialEndTime) {
            setDefaultEndTime(dayjs(initialEndTime, 'HH:mm'));
        }
        if (frequency) {
            setSelectedOption(frequency);
        }
    }, [initialCreateDate, initialStartTime, initialEndTime, selectedOption]);

    const eventRecorrenceAction = [
        {value: 'this-event', label: 'This event'},
        {value: 'this-and-following-events', label: 'This and following events'},
        {value: 'all-event', label: 'All events'}
    ]

    const handleSelectRecurrencyChange = (event) => {
        setSelectedRecurrencyOption(event.target.value);
    }

    const handleCreatedDate = (value) => {
        setDefaultCreateDate(value);
    }

    const handleStartTime = (value) => {
        setDefaultStartTime(value);
    }

    const handleEndTime = (value) => {
        setDefaultEndTime(value);
    }

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    }

    const selectList = [
        { value: 'do-not-repeat', label: 'Do not repeat' },
        { value: 'every-day', label: 'Every day' },
        { value: 'weekly', label: 'Weekly' },
        { value: 'monday-friday', label: 'Every day of the week (Monday to Friday)' },
    ];

    const handleSave = (event) => {
        console.log('event', event)
    }

    const handleRemove = () => {
        console.log('remove')
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="modal-header d-flex justify-content-between">
                    <div className="left">
                        <h5 className="modal-title" id="eventDetailsModalTitle">
                            Update Working Schedule
                        </h5>
                    </div>
                    <div className="right">
                        <button type="button" onClick={handleClose} className="close" data-dismiss="modal" aria-label="Close">
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                </div>

                <div className="modal-body">
                    <div className='row'>
                        <div className="col-md-4 form-group my-1">
                            <b>Select Date</b>
                            <DatePicker
                                style={{ height: 38, marginTop: 12 }}
                                onChange={handleCreatedDate}
                                allowClear={false}
                                value={defaultCreateDate}
                                format={"YYYY/MM/DD"}
                                onKeyDown={e => e.preventDefault()}
                            />
                        </div>
                        <div className="col-md-4 form-group my-1">
                            <b>Start Time</b>
                            <TimePicker
                                style={{ height: 38, marginTop: 12 }}
                                value={defaultStartTime}
                                format={'HH:mm'}
                                allowClear={false}
                                onChange={handleStartTime}
                                onKeyDown={e => e.preventDefault()}
                            />
                        </div>
                        <div className="col-md-4 form-group my-1">
                            <b>End Time</b>
                            <TimePicker
                                style={{ height: 38, marginTop: 12 }}
                                value={defaultEndTime}
                                format={"HH:mm"}
                                allowClear={false}
                                onChange={handleEndTime}                                
                                onKeyDown={e => e.preventDefault()}
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='mt-4 pl-1'>
                            <h5>Frequency</h5>
                        </div>
                        <div>
                            <select id="options" value={selectedOption} onChange={handleSelectChange} className='form-control'>
                                {Array.isArray(selectList) && selectList.map((item,index) => {
                                    return (
                                        <option  value={item.value}  key={index}   >{item.label}</option>
                                    )
                                })} 
                            </select>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='mt-4 pl-1'>
                            <h5>Recurrency</h5>
                        </div>
                        <div>
                            <select id="recurrency" value={selectRecurrencyOption} onChange={handleSelectRecurrencyChange} className='form-control'>
                                {Array.isArray(eventRecorrenceAction) && eventRecorrenceAction.map((item,index) => {
                                    return (
                                        <option  value={item.value}  key={index}   >{item.label}</option>
                                    )
                                })} 
                            </select>
                        </div>
                    </div>
                </div>




                <div className="modal-footer">
                    <div className="d-flex justify-content-between w-100">
                        <div>
                            <button type="submit" onClick={() => handleRemove()} className="btn btn-red">
                                <i className="fa fa-trash mr-2"></i>
                                Remove
                            </button>
                        </div>
                        <div>
                            <button onClick={handleClose} type="button" className="btn btn-secondary mr-2" data-dismiss="modal">
                                Close
                            </button>
                            <button type="submit" onClick={() => handleSave()} className="btn btn-info askRadio_okay">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default UpdateWorkScheduleModal;
