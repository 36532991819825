import { Form, Formik } from "formik";
import React from "react";
import { useState, useRef, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FormControl from "../../components/form/FormControl";
import config from "../../services/config.js";
import allData from "../../Data/data";
import { FaClock, FaCalendarAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { MainEditCalenderContext } from "../../context/MainEditCalenderContext";
import dayjs from "dayjs";
import { MainAuthPermissionsContext } from "../../context/MainAuthPermissionsContext";
import { AiOutlineConsoleSql } from "react-icons/ai";
import { Select } from "antd";
import { color_array } from "../../Data/AllData";

function AddEventModal({
  show,
  type,
  calenderDetails,
  removeAddModal,
  dateall,
  ids,
}) {
  const { addcalenderdetails } = useContext(MainEditCalenderContext);
  const { permissions } = useContext(MainAuthPermissionsContext);
  const hexCodeDefault =
    Array.isArray(calenderDetails) && calenderDetails[0].calendar_color;
  let colorChangeRef = useRef(null);
  let colorChangeRef2 = useRef(null);
  const navigate = useNavigate();
 
  const [initialValues, setInitialValues] = useState({
    eventtitle: "",
    eve_color_id: "1",
    type: "",
    eve_color:color_array[0].code
      // Array.isArray(calenderDetails) && calenderDetails.length > 0
      //   ? calenderDetails[0].calendar_color
      //   : "#000000",
  });
 
  const handleAddValue = (values) => {
    const date = dayjs().tz(
      permissions[`system-user_timezone`]?.setting_value ?? "America/New_York"
    );
    console.log(dateall.start ,dateall.end)
    const newDate = date.add(1, "hour");
    console.log(dateall, "type");
    addcalenderdetails({
      EventTitle: `${values.eventtitle}`,
      EventType: `${selected}`,
      EventCalendar: `${ids}`,
      HEXCODE: `${initialValues.eve_color}`,
      eve_color_id: `${initialValues.eve_color_id}`,
      startdate: `${dateall.start ??""}`,
      enddate: `${dateall.end ?? ""}`,
      Starttime: dayjs().format("HH:mm"),
      Endtime: dayjs().add(1, "hour").format("HH:mm"),
      view: type,
    });
    navigate(`/${config.ddemoss}calendar/new_event`);
  };
  const [selected, setselected] = useState("event");
  return (
    <Modal show={show} onHide={removeAddModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Event</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={initialValues} onSubmit={handleAddValue}>
          <Form name="myForm">
            <FormControl
              className="form-control my-1"
              label={"Event Title"}
              name="eventtitle"
              control="input"
              placeholder="Calendar Name"
            />
            <div className="row">
            <div className="col-6">
            <label htmlFor="">
              <b>Type </b>
            </label>
            <select
              name="type"
              className={"form-control"}
              onChange={(e) => setselected(e.target.value)}
            >
              {allData.calendar.type.map((item, index) => {
                return (
                  <option value={item.value} key={index}>
                    {item.label}
                  </option>
                );
              })}
            </select>
            </div>
            <div className="form-group col-6  bokable new_event_color">
              <label className="form-label">HEX Code</label>
              <div className="input-group">
                <Select
                  defaultValue={"1"}
                  onChange={(v1, v2) => {
                    setInitialValues({
                      ...initialValues,
                      eve_color: v2.key,
                      eve_color_id: v1,
                    });
                  }}
                  style={{ width: "100%" }}
                >
                  {color_array.length &&
                    color_array.map(({ code, id,name }) => (
                      <Select.Option value={id} key={code}>
                        {name}
                        <span
                          className="color_box"
                          style={{
                            backgroundColor: code,
                            borderRadius: 10,
                            width: 20,
                            float: "right",
                          }}
                        ></span>
                      </Select.Option>
                    ))}
                </Select>
                {/* <input
                  ref={colorChangeRef2}
                  type="text"
                  className="form-control inputV"
                  name="eve_color"
                  value={initialValues?.eve_color}
                  onChange={(e) => setInitialValues({ ...initialValues, "eve_color": e.target.value })}
                />
                <div className="input-group-append">
                  <input
                    type="color"
                    className="inputColor"
                    name="eve_color"
                    value={initialValues?.eve_color}
                    onChange={(e) => setInitialValues({ ...initialValues, "eve_color": e.target.value })}
                  />
                </div> */}
              </div>
            </div>
            </div>
            <div className="poptime mb-2 mt-2  col-12">
              <div className="poptimeBox d-flex align-items-center mt-3 gap-2">
                <div className="poptimeBox__img">
                  <FaClock />
                </div>
                <div className="poptimeBox__txt d-flex gap-1">
                  <div className="d-flex gap-1">
                    <span className="startdate mr-3 popbefore">
                      {dayjs(dateall.start).format("YYYY-MM-DD")}
                    </span>
                    <span>
                      {type === "dayGridMonth"
                        ? dayjs().format("HH:mm:A")
                        : dayjs(dateall.start).format("HH:mm:A")}
                    </span>
                  </div>
                  <div className="d-flex gap-1">
                    ||
                    <span className="enddate mr-3 popbefore">
                      {dayjs(dateall.start).add(1, "hour").format("YYYY-MM-DD")}
                    </span>
                    <span>
                      {type === "dayGridMonth" || type === ""
                        ? dayjs().add(1, "hour").format("HH:mm:A")
                        : dayjs(dateall.end).format("HH:mm:A")}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="poptime col-12">
              <div className="poptimeBox d-flex align-items-center gap-2">
                <div className="poptimeBox__img">
                  <FaCalendarAlt />
                </div>
                <div className="poptimeBox__txt pr-4">
                  <div className="d-flex">
                    <span className="mr-4 popbefore">Public</span>
                    <span className="mr-4 popbefore">Busy</span>
                    <span>Notify 10 Minutes Before</span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <Button
              type="submit"
              variant="primary"
              className="d-flex justify-content-end ml-auto"
            >
              More Options
            </Button>
          </Form>
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default AddEventModal;
