import React, { useState, useEffect, useContext, useRef } from 'react'
import { MainAuthPermissionsContext } from '../context/MainAuthPermissionsContext';
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Modal from "react-bootstrap/Modal";
import { MainTranslationContexts } from '../context/MainTranslationContexts';
import { Translation } from '../components/Translation';
import { FaSearch } from "react-icons/fa";
import usePost from '../customHooks/usePost';
import config from "../services/config.js";
import { DatePicker } from 'antd';
import { toast } from "react-toastify";
import swal from 'sweetalert';
dayjs.extend(utc);
dayjs.extend(timezone);
function EditMeetingAction({ ...props }) {
    const { Id, datasAction,allData, actionData, modules, WebSocketAction, assignId, meetingPriorityData, pipeline_id } = { ...props };
    const { permissions } = useContext(MainAuthPermissionsContext)
    // console.log(,"muzzz")
    const { translations } = useContext(MainTranslationContexts);
    const [assigntoImg, setAssigntoImg] = useState(datasAction?.avatar);
    const date = new Date();
    const year = date.getFullYear();
const month = String(date.getMonth() + 1).padStart(2, "0");
const day = String(date.getDate()).padStart(2, "0");
const hours = String(date.getHours()).padStart(2, "0");
const minutes = String(date.getMinutes()).padStart(2, "0");


    const [dateValue, setDateValue] = useState(`${year}-${month}-${day}T${hours}:${minutes}`);
    const [ownerhidden, setOwnerhidden] = useState(assignId);
    const [searchval, setSearchval] = useState(datasAction?.f_name);
    const [listOpen, setListOpen] = useState(false);
    const [showEdit2, setShowEdit2] = useState(false);
    const [pipelines, setPipelines] = useState(pipeline_id);
    const [priority, setPriority] = useState(Array.isArray(meetingPriorityData) && meetingPriorityData[0]?.severity_id);
    const [title, setTitle] = useState("");
    const inputElement = useRef();
    const ownerRef = useRef(null);
    const [resowner, apiMethodowner] = usePost();
    const [resMeeting, apiMethodCreateAction] = usePost('')
    const [Assgns, setAssgns] = useState({
        "id": assignId,
        "image":datasAction?.avatar,
    })
    const handleClick = (item) => {
        if (item?.lead_follower !== "yes") {
            setSearchval(item.uname);
            setAssgns({
                "id": item.id,
                "image": item.avatar,
            })

        }
        setListOpen(false);
    };
    const handleClose = () => {
        setShowEdit2(false)
    }
    const handleSubmitAssign = () => {
        setOwnerhidden(Assgns.id);
        setAssigntoImg(Assgns.image);
        setShowEdit2(false)
    }
    const handleList = () => {
        let formdataOwner = new FormData();
        formdataOwner.append("userType", "typeSearch");
        formdataOwner.append("query", searchval);
        formdataOwner.append("uLead", Id);
        apiMethodowner("postSpecifiesUsers", formdataOwner);
        setListOpen(!listOpen);
    };
    const handleAssignModalOpen = () => {
        setShowEdit2(true)
    }
    const handleActionSubmit = () => {
        let formdata = new FormData();
        if (title.trim() != "") {
            formdata.append("title", title);
        } else {
            swal({
                title: "Fill required field",
                icon: "error"
            });
            return
        }
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      formdata.append("timezone", timeZone);

        formdata.append("event_type", "follow_up");
        formdata.append("pipeline", pipelines);
        formdata.append("severity", priority);
        assignId && formdata.append("assigned_to", assignId);
        formdata.append("event_members", ownerhidden);
        formdata.append("module_id", Id);
        formdata.append("color_code", meetingPriorityData.filter(item => item.severity_id == priority)[0]?.severity_color);
        formdata.append("module", modules);
        datasAction?.lead_user_time_zone&&  formdata.append("lead_user_time_zone", datasAction?.lead_user_time_zone);
        allData&&  formdata.append("lead_user_time_zone", allData?.createdUserData?.usertimezone);
        formdata.append("create_event", "cReaTe_mOduLe_eVENt");
        formdata.append("eve_start_date", dayjs(dateValue).tz(allData?.createdUserData?.usertimezone??permissions["system-user_timezone"]?.setting_value).format('DD-MM-YYYY'));
        formdata.append("eve_start_time", dayjs(dateValue).tz(allData?.createdUserData?.usertimezone??permissions["system-user_timezone"]?.setting_value).format('HH:mm:ss'));
        formdata.append("eve_end_date", dayjs(dateValue).add(1, 'hour').tz(allData?.createdUserData?.usertimezone??permissions["system-user_timezone"]?.setting_value).format('DD-MM-YYYY'));
        formdata.append("eve_end_time", dayjs(dateValue).add(1, 'hour').tz(allData?.createdUserData?.usertimezone??permissions["system-user_timezone"]?.setting_value).format('HH:mm:ss'));
        apiMethodCreateAction('postModuleCreateEvents', formdata)
    };
    useEffect(() => {
        if (pipeline_id & meetingPriorityData) {
            setPipelines(pipeline_id);
            setPriority(meetingPriorityData);
        }

    }, [pipeline_id, meetingPriorityData]);
    useEffect(() => {
        if (resMeeting.data) {
            if (resMeeting.data.success === 0) {
                toast.success(resMeeting.data.message);
            }
            else {
                toast.success(resMeeting.data.message);
                if (modules === "meeting") {
                    // WebSocketAction
                    let data = {
                        section: "Followup",
                        user_id: "1",
                        meeting_id: Id,
                        data: resMeeting.data,
                    };
                    WebSocketAction(data);
                    setDateValue(date)
                    setTitle("")
                    setPriority(Array.isArray(meetingPriorityData) && meetingPriorityData[0]?.severity_id)
                    setAssigntoImg(datasAction?.avatar);
                } else {
                }
            }
        }
    }, [resMeeting.data])


    const onOk = (e) => {
        console.log(e.target.value)
        setDateValue(e.target.value)
    };

    return (
        <>
            <div className=""
                style={{
                    display: "flex",
                    /* align-items: baseline; */
                    justifyContent: "spaceBetween",
                    gap: "6px"
                }}
            >

                <div className="w-75">
                    <div className="dropdown">
                        {/* <DatePicker
                            allowClear={false}
                            format="YYYY-MM-DD HH:mm"
                            showTime={{ format: 'HH:mm' }}
                            onOk={onOk}
                            value={dateValue}
                        /> */}
                         <input type="datetime-local"className='dateClass form-control' value={dateValue}  onChange={onOk} />

                    </div>
                </div>

                <div className="w-100">
                    <div className="form-group">
                        <input type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="form-control"
                            name="example-text-input"
                            placeholder="What do you need to get done?"
                        />
                    </div>
                </div>

                <div className="form-group multiselect_div w-50">
                    <select
                        value={priority}
                        onChange={(e) => setPriority(e.target.value)}
                        id="single-selection"
                        name="single_selection"
                        className="multiselect multiselect-custom form-control"
                    >
                        {
                            Array.isArray(meetingPriorityData) ?
                                meetingPriorityData?.map((item) => {
                                    return (
                                        <option key={item.severity_id} value={item.severity_id}>{item?.severity_label}</option>
                                    )
                                })
                                :
                                <option></option>

                        }
                    </select>
                </div>

                <div className="w-25">
                    <img
                        onClick={handleAssignModalOpen}
                        className="avatar"
                        src={assigntoImg ? assigntoImg.includes("http") ? assigntoImg : `${config.baseurl2}${assigntoImg}` : "https://www.gravatar.com/avatar/b39d3037b9a666e9944ac081e76e3e28?s=160"}
                    />
                </div>

                <div className="w-25">

                    <button
                        type="button"
                        className="btn btn-icon btn-primary btn-success"
                        onClick={() => {
                            handleActionSubmit();
                        }}
                    >
                        <i className="fe fe-plus"></i>
                    </button>
                </div>
            </div>
            <div className="row clearfix">
            </div>


            <Modal show={showEdit2} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Assign Action</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12 mt-3">
                        <label className="form-label">
                            {Translation(translations, "Lead Assign to")}
                        </label>
                        <div ref={ownerRef} className="searchDropDown">
                            <input
                                type="text"
                                className="form-control"
                                ref={inputElement}
                                name="contact_owner"
                                value={searchval}
                                onChange={(e) => setSearchval(e.target.value)}
                            />
                            <button
                                className="nav-link clickButton"
                                type="button"
                                id="dropdownMenuButton"
                                onClick={() => handleList()}
                            >
                                <FaSearch />
                            </button>
                        </div>
                        <div className={`dropDownCustom ${listOpen && "active"}`}>
                            {resowner.data && (
                                <ul className="list gfdgd">
                                    {resowner.isLoading ? (
                                        ""
                                    ) : !resowner.data.message ? (
                                        resowner.data.map((item, index) => {
                                            return (<li key={index} onClick={() => handleClick(item)} >  {Translation(translations, `${item.uname} (${item.role_name})`)}</li>);
                                        })
                                    ) : (
                                        <li>
                                            {Translation(
                                                translations,
                                                `${resowner.data.message}`
                                            )}
                                        </li>
                                    )}
                                </ul>


                            )}
                        </div>
                    </div>

                    <div className="modal-footer mt-3">
                        <button type="button" onClick={handleClose} className="btn btn-default not_now">Not Now</button>
                        <button type="button" onClick={handleSubmitAssign} className="btn btn-primary btn-leadassignst">Assign Action</button>
                    </div>
                </Modal.Body>
            </Modal>

        </>



    )
}



export default EditMeetingAction