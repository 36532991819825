import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";

const CreateWorkScheduleModal = ({ 
    show, handleClose, initialCreateDate, initialStartTime, initialEndTime, frequency, onSave 
}) => {

    const [defaultCreateDate, setDefaultCreateDate] = useState(dayjs());
    const [defaultStartTime, setDefaultStartTime] = useState(dayjs());
    const [defaultEndTime, setDefaultEndTime] = useState(dayjs());
    const [selectedOption, setSelectedOption] = useState('do-not-repeat');

    useEffect(() => {
        if (initialCreateDate) {
            setDefaultCreateDate(dayjs(initialCreateDate));
        }
        if (initialStartTime) {
            setDefaultStartTime(dayjs(initialStartTime, 'HH:mm'));
        }
        if (initialEndTime) {
            setDefaultEndTime(dayjs(initialEndTime, 'HH:mm'));
        }
        if (frequency) {
            setSelectedOption(frequency);
        }
    }, [initialCreateDate, initialStartTime, initialEndTime, selectedOption]);

    const handleCreatedDate = (value) => {
        setDefaultCreateDate(value);
    };

    const handleStartTime = (value) => {
        setDefaultStartTime(value);
    };

    const handleEndTime = (value) => {
        setDefaultEndTime(value);
    };

    const handleSave = () => {
        const startTime = dayjs(initialStartTime, 'hh:mm A');
        const endTime = dayjs(initialEndTime, 'hh:mm A');
        onSave({
            defaultCreateDate, 
            initialStartTime: startTime, 
            initialEndTime: endTime, 
            frequency: selectedOption,
            justForThisMonth: checkedOptions
        });
        handleClose();
    };
    
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const selectList = [
        { value: 'do-not-repeat', label: 'Do not repeat' },
        { value: 'every-day', label: 'Every day' },
        { value: 'weekly', label: 'Weekly' },
        { value: 'monday-friday', label: 'Every day of the week (Monday to Friday)' },
    ];

    // Step 1: Initialize the state
    const [checkedOptions, setCheckedOptions] = useState(false);

    // Step 3: Handle changes
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedOptions(checked);
    };

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="modal-header d-flex justify-content-between">
                    <div className="left">
                        <h5 className="modal-title" id="askmodalTitle">
                            Add User Work Schedule
                        </h5>
                    </div>
                    <div className="right">
                        <button type="button" onClick={handleClose} className="close" data-dismiss="modal" aria-label="Close">
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                </div>

                <div className="modal-body">
                    <div className='row'>
                        <div className="col-md-4 form-group my-1">
                            <b>Select Date</b>
                            <DatePicker
                                style={{ height: 38, marginTop: 12 }}
                                onChange={handleCreatedDate}
                                allowClear={false}
                                value={defaultCreateDate}
                                format={"YYYY/MM/DD"}
                                onKeyDown={e => e.preventDefault()}
                            />
                        </div>
                        <div className="col-md-4 form-group my-1">
                            <b>Start Time</b>
                            <TimePicker
                                style={{ height: 38, marginTop: 12 }}
                                value={defaultStartTime}
                                format={'HH:mm'}
                                allowClear={false}
                                onChange={handleStartTime}
                                onKeyDown={e => e.preventDefault()}
                            />
                        </div>
                        <div className="col-md-4 form-group my-1">
                            <b>End Time</b>
                            <TimePicker
                                style={{ height: 38, marginTop: 12 }}
                                value={defaultEndTime}
                                format={"HH:mm"}
                                allowClear={false}
                                onChange={handleEndTime}                                
                                onKeyDown={e => e.preventDefault()}
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='mt-4 pl-1'>
                            <h5>Frequency</h5>
                        </div>
                        <div>
                            <select id="options" value={selectedOption} onChange={handleSelectChange} className='form-control'>
                                {Array.isArray(selectList) && selectList.map((item,index) => {
                                    return (
                                        <option  value={item.value}  key={index}   >{item.label}</option>
                                    )
                                })} 
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="d-flex gap-1 align-items-start pt-3">
                            <label>
                                <input
                                type="checkbox"
                                name="option1"
                                checked={checkedOptions}
                                onChange={handleCheckboxChange}
                                className='mr-2'
                                />
                                Just for this month
                            </label>
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <button onClick={handleClose} type="button" className="btn btn-secondary" data-dismiss="modal">
                        Close
                    </button>
                    <button type="submit" onClick={() => handleSave()} className="btn btn-info askRadio_okay">
                        Save
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default CreateWorkScheduleModal;
