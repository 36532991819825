import React, { useEffect, useState,useContext } from 'react';
import { Select } from 'antd';
import usePost from '../../customHooks/usePost';
import { MainAuthPermissionsContext } from '../../context/MainAuthPermissionsContext';

const SelectSearch = ({ changess, selected, defaultValue, disabled,AllMember,eventData }) => {
    const [resGet, apiMethodGet] = usePost();
    const [selectData, setselectData] = useState([])
  const { permissions } = useContext(MainAuthPermissionsContext);

  let selectedMember=AllMember.map(val=>val?.membersearch)
  selectedMember.splice(selectedMember.indexOf(defaultValue), 1);
    useEffect(() => {

        if (resGet.data&&!resGet.data?.message) {
            
            console.log("first",eventData)
          if(eventData){ 
             if(permissions?.id&&permissions?.id!=eventData.member_id ){
                let filterData= resGet?.data?.filter(obj=>(obj?.id!==permissions?.id))
                setselectData(filterData)
            }else{
                console.log("second")

                setselectData(resGet.data)
                }
            }else{
                let filterData= resGet?.data?.filter(obj=>(obj?.id!==permissions?.id))
                setselectData(filterData)
            }
        }else{
            setselectData([])
        }

    }, [resGet])

    const onSearch = (value) => {
        let formdata = new FormData()
        formdata.append("userType", "typeSearchMember")
        formdata.append("uLead", "6")
        formdata.append("query", value)
        apiMethodGet("postSerchMember", formdata)

    };
    useEffect(() => onSearch(''), [])

    return (
        <Select
            value={defaultValue}
            disabled={disabled}
            showSearch={true}
            filterOption={(input, option) =>
                (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
            }
            onSearch={
                (v) => {
                    onSearch(v);
                }
            }
            onChange={(v1, v2) => {

                changess(v1)
            }}


            style={{ width: "100%", height: 40 }}
            placeholder={'Search follower name'}
        >

            {
                selectData.length && selectData.filter(val=>!selectedMember.includes(val.id)).map(({ uname, id, text }) => {
                    return (
                        <Select.Option value={id} key={id}>
                            {uname}
                        </Select.Option>
                    )
                })
            }
        </Select >)
};

export default SelectSearch;