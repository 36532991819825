import { Tree, TreeNode } from "react-organizational-chart";
import Modal from "react-bootstrap/Modal";
// import ModalAutomation from './ModalAutomations';
import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { Translation } from "./components/Translation";
import { MainTranslationContexts } from "./context/MainTranslationContexts";
import FormControl from "./components/form/FormControl";
import { Form, Formik, Field } from "formik";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { Button, Table } from "react-bootstrap";
import swal from "sweetalert";
import dayjs from "dayjs";
import usePost from "./customHooks/usePost";
import config from "./services/config.js";
import axios from "axios";
import { getTokenSession } from "./utils/common";
import { useNavigate } from "react-router-dom";
import Automation_segments from "./Automation_segments";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Select } from "antd";
import Automation_addTag_module from "./Automation_addTag_module";
import Automation_removeTag_module from "./Automation_removeTag_module";
import Automation_addNote_module from "./Automation_addNote_module";
import Automation_updateField_module from "./Automation_updateField_module";
import Automation_addEvent_module from "./Automation_addEvent_module";
import isUrl from "is-url";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { MainActionListContext } from "./context/MainActionListContext";
import { MainAuthPermissionsContext } from "./context/MainAuthPermissionsContext";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { DatePicker } from "antd";
import { FaSearch } from "react-icons/fa";
import Dropdown from "./components/form/Dropdown";
import { timeZone } from "./Data/AllData";

dayjs.extend(utc);
dayjs.extend(timezone);
// let dat = {
//   actions: {
//     id: "id_d0fc235faa63535202312103197",
//     label: "Wait",
//     message: "for 5 minutes",
//     itemType: "action",
//     itemGroup: "workflow",
//     actionDetails: {
//       name: "Wait",
//       label: "Wait",
//       des: "Wait for a certain period of time, or until conditions are matched.",
//       type: "wait",
//       disabled: "false",
//       icon: "<i class='fas fa-clock'></i>",
//       bgColor: "#fff",
//       color: "#212121",
//       show: "true",
//       allowEdit: "true",
//     },
//     formData: [
//       { name: "waitingMode", value: "waitFor" },
//       { name: "waitingNumber", value: "5" },
//       { name: "waitingUnit", value: "minutes" },
//       { name: "waitingFor", value: "periodOfTime" },
//     ],
//     children: [
//       {
//         id: "id_d039a17c86fda352023122315556",
//         label: null,
//         message:
//           "Does the following conditions match? (Has Made Purchase  is  18'' x 18'' Standard Microfibersad (18) )",
//         itemType: "action",
//         itemGroup: "workflow",
//         actionDetails: {
//           name: "If/Else",
//           label: "If/Else",
//           des: "Continue the automation in a different way depending on whether the conditions are matched.",
//           type: "ifElse",
//           disabled: "false",
//           icon: "<i class='fas fa-question-circle'></i>",
//           bgColor: "#535353",
//           color: "#fff",
//           show: "true",
//           allowEdit: "true",
//         },
//         formData: [
//           { name: "condition_1", value: "and" },
//           { name: "select_option_1", value: "Has Made Purchase" },
//           { name: "hidden_select_option_1", value: "made_purchase" },
//           { name: "select_condition_1", value: "is" },
//           {
//             name: "condition_value_1",
//             value: "18'' x 18'' Standard Microfibersad (18)",
//           },
//           { name: "condition_id_1", value: "39995846819925" },
//           { name: "advancedOptionsLength", value: "1" },
//         ],
//         children: [
//           {
//             id: "path1_id_ddb2807c8964e352023122315560",
//             label: null,
//             message: "Yes",
//             itemType: "path",
//             itemGroup: null,
//             actionDetails: { type: "path1", color: null, bgColor: null },
//             formData: null,
//             children: [
//               {
//                 id: "id_7e470ebd38b2e352023122322541",
//                 label: null,
//                 message: "Add tag: Tag1",
//                 itemType: "action",
//                 itemGroup: "contact",
//                 actionDetails: {
//                   name: "Add a tag",
//                   label: "Add a tag",
//                   des: "Add a tag to a contact.",
//                   type: "TagAdd",
//                   disabled: "false",
//                   icon: "<i class='fas fa-user'></i>",
//                   bgColor: "#4A7CB7",
//                   color: "#fff",
//                   show: "true",
//                   allowEdit: "true",
//                 },
//                 formData: [{ name: "tag_id", value: "26" }],
//               },
//             ],
//           },
//           {
//             id: "path2_id_fd69c7e1c5c02352023122315560",
//             label: null,
//             message: "No",
//             itemType: "path",
//             itemGroup: null,
//             actionDetails: { type: "path2", color: null, bgColor: null },
//             formData: null,
//             children: [
//               {
//                 id: "id_372a656809db7352023122330540",
//                 label: null,
//                 message: "Add tag: Test",
//                 itemType: "action",
//                 itemGroup: "contact",
//                 actionDetails: {
//                   name: "Add a tag",
//                   label: "Add a tag",
//                   des: "Add a tag to a contact.",
//                   type: "TagAdd",
//                   disabled: "false",
//                   icon: "<i class='fas fa-user'></i>",
//                   bgColor: "#4A7CB7",
//                   color: "#fff",
//                   show: "true",
//                   allowEdit: "true",
//                 },
//                 formData: [{ name: "tag_id", value: "12" }],
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   triggers: [
//     {
//       id: "id_4b8ffe4ac85c735202312944889",
//       label: "Register",
//       message: null,
//       itemType: "triggerAction",
//       itemGroup: null,
//       actionDetails: {
//         callback: "triggerWithoutFormHandle",
//         name: "Register",
//         label: "Register",
//         des: "Register",
//         type: "register",
//         disabled: "false",
//         icon: "<i class='fas fa-user-circle'></i>",
//         bgColor: null,
//         color: null,
//         show: "true",
//         allowEdit: "false",
//       },
//       formData: [{ name: "action_type", value: "register" }],
//     },
//     {
//       id: "id_cabcc4928921e2852023153736759",
//       label: "Form automation :",
//       message: '"TESTING"',
//       itemType: "triggerAction",
//       itemGroup: null,
//       actionDetails: {
//         callback: "triggerTypeFormHandle",
//         name: "Type form fill",
//         label: "Type form fill",
//         des: "Type form fill",
//         type: "typeFormFill",
//         disabled: "false",
//         icon: "<i class='fas fa-align-justify'></i>",
//         bgColor: null,
//         color: null,
//         show: "true",
//         allowEdit: "true",
//       },
//       formData: [{ name: "form_id", value: "8" }],
//     },
//   ],
// };

// {
//   "actions": {
//     "id": "0.26011213341032313Thu Aug 03 2023 18:43:47 GMT+0500 (Pakistan Standard Time)",
//     "label": "Add Tag testsing in contact",
//     "icon": "fas fa-tags",
//     "children": [
//       {
//         "id": "0.971331076044311Thu Aug 03 2023 18:45:12 GMT+0500 (Pakistan Standard Time)",
//         "label": "Add Tag this this add for testing in contact",
//         "icon": "fas fa-tags",
//         "children": [],
//         "bgColor": "#4A7CB7",
//         "color": "#00000",
//         "ifelse": false,
//         "formdata": [{ "tag_value": "this this add for testing" }],
//         "actionDetails": {
//           "tittle": " in_contact",
//           "type": "add_tag",
//           "tag_value": "this this add for testing"
//         }
//       }
//     ],
//     "bgColor": "#4A7CB7",
//     "color": "#00000",
//     "ifelse": false,
//     "formdata": [{ "tag_value": "testsing" }],
//     "actionDetails": {
//       "tittle": " in_contact",
//       "type": "add_tag",
//       "tag_value": "testsing"
//     }
//   },
//   "triggers": [
//     {
//       "tittle": "Add New Contact",
//       "sub_tittle": "",
//       "id": "0.37835220559190885Thu Aug 03 2023 18:43:33 GMT+0500 (Pakistan Standard Time)",
//       "formData": [],
//       "actionDetails": [
//         {
//           "tittle": " in_contact",
//           "type": "add_tag",
//           "tag_value": "testsing"
//         },
//         {
//           "tittle": " in_contact",
//           "type": "add_note",
//           "tag_value": "testsing"
//         },
//         {
//           "tittle": " in_contact",
//           "type": "add_event",
//           "tag_value": "testsing"
//         }
//       ],
//       "segment": []
//     }
//   ]
// }
const TreeElement = ({ node }) => {
  return <DynamicTree data={node.children} />;
};

const DynamicTree = ({ data }) => {
  return data?.map((items) => {
    return (
      <TreeNode
        label={<div className="treedataNodes">Send an Email : Select</div>}
      >
        <TreeElement key={items.id} node={items} />
      </TreeNode>
    );
  });
};

const initialState_edit_action = {
  add_tag: false,
  remove_tag: false,
  add_note: false,
  add_event: false,
  update_field: false,
  if_else: false,
  add_automation: false,
  end_another_automation: false,
  math_operation_automation: false,
  wait_for: false,
};
const reducer_edit_action_handle = (state, action) => {
  return {
    ...initialState_edit_action,
    [action.key]: action.value,
  };
};
function Automation() {
  // console.log(dat);
  const [show, setshow] = useState(false);
  const [res, apimethodCreateAutomation] = usePost();

  const [show1, setshow1] = useState(false);
  const [show2, setshow2] = useState(false);
  const [MapFieldMoadalShow, setMapFieldMoadalShow] = useState(false);
  const [AutomationModal, setAutomationModal] = useState(false);
  const [ActionsTriggerModal, setActionsTriggerModal] = useState(false);
  const [WaitRadio1, setWaitRadio1] = useState(false);
  const [WaitRadio2, setWaitRadio2] = useState(false);
  const [mainTree_Top, setmainTree_Top] = useState([]);
  const [nodeid, setnodeid] = useState("");
  const [trigger_id, settrigger_id] = useState(null);
  const [action_id, setaction_id] = useState(null);
  const [HoveredIndex, setHoveredIndex] = useState(null);
  const [mapField_list, setMapField_list] = useState([
    {
      id: "1",
      name: "date",
      value: "test",
      map_to: {
        lists: [{ label: "date created", value: "1" }],
      },
    },
    {
      id: "2",
      name: "email",
      value: "checking",
      map_to: {
        lists: [{ label: "email", value: "1" }],
      },
    },
    {
      id: "1",
      name: "date",
      value: "test",
      map_to: {
        lists: [{ label: "date created", value: "1" }],
      },
    },
    {
      id: "2",
      name: "email",
      value: "checking",
      map_to: {
        lists: [{ label: "email", value: "1" }],
      },
    },
    {
      id: "1",
      name: "date",
      value: "test",
      map_to: {
        lists: [{ label: "date created", value: "1" }],
      },
    },
    {
      id: "2",
      name: "email",
      value: "checking",
      map_to: {
        lists: [{ label: "email", value: "1" }],
      },
    },
  ]);

  const { translations } = useContext(MainTranslationContexts);
  // state managemnet  //////////////////////////////////////////////////////////////\\\\\\\\\\\\\\\\\\\\\\ for trigger
  const [HeaderModalName, setHeaderModalName] = useState();
  const [HeaderModalActions, setHeaderModalActions] = useState();
  const [WebHookTriger, setWebHookTriger] = useState(false);
  const [FieldChangeTriger, setFieldChangeTriger] = useState(false);
  const [AddedTagTriger, setAddedTagTriger] = useState(false);
  const [RemoveTagTriger, setRemoveTagTriger] = useState(false);
  const [BookingCalenderTriger, BookingCalenderTrigerset] = useState(false);
  const [WebPageVisitedTriger, WebPageVisitedTrigerset] = useState(false);
  const [DateBasedTriger, setDateBasedTriger] = useState(false);

  // state managemnet  //////////////////////////////////////////////////////////////\\\\\\\\\\\\\\\\\\\\\\ for  New Action

  const [ActionWait, setActionWait] = useState(false);
  const [ActionIf_Else, setActionIf_Else] = useState(false);
  const [ActionStartAutomation, setActionStartAutomation] = useState(false);
  const [ActionEndAutomation, setActionEndAutomation] = useState(false);
  const [ActionEndAnotherAutomation, setActionEndAnotherAutomation] =
    useState(false);
  const [AtionWebhook, setAtionWebhook] = useState(false);
  const [ActionPerformMath, setActionPerformMath] = useState(false);
  const [SameAllCOPE, setSameAllCOPE] = useState(false);
  const [showCreateName, setshowCreateName] = useState(false);
  const [fieldChangeModule, setfieldChangeModule] = useState(false);
  const [fieldChangeField_list, setfieldChangeField_list] = useState([]);
  const [fieldChangeField_list_math, setfieldChangeField_list_math] = useState(
    []
  );
  const [module_change_data_based, setmodule_change_data_based] = useState([]);
  const [bookingCalendar_list, setbookingCalendar_list] = useState([]);
  const [web_page_url_list, setweb_page_url_list] = useState([]);
  const [If_ELSE_Array, setIf_ELSE_Array] = useState([
    {
      id: 1,
      module_name: "",
      field_name: null,
      condition_value: null,
      SearchInput: "",
      select: "AND",
      between: false,
    },
  ]);
  const [segment_field_change, setSegment_field_change] = useState([
    {
      id: 1,
      module_name: "",
      field_name: null,
      condition_value: null,
      SearchInput: "",
      select: "AND",
      between: false,
    },
  ]);
  const [segment_tags_add, setSegment_tags_add] = useState([
    {
      id: 1,
      module_name: "",
      field_name: null,
      condition_value: null,
      SearchInput: "",
      select: "AND",
      between: false,
    },
  ]);
  const [segment_tags_remove, setSegment_tags_remove] = useState([
    {
      id: 1,
      module_name: "",
      field_name: null,
      condition_value: null,
      SearchInput: "",
      select: "AND",
      between: false,
    },
  ]);
  const [segment_webPage_visited, setSegment_webPage_visited] = useState([
    {
      id: 1,
      module_name: "",
      field_name: null,
      condition_value: null,
      SearchInput: "",
      select: "AND",
      between: false,
    },
  ]);
  const [segment_date_based, setSegment_date_based] = useState([
    {
      id: 1,
      module_name: "",
      field_name: null,
      condition_value: null,
      SearchInput: "",
      select: "AND",
      between: false,
    },
  ]);
  const [segmentaction_wait_for, setsegmentaction_wait_for] = useState([
    {
      id: 1,
      module_name: "",
      field_name: null,
      condition_value: null,
      SearchInput: "",
      select: "AND",
      between: false,
    },
  ]);
  const [allAutomation_data, setallAutomation_data] = useState("");

  const [webHook_params, setwebHook_params] = useState([
    { id: 1, key: "", value: "" },
  ]);

  // edit action states /////

  const [edit_action_data, setedit_action_data] = useState(null);
  const [content, setContent] = useState("");
  // edit event action
  const { permissions } = useContext(MainAuthPermissionsContext);
  const { addActionList, Actionlist } = useContext(MainActionListContext);
  // const { translations } = useContext(MainTranslationContexts);
  const [assigntoImg, setAssigntoImg] = useState(permissions?.avatar);
  const date = dayjs();
  const [dateValue, setDateValue] = useState(date);
  const [ownerhidden, setOwnerhidden] = useState(permissions?.id);
  const [searchval, setSearchval] = useState("");
  const [listOpen, setListOpen] = useState(false);
  const [showEdit2, setShowEdit2] = useState(false);
  const [pipelines, setPipelines] = useState(
    Actionlist.length ? Actionlist[0].db_id : ""
  );
  const [priority_list, setPriority_list] = useState([]);
  const [priority, setPriority] = useState("");
  const [title, setTitle] = useState("");
  const inputElement = useRef();
  const ownerRef = useRef(null);
  const [resowner, apiMethodowner] = usePost();
  const [createAction, apiMethodCreateAction] = usePost("");
  const [Assgns, setAssgns] = useState({});
  // edit event action

  const [
    fieldChangeField_list_update_field_edit,
    setfieldChangeField_list_update_field_edit,
  ] = useState([]);

  // edit action end /////

  let math_operation_list = [
    { label: "Add" },
    { label: "Substract" },
    { label: "Multiply" },
    { label: "Divide" },
  ];
  const InitialSameModal = {
    tag_to_add_contact: edit_action_data?.actionDetails?.tag_value,
  };
  const InitialSameModal2 = {
    tag_to_add_contact: edit_action_data?.actionDetails?.tag_value,
  };
  const InitialSameModal_add_event_edit = {
    tag_to_add_contact: "",
  };
  const InitialSameModal_add_automation_edit = {
    add_automation_select: edit_action_data?.actionDetails?.automation_select,
    add_automation_select_list: [],
  };
  const InitialSameModal_update_field = {
    module_name: edit_action_data?.actionDetails?.module_name,
    updated_field_value: edit_action_data?.actionDetails?.updated_field_value,
    field_type: edit_action_data?.actionDetails?.field_type ?? "",
    field_module: edit_action_data?.actionDetails?.field_module ?? "",
    field_name: edit_action_data?.actionDetails?.field_name ?? "",
  };
  const InitialSameModal_math_operation_automation = {
    math_operation: edit_action_data?.actionDetails?.math_operation,
    module_math: edit_action_data?.actionDetails?.module_math,
    field_math: edit_action_data?.actionDetails?.field_math,
    value_number: edit_action_data?.actionDetails?.value_number,
  };
  const InitialSameModal_wait_for = {
    radioOption1: edit_action_data?.actionDetails?.radioOption1 ?? "",
    wait_for: edit_action_data?.actionDetails?.wait_for ?? "",
    wait_time_count:
      edit_action_data?.actionDetails?.radioOption1 === "wait_1"
        ? edit_action_data?.actionDetails?.wait_time_count ?? ""
        : "",
    wait_time_days:
      edit_action_data?.actionDetails?.radioOption1 === "wait_1"
        ? edit_action_data?.actionDetails?.wait_time_days ?? ""
        : "",
    time_limit:
      edit_action_data?.actionDetails?.radioOption1 === "wait_2"
        ? edit_action_data?.actionDetails?.time_limit ?? ""
        : "",
  };
  const navigate = useNavigate();

  // state managemnet  //////////////////////////////////////////////////////////////\\\\\\\\\\\\\\\\\\\\\\ for  Tree Node data
  const [treeData, setTreeData] = useState([
    // {
    //     id: 1,
    //     label: 'parent',
    //     children: [
    //         {
    //             id: 2,
    //             label: 'Child',
    //             children: [],
    //         },
    //         {
    //             id: 3,
    //             label: 'Child1',
    //             children: [],
    //         },
    //     ],
    // },
  ]);
  const [state, dispatch] = useReducer(
    reducer_edit_action_handle,
    initialState_edit_action
  );

  axios.defaults.headers = {
    "Content-Type": "multipart/form-data",
    authentication: `${getTokenSession()}`,
  };
  useEffect(() => {
    if (res.data) {
      // navigate(`/${config.ddemoss}allautomation`);
    }
  }, [res]);

  useEffect(() => {
    if (BookingCalenderTriger) {
      axios.get(`${config.apiEndPoint}getAllCalendars`).then((res) => {
        setbookingCalendar_list(res.data);
      });
    }
    if (WebPageVisitedTriger) {
      axios.get(`${config.apiEndPoint}getAllViewSiteTracking`).then((res) => {
        setweb_page_url_list(res.data);
      });
    }
  }, [BookingCalenderTriger, WebPageVisitedTriger]);
  useEffect(() => {
    if (
      ActionStartAutomation ||
      ActionEndAnotherAutomation ||
      state.add_automation ||
      state.end_another_automation
    ) {
      axios
        .get(`${config.apiEndPoint}getAllViewAutomationAction`)
        .then((res) => {
          setallAutomation_data(res.data);
        });
    }
  }, [
    ActionStartAutomation,
    ActionEndAnotherAutomation,
    state.add_automation,
    state.end_another_automation,
  ]);
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setContent(data);
  };
  const showmodal = (event) => {
    console.log(typeof event, "tree");

    const item =
      typeof event === "string"
        ? event
        : event.target.closest(".item_automat").innerText;
    setHeaderModalName(item);
    setAutomationModal(true);
    setshow2(true);
    setshow(false);
    switch (item) {
      case "WEB HOOK LISTNER":
        setWebHookTriger(true);
        setFieldChangeTriger(false);
        setAddedTagTriger(false);
        setRemoveTagTriger(false);
        BookingCalenderTrigerset(false);
        WebPageVisitedTrigerset(false);
        setDateBasedTriger(false);
        break;
      case "FIELD CHANGE":
        setFieldChangeTriger(true);
        setWebHookTriger(false);
        setAddedTagTriger(false);
        setRemoveTagTriger(false);
        BookingCalenderTrigerset(false);
        WebPageVisitedTrigerset(false);
        setDateBasedTriger(false);
        break;
      case "TAG ADDED":
        setAddedTagTriger(true);
        setFieldChangeTriger(false);
        setWebHookTriger(false);
        setRemoveTagTriger(false);
        BookingCalenderTrigerset(false);
        WebPageVisitedTrigerset(false);
        setDateBasedTriger(false);
        break;
      case "TAG REMOVED":
        setRemoveTagTriger(true);
        setAddedTagTriger(false);
        setFieldChangeTriger(false);
        setWebHookTriger(false);
        BookingCalenderTrigerset(false);
        WebPageVisitedTrigerset(false);
        setDateBasedTriger(false);

        break;
      case "BOOKING CALENDAR":
        BookingCalenderTrigerset(true);
        setRemoveTagTriger(false);
        setAddedTagTriger(false);
        setFieldChangeTriger(false);
        setWebHookTriger(false);
        WebPageVisitedTrigerset(false);
        setDateBasedTriger(false);

        break;
      case "WEB PAGE VISITED":
        WebPageVisitedTrigerset(true);
        BookingCalenderTrigerset(false);
        setRemoveTagTriger(false);
        setAddedTagTriger(false);
        setFieldChangeTriger(false);
        setWebHookTriger(false);
        setDateBasedTriger(false);
        break;
      case "DATE BASED":
        setDateBasedTriger(true);
        WebPageVisitedTrigerset(false);
        BookingCalenderTrigerset(false);
        setRemoveTagTriger(false);
        setAddedTagTriger(false);
        setFieldChangeTriger(false);
        setWebHookTriger(false);
        break;
    }
    // if (item === "Field changes") {
    //     setshowdatamodal_form(true)
    //     setselctor_show(false)
    //     setshow2(true)
    //     setAutomationModal(true)
    //     setshow(false)
    // }
    // else if (item === "Tag is added" || item === "Tag is removed" || item === "Wholesale" || item === "Affiliate Account" || item === "Admin account" || item === "Type form fill") {
    //     setshow(false)
    //     setAutomationModal(true)
    //     setshow2(true)
    //     setselctor_show(true)
    //     setshowdatamodal_form(false)
    // }
    // switch(item)
  };

  // ////////// add trigger direct function ////////////
  const Add_new_conatact_trigger = () => {
    setshow(false);
    setmainTree_Top((prev_data) => [
      ...prev_data,
      {
        tittle: `Add New Contact`,
        trigger_name: "ADD NEW CONTACT",

        sub_tittle: ``,
        id: Math.random() + new Date(),
        formData: [],
        actionDetails:
          prev_data.length && prev_data[0].actionDetails
            ? [...prev_data[0].actionDetails]
            : [],
        segment: [],
      },
    ]);
  };
  const Add_new_opportunity_trigger = () => {
    setshow(false);
    setmainTree_Top((prev_data) => [
      ...prev_data,
      {
        tittle: `Add New Opportunity`,
        trigger_name: "ADD NEW OPPORTUNITY",

        sub_tittle: ``,
        id: Math.random() + new Date(),
        formData: [],
        actionDetails:
          prev_data.length && prev_data[0].actionDetails
            ? [...prev_data[0].actionDetails]
            : [],
        segment: [],
      },
    ]);
  };
  const Add_new_project_trigger = () => {
    setshow(false);
    setmainTree_Top((prev_data) => [
      ...prev_data,
      {
        tittle: `Add New Project`,
        trigger_name: "ADD NEW PROJECT",

        sub_tittle: ``,
        id: Math.random() + new Date(),
        formData: [],
        actionDetails:
          prev_data.length && prev_data[0].actionDetails
            ? [...prev_data[0].actionDetails]
            : [],
        segment: [],
      },
    ]);
  };
  const Add_new_event_trigger = () => {
    setshow(false);
    setmainTree_Top((prev_data) => [
      ...prev_data,
      {
        tittle: `Add New Event`,
        trigger_name: "ADD NEW EVENT",

        sub_tittle: ``,
        id: Math.random() + new Date(),
        formData: [],
        actionDetails:
          prev_data.length && prev_data[0].actionDetails
            ? [...prev_data[0].actionDetails]
            : [],
        segment: [],
      },
    ]);
  };

  // ////////// add trigger direct function ////////////

  const closeModal = () => {
    setshow2(false);
    // setshow(true);
    setAutomationModal(false);
    setInitialFieldChangeTriger({
      segment_checkbox_field: "",
      // add_update: "",
      run_time: "",
      field_change_to_specific_value: "",
      field_change_to: "",
      field_change_from_specific_value: "",
      field_change_from: "",
      field: "",
      field_change_module: "",
    });
    setInitialAddedTagTriger({
      segment_checkbox_tag_added: "",
      tags_added: "",
      tags_added_runs: "",
      tags_added_module: "",
    });
    setInitialRemoveTagTriger({
      segment_checkbox_tag_removed: "",
      tags_removed: "",
      tags_removed_runs: "",
      tags_removed_module: "",
    });
    setInitialBookingCalenderTriger({
      booking_calendar_appoinment_id: "",
      booking_calendar_appoinment_name: "",
      booking_calendar_run: "",
    });
    setInitialWebPageVisitedTriger({
      web_page_URL_id: "",
      web_page_URL_name: "",
      runs: "",
      web_page_URL_sub: "",
      web_page_segment: "",
    });
    setInitialDateBasedTriger({
      module_date_based: "",
      start_num_date_based: "",
      start_days_date_based: "",
      start_on_bef_aft_date_based: "",
      start_on_the_date_date_based: "",
      start_run_day_date_based: "",
      start_hour_date_based: "",
      timezone_date_based: "",
      runs_date_based: "",
    });
    setSegment_date_based([
      {
        id: 1,
        module_name: "",
        field_name: null,
        condition_value: null,
        SearchInput: "",
        select: "AND",
        between: false,
      },
    ]);
    setSegment_field_change([
      {
        id: 1,
        module_name: "",
        field_name: null,
        condition_value: null,
        SearchInput: "",
        select: "AND",
        between: false,
      },
    ]);
    setSegment_tags_add([
      {
        id: 1,
        module_name: "",
        field_name: null,
        condition_value: null,
        SearchInput: "",
        select: "AND",
        between: false,
      },
    ]);
    setSegment_tags_remove([
      {
        id: 1,
        module_name: "",
        field_name: null,
        condition_value: null,
        SearchInput: "",
        select: "AND",
        between: false,
      },
    ]);
    setSegment_webPage_visited([
      {
        id: 1,
        module_name: "",
        field_name: null,
        condition_value: null,
        SearchInput: "",
        select: "AND",
        between: false,
      },
    ]);
  };

  // useEffect(() => {
  //     setIf_ELSE_Array(prevArray => [...prevArray, Math.random()]);
  // }, [IF_ELSEROWS]);
  const AddCondition_IF_Else = () => {
    setIf_ELSE_Array((prevArray) => [...prevArray, Math.random()]);
  };
  const HandleActionName = (event) => {
    const selected = event.target.closest(
      ".addActionsMenuContent_automat_each"
    ).innerText;
    const formated = selected.replace(/\n/g, "");
    // console.log(formated);
    setActionsTriggerModal(true);
    setshow1(false);
    // condition overflow  ..........case
    switch (formated) {
      case "WaitWait for a certain period of time, or until conditions are matched.":
        setActionWait(true);
        setActionIf_Else(false);
        setActionStartAutomation(false);
        setActionEndAutomation(false);
        setActionEndAnotherAutomation(false);
        setAtionWebhook(false);
        setActionPerformMath(false);
        setHeaderModalActions("Add a wait Condition");
        break;
      case "If/ElseContinue the automation in a different way depending on whether the conditions are matched.":
        setActionIf_Else(true);
        setActionWait(false);
        setActionStartAutomation(false);
        setActionEndAutomation(false);
        setActionEndAnotherAutomation(false);
        setAtionWebhook(false);
        setActionPerformMath(false);
        setHeaderModalActions("How would you like to split this automation ? ");
        break;
      case "Start an automationchoose an automation to start as an action":
        setActionStartAutomation(true);
        setActionIf_Else(false);
        setActionWait(false);
        setActionEndAutomation(false);
        setActionEndAnotherAutomation(false);
        setAtionWebhook(false);
        setActionPerformMath(false);
        setHeaderModalActions("Select an automation to enter");

        break;
      case "End this automationThis is where this automation will end":
        // setActionEndAutomation(true)
        // setActionWait(false)
        // setActionIf_Else(false)
        // setActionStartAutomation(false)
        // setActionEndAnotherAutomation(false)
        // setAtionWebhook(false)
        // setActionPerformMath(false)
        setActionsTriggerModal(false);

        break;
      case "End another automationHave this contact end another automation if they are currently in it.":
        setActionEndAnotherAutomation(true);
        setActionWait(false);
        setActionIf_Else(false);
        setActionStartAutomation(false);
        setActionEndAutomation(false);
        setAtionWebhook(false);
        setActionPerformMath(false);
        setHeaderModalActions("Select an automation to exit");
        break;
      case "WebhookPost a contact data to a URL of your choice.":
        setAtionWebhook(true);
        setActionWait(false);
        setActionIf_Else(false);
        setActionStartAutomation(false);
        setActionEndAutomation(false);
        setActionEndAnotherAutomation(false);
        setActionPerformMath(false);
        setHeaderModalActions("Webhook");

        break;
      case "Perform mathPerform math on a contact or deal numeric or date custom field.":
        setActionPerformMath(true);
        setActionWait(false);
        setActionIf_Else(false);
        setActionStartAutomation(false);
        setActionEndAutomation(false);
        setActionEndAnotherAutomation(false);
        setAtionWebhook(false);
        setHeaderModalActions("Math operation");
        break;
    }
  };
  const SameModel = (event) => {
    const selected = event.target.closest(
      ".addActionsMenuContent_automat_each"
    ).innerText;
    const formated = selected.replace(/\n/g, "");
    setSameAllCOPE(true);
    setshow1(false);
  };
  const InitialWebHookTriger = {};
  let [InitialFieldChangeTriger, setInitialFieldChangeTriger] = useState({
    segment_checkbox_field: "",
    // add_update: "",
    run_time: "",
    field_change_to_specific_value: "",
    field_change_to: "",
    field_change_from_specific_value: "",
    field_change_from: "",
    field: "",
    field_change_module: "",
  });
  const [InitialAddedTagTriger, setInitialAddedTagTriger] = useState({
    segment_checkbox_tag_added: "",
    tags_added: "",
    tags_added_runs: "",
    tags_added_module: "",
  });
  const [InitialRemoveTagTriger, setInitialRemoveTagTriger] = useState({
    segment_checkbox_tag_removed: "",
    tags_removed: "",
    tags_removed_runs: "",
    tags_removed_module: "",
  });
  const [InitialBookingCalenderTriger, setInitialBookingCalenderTriger] =
    useState({
      booking_calendar_appoinment_id: "",
      booking_calendar_appoinment_name: "",
      booking_calendar_run: "",
    });
  const [InitialWebPageVisitedTriger, setInitialWebPageVisitedTriger] =
    useState({
      web_page_URL_id: "",
      web_page_URL_name: "",
      runs: "",
      web_page_URL_sub: "",
      web_page_segment: "",
    });
  const [InitialDateBasedTriger, setInitialDateBasedTriger] = useState({
    module_date_based: "",
    start_num_date_based: "",
    start_days_date_based: "",
    start_on_bef_aft_date_based: "",
    start_on_the_date_date_based: "",
    start_run_day_date_based: "",
    start_hour_date_based: "",
    timezone_date_based: "",
    runs_date_based: "",
  });
  const InitialWaitAction = {
    wait_for: "wait for",
  };
  const InitialIf_ElseAction = {};
  const InitialStartAutomationAction = {
    add_automation_select: [],
    add_automation_select_list: [],
  };
  const InitialEndAutomationAction = {
    add_automation_select: [],
    add_automation_select_list: [],
  };
  const InitialEndAnotherAutomationAction = {
    request_type: "Post",
    request_url: "",
    content_type: "",
    header_key: "",
    header_value: "",
  };
  const InitialWebhookAction = {};
  const InitialPerformMathAction = {
    math_operation: "",
    module_math: "",
    field_math: "",
    value_number: "",
  };

  // tree node functions ......................................................
  const run_time = [
    {
      label: "first time",
      value: "first_time",
    },
    {
      label: "every time",
      value: "every_time",
    },
  ];
  const add_update = [
    {
      label: "Added or Updated ",
      value: "Added_or_Updated",
    },
    {
      label: "Added",
      value: "Added",
    },
    {
      label: "Updated",
      value: "Updated",
    },
  ];
  const DropDownDatas = [
    {
      Module: "Lead",
      id: 1,
    },
    {
      Module: "Prospect",
      id: 2,
    },
    {
      Module: "Client",
      id: 3,
    },
    {
      Module: "Opportunity",
      id: 4,
    },
    {
      Module: "Project",
      id: 4,
    },
    {
      Module: "Action",
      id: 5,
    },
    {
      Module: "Follow Up",
      id: 6,
    },
  ];
  const timeArray = [
    { value: "00 ", label: " 0  (12 AM)" },
    { value: "01 ", label: " 1  (1 AM)" },
    { value: "02 ", label: " 2  (2 AM)" },
    { value: "03 ", label: " 3  (3 AM)" },
    { value: "04 ", label: " 4  (4 AM)" },
    { value: "05 ", label: " 5  (5 AM)" },
    { value: "06 ", label: " 6  (6 AM)" },
    { value: "07 ", label: " 7  (7 AM)" },
    { value: "08 ", label: " 8  (8 AM)" },
    { value: "09 ", label: " 9  (9 AM)" },
    { value: "10", label: " 10 (10 AM)" },
    { value: "11", label: " 11 (11 AM)" },
    { value: "12", label: " 12 (12 PM)" },
    { value: "13", label: " 13  (1 PM)" },
    { value: "14", label: " 14  (2 PM)" },
    { value: "15", label: " 15  (3 PM)" },
    { value: "16", label: " 16  (4 PM)" },
    { value: "17", label: " 17  (5 PM)" },
    { value: "18", label: " 18  (6 PM)" },
    { value: "19", label: " 19  (7 PM)" },
    { value: "20", label: " 20  (8 PM)" },
    { value: "21", label: " 21  (9 PM)" },
    { value: "22", label: " 22 (10 PM)" },
    { value: "23", label: " 23 (11 PM)" },
  ];

  const not_edit_trigger = [
    "ADD NEW CONTACT",
    "ADD NEW OPPORTUNITY",
    "ADD NEW PROJECT",
    "ADD NEW EVENT",
  ];
  const ActionModalCanelHandle = () => {
    setActionsTriggerModal(false);
    setshow1(true);

    setIf_ELSE_Array([
      {
        id: 1,
        module_name: "",
        field_name: null,
        condition_value: null,
        SearchInput: "",
        select: "AND",
        between: false,
      },
    ]);
  };

  const handleSubmit_Action_Modals = () => {
    setActionsTriggerModal(false);
    setshow1(false);
  };

  const handleFieldChangeValue = (value) => {
    setfieldChangeModule(value);
    axios
      .post(`${config.apiEndPoint}postAutomationTriggerModuleFilds`, {
        submit: "automationTrgrModuleFild",
        module: value,
      })
      .then((res) => {
        setfieldChangeField_list(
          res.data.fetchColumns.map((val) => ({
            label: val.Field ? val.Field : val.name,
          }))
        );
      });
  };

  const handleFieldChangeValue2 = (value) => {
    axios
      .post(`${config.apiEndPoint}postAutomationTriggerModuleFilds`, {
        submit: "automationTrgrModuleFild",
        module: value,
      })
      .then((res) => {
        let a = res.data.fetchColumns.filter((val) => {
          let type = val.type ?? val.Type;
          if (
            type === "number" ||
            type === "datetime" ||
            type === "date" ||
            type === "timestamp" ||
            type === "time"
          )
            return {};
        });
        console.log(a);
        setfieldChangeField_list_math(
          a.map((val) => ({
            label: val.Field ? val.Field : val.name,
            type: val.type ?? val.Type,
          }))
        );
      });
  };
  const handle_module_change_data_based = (value) => {
    axios
      .post(`${config.apiEndPoint}postAutomationTriggerModuleFilds`, {
        submit: "automationTrgrModuleFild",
        module: value,
      })
      .then((res) => {
        let a = res.data.fetchColumns.filter((val) => {
          let type = val.type ?? val.Type;
          if (
            type === "datetime" ||
            type === "date" ||
            type === "timestamp" ||
            type === "time"
          )
            return {};
        });
        console.log(a);
        setmodule_change_data_based(
          a.map((val) => ({
            label: val.Field ? val.Field : val.name,
            type: val.type ?? val.Type,
          }))
        );
      });
  };
  // const node_repeater=(data,obj)=>{
  //   data.map((val,index)=>{
  //     if(val.children.length){
  //       val.children.map((val2)=>{
  //         if(val2.children.length){
  //           node_repeater(val2.children,obj)
  //         }else{
  //           if(!obj.ifelse){val2.children.push(obj)}else{
  //             obj.children=[{
  //               id:Math.random(),
  //               label:"yes",
  //               children:[]
  //             },{
  //               id:Math.random(),
  //               label:"no",
  //               children:[]
  //             }]
  //             val2.children.push(obj)
  //           }
  //         }
  //       })
  //     }else{
  //       if(!obj.ifelse){val.children.push(obj)}else{
  //         obj.children=[{
  //           id:Math.random(),
  //           label:"yes",
  //           children:[]
  //         },{
  //           id:Math.random(),
  //           label:"no",
  //           children:[]
  //         }]
  //         val.children.push(obj)
  //       }
  //     }
  //       })

  // }
  const node_repeater = (data, obj) => {
    // Use forEach instead of map for side effects
    data.forEach((val) => {
      if (val.children.length) {
        // Recursively call the function on the children
        node_repeater(val.children, obj);
      } else {
        // Use concat instead of push to create a new array
        if (nodeid === val.id) {
          val.children = obj.ifelse
            ? val.children.concat({
                ...obj,
                children: [
                  {
                    id: Math.random() + new Date(),
                    label: "yes",
                    children: [],
                  },
                  { id: Math.random() + new Date(), label: "no", children: [] },
                ],
              })
            : val.children.concat(obj);
        }
      }
    });
  };
  const updateProperties = (target, source) => {
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        target[key] = source[key];
      }
    }
  };

  const node_repeater_edit = (data, obj) => {
    // Use forEach instead of map for side effects
    data.forEach((val) => {
      if (val.children.length && val.id !== action_id) {
        // Recursively call the function on the children
        node_repeater_edit(val.children, obj);
      } else {
        // Use concat instead of push to create a new array
        if (action_id === val.id) {
          obj.children = [...val.children];
          updateProperties(val, obj);
          console.log(val);
        }
      }
    });
  };
  const node_test = (obj) => {
    if (treeData.length) {
      let copytreedata = [...treeData];
      if (copytreedata[0].children.length) {
        node_repeater(copytreedata[0].children, obj);
      } else {
        if (!obj.ifelse) {
          copytreedata[0].children.push(obj);
        } else {
          obj.children = [
            {
              id: Math.random() + new Date(),
              label: "yes",
              children: [],
            },
            {
              id: Math.random() + new Date(),
              label: "no",
              children: [],
            },
          ];
          copytreedata[0].children.push(obj);
        }
      }
      setTreeData(copytreedata);
    }
  };
  const node_test_edit = (obj) => {
    if (treeData.length) {
      let copytreedata = [...treeData];
      if (
        copytreedata[0]?.children.length &&
        copytreedata[0]?.id !== action_id
      ) {
        console.log("redit2", copytreedata);
        node_repeater_edit(copytreedata[0].children, obj);
      } else {
        obj.children = [...copytreedata[0].children];
        copytreedata[0] = { ...obj };
      }
      setTreeData(copytreedata);
    }
  };
  // const childrenPush_map = (comming_data, data_to_push) => {
  //   let datas = [...comming_data];
  //   let wait_obj = {
  //     id: Math.random(),
  //     label: `sdfsscscssd`,
  //     icon: "fas fa-clock",
  //     children: [],
  //   };
  //   console.log(datas, data_to_push, "mappp");

  //   datas.map((data, index) => {
  //     if (data.children.length) {
  //       data.children.map((val, index) => {
  //         if (val.children.length) {
  //           console.log(val, "ifff");
  //           childrenPush_map(val.children, data_to_push);
  //         } else {
  //           console.log(val, "elseee");
  //           val.children.push(data_to_push);
  //         }
  //       });
  //     } else {
  //       data.children.push(data_to_push);
  //     }
  //   });
  //   return datas;
  // };
  // function addspecificnode(nodeId) {
  //     setTreeData((prevTreeData) => {
  //         // Find the node in the treeData array using the nodeId
  //         const updatedTreeData = [...prevTreeData];

  //         const findNodeAndAddChild = (data) => {
  //             for (let i = 0; i < data.length; i++) {
  //                 const node = data[i];
  //                 if (node.id === nodeId) {
  //                     // Check if the child node already exists
  //                     const childExists = node.children.some(child => child.label === `Child of ${node.label}`);
  //                     if (!childExists) {
  //                         // Add a new child node to the found node
  //                         const newChildNode = {
  //                             id: Date.now(), // Generate a unique ID for the new child node
  //                             label: `Child of ${node.label}`,
  //                             children: []
  //                         };
  //                         node.children.push(newChildNode);
  //                     }
  //                     break; // Exit the loop once the node is found and the child is added
  //                 }
  //                 if (node.children && node.children.length > 0) {
  //                     findNodeAndAddChild(node.children);
  //                 }
  //             }
  //         };

  //         findNodeAndAddChild(updatedTreeData); // Call the recursive function to find the node and add the child

  //         return updatedTreeData; // Return the updated treeData
  //     });
  // }
  const fields_according_to_module = (module) => {
    axios
      .post(`${config.apiEndPoint}postLeadDeletedCorrelations`, module)
      .then((response) => {
        return response.data;
      });
  };

  function addspecificnode(nodeId) {
    const numChildrenToAdd = parseInt(
      prompt("Enter the number of children to add:")
    );

    setTreeData((prevTreeData) => {
      // Find the node in the treeData array using the nodeId
      const updatedTreeData = [...prevTreeData];

      const findNodeAndAddChild = (data) => {
        for (let i = 0; i < data.length; i++) {
          const node = data[i];
          if (node.id === nodeId) {
            // Check if the child node already exists
            const childExists = node.children.some(
              (child) => child.label === `Child of ${node.label}`
            );

            if (!childExists) {
              // Add new child nodes to the found node
              let v = 0;
              for (let j = 0; j < numChildrenToAdd; j++) {
                const newChildNode = {
                  id: Date.now() + j,
                  label: `Child ${j + 1} of ${node.label}`,
                  icon: "fas fa-envelope",
                  children: [],
                };
                node.children.push(newChildNode);
                v++;
                console.log(v);
              }
            }
            break; // Exit the loop once the node is found and the children are added
          }

          if (node.children && node.children.length > 0) {
            findNodeAndAddChild(node.children);
          }
        }
      };

      findNodeAndAddChild(updatedTreeData); // Call the recursive function to find the node and add the children

      return updatedTreeData; // Return the updated treeData
    });
  }

  function generateTreeNodes(data) {
    return data.map((node, index) => {
      const hasChildren = node.children && node.children.length > 0;

      return (
        <TreeNode
          className="Tree-node-pr"
          key={index}
          label={
            node.type !== "add_note" ? (
              <div
                className="each-treeNode-auto "
                style={{ backgroundColor: node.bgColor, color: node.color }}
                onMouseEnter={() => setHoveredIndex(node.id)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                {HoveredIndex === node.id && (
                  <div className="hover-delete action_edit_delete">
                    <div className="delete-icon d-flex gap-2 py-2">
                      <FaEdit onClick={() => HandleEdit_Action(node, index)} />

                      <FaTrash
                        onClick={() => HandleDelete_Action(node, index)}
                      />
                    </div>
                  </div>
                )}
                {<i className={`${node.icon} me-2`}></i>}
                {node.label}
              </div>
            ) : (
              <div
                className="each-treeNode-auto "
                style={{
                  backgroundColor: node.bgColor,
                  color: node.color,
                  display: "flex",
                }}
                onMouseEnter={() => setHoveredIndex(node.id)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                {<i className={`${node.icon} me-2`}></i>} Add Note "
                <span
                  dangerouslySetInnerHTML={{
                    __html: node?.actionDetails?.note_value ?? "",
                  }}
                ></span>{" "}
                " in {node?.actionDetails?.module_name ?? ""}
                {HoveredIndex === node.id && (
                  <div className="hover-delete action_edit_delete">
                    <div className="delete-icon d-flex gap-2 py-2">
                      <FaEdit onClick={() => HandleEdit_Action(node, index)} />

                      <FaTrash
                        onClick={() => HandleDelete_Action(node, index)}
                      />
                    </div>
                  </div>
                )}
              </div>
            )
          }
        >
          {hasChildren ? (
            generateTreeNodes(node.children)
          ) : node?.type === "end_automation" ? (
            ""
          ) : (
            <TreeNode
              label={
                <div
                  className="nodestyling"
                  onClick={() => {
                    if (mainTree_Top.length) {
                      setnodeid(node.id);
                      console.log(node, "SAdsad");
                      setshow1(true);
                    } else {
                      swal({
                        title: "Atleast add one trigger first",
                        icon: "error",
                      });
                    }
                  }}
                >
                  +
                </div>
              }
            />
          )}
        </TreeNode>
      );
    });
  }
  // let node_obj = {
  //   id: Math.random() + new Date(),
  //   label: "if else statement",
  //   ifelse: true,
  //   bgColor: "#4287f5",
  //   children: [],
  // };
  // let node_obj2 = {
  //   id: Math.random() + new Date(),
  //   label: "single task",
  //   ifelse: false,
  //   bgColor: "#c1f7eb",
  //   children: [],
  // };

  // handle submits for actions

  const HandleActionWaitSubmit = (v) => {
    // if (v.radioOption1 && v.radioOption1 === "wait_1") {
    //   let wait_obj = {
    //     id: Math.random() + new Date(),
    //     label: `${v.wait_for} ${v.wait_time_count ? v.wait_time_count : ""
    //       } ${v.wait_time_days ? v.wait_time_days : ""}`,
    //     icon: "fas fa-clock",
    //     children: [],
    //     bgColor: "#69afff",
    //     color: "#00000",
    //     ifelse: false,
    //     formdata: [v],
    //     actionDetails: v,
    //   };
    //   treeData.length === 0
    //     ? setTreeData([wait_obj])
    //     : node_test(wait_obj);
    //   // console.log(treeData, "asdasds");
    // }
    console.log("v", v);
    console.log("segmentaction_wait_for", segmentaction_wait_for);

    if (v.radioOption1 === "wait_1") {
      if (!v.wait_for || !v.wait_time_count || !v.wait_time_days) {
        swal({
          title: "Fill all the required fields",
          icon: "error",
        });
        return;
      }

      let wait_obj = {
        type: "wait_for",
        id: Math.random() + new Date(),
        label: `${v.wait_for} ${v.wait_time_count ? v.wait_time_count : ""} ${
          v.wait_time_days ? v.wait_time_days : ""
        }`,
        icon: "fas fa-clock",
        children: [],
        bgColor: "#69afff",
        color: "#00000",
        ifelse: false,
        formdata: [v],
        actionDetails: v,
      };

      treeData.length === 0 ? setTreeData([wait_obj]) : node_test(wait_obj);
      if (mainTree_Top.length) {
        let b = mainTree_Top.map((trigger) => {
          trigger.actionDetails = [...trigger.actionDetails, wait_obj];
          return trigger;
        });
        setmainTree_Top(b);
      }
    } else if (v.radioOption1 === "wait_2") {
      let segmentError = false;
      let segmentLabel = "";
      segmentaction_wait_for.map((segments) => {
        if (
          !segments.module_name ||
          !segments.field_name ||
          !segments.condition_value ||
          !segments.SearchInput
        ) {
          segmentError = true;
        }

        segmentLabel += `${segments.module_name} ${segments.field_name} is ${segments.condition_value} to ${segments.SearchInput} ${segments.select}\n`;
      });

      if (segmentError) {
        swal({
          title: "Fill all the required fields",
          icon: "error",
        });
        return;
      }

      let wait_obj = {
        type: "wait_for",
        title: "wait_for",
        id: Math.random() + new Date(),
        label: segmentLabel, //v.time_limit,
        icon: "fas fa-clock",
        children: [],
        bgColor: "#69afff",
        color: "#00000",
        ifelse: false,
        formdata: [{ ...v, ...segmentaction_wait_for }],
        actionDetails: { ...v, ...segmentaction_wait_for },
      };

      treeData.length === 0 ? setTreeData([wait_obj]) : node_test(wait_obj);
      if (mainTree_Top.length) {
        let b = mainTree_Top.map((trigger) => {
          trigger.actionDetails = [...trigger.actionDetails, wait_obj];
          return trigger;
        });
        setmainTree_Top(b);
      }
    } else {
      swal({
        title: "Fill all the required fields",
        icon: "error",
      });
      return;
    }

    handleSubmit_Action_Modals();
    // if (v.radioOption1 && v.radioOption1 === "wait_1") {
    //   if (v.wait_for && v.wait_time_count && v.wait_time_days) {
    //     let wait_obj = {
    //       type: "wait_for",

    //       id: Math.random() + new Date(),
    //       label: `${v.wait_for} ${v.wait_time_count ? v.wait_time_count : ""} ${
    //         v.wait_time_days ? v.wait_time_days : ""
    //       }`,
    //       icon: "fas fa-clock",
    //       children: [],
    //       bgColor: "#69afff",
    //       color: "#00000",
    //       ifelse: false,
    //       formdata: [v],
    //       actionDetails: v,
    //     };
    //     treeData.length === 0 ? setTreeData([wait_obj]) : node_test(wait_obj);
    //     handleSubmit_Action_Modals();
    //     if (mainTree_Top.length) {
    //       let b = mainTree_Top.map((trigger) => {
    //         trigger.actionDetails = [...trigger.actionDetails, wait_obj];
    //         return trigger;
    //       });
    //       setmainTree_Top(b);
    //     }
    //     // console.log(treeData, "asdasds");
    //   } else {
    //     swal({
    //       title: "Fill all the required fields",
    //       icon: "error",
    //     });
    //   }
    // } else if (v.radioOption1 && v.radioOption1 === "wait_2") {
    //   if(!segmentaction_wait_for.length){
    //     swal({
    //       title: "Fill all the required fields",
    //       icon: "error",
    //     });
    //     return;
    //   }

    //   let segmentError = [];
    //   segmentaction_wait_for.map((segments) => {

    //     if(!segments.module_name || !segments.field_name || !segments.condition_value || !segments.SearchInput){
    //       segmentError.push("Errors");
    //     }

    //     let wait_obj = {
    //       type: "wait_for",
    //       title: "wait_for",
    //       id: Math.random() + new Date(),
    //       label: v.time_limit,
    //       icon: "fas fa-clock",
    //       children: [],
    //       bgColor: "#69afff",
    //       color: "#00000",
    //       ifelse: false,
    //       formdata: [{...v,...segments}],
    //       actionDetails: {...v,...segments},
    //     };

    //     treeData.length === 0 ? setTreeData([wait_obj]) : node_test(wait_obj);
    //     if (mainTree_Top.length) {
    //       let b = mainTree_Top.map((trigger) => {
    //         trigger.actionDetails = [...trigger.actionDetails, wait_obj];
    //         return trigger;
    //       });
    //       setmainTree_Top(b);
    //     }
    //   });

    //   if(segmentError.length){
    //     swal({
    //       title: "Fill all the required fields",
    //       icon: "error",
    //     });
    //     return;
    //   }

    //   handleSubmit_Action_Modals();

    //   // if (v.time_limit) {
    //   //   let wait_obj = {
    //   //     type: "wait_for",
    //   //     title: "wait_for",
    //   //     id: Math.random() + new Date(),
    //   //     label: v.time_limit,
    //   //     icon: "fas fa-clock",
    //   //     children: [],
    //   //     bgColor: "#69afff",
    //   //     color: "#00000",
    //   //     ifelse: false,
    //   //     formdata: [v],
    //   //     actionDetails: v,
    //   //   };
    //   //   treeData.length === 0 ? setTreeData([wait_obj]) : node_test(wait_obj);
    //   //   if (mainTree_Top.length) {
    //   //     let b = mainTree_Top.map((trigger) => {
    //   //       trigger.actionDetails = [...trigger.actionDetails, wait_obj];
    //   //       return trigger;
    //   //     });
    //   //     setmainTree_Top(b);
    //   //   }

    //   //   handleSubmit_Action_Modals();
    //   // // if (v.time_limit) {
    //   // //   let wait_obj = {
    //   // //     type: "wait_for",
    //   // //     title: "wait_for",
    //   // //     id: Math.random() + new Date(),
    //   // //     label: v.time_limit,
    //   // //     icon: "fas fa-clock",
    //   // //     children: [],
    //   // //     bgColor: "#69afff",
    //   // //     color: "#00000",
    //   // //     ifelse: false,
    //   // //     formdata: [v],
    //   // //     actionDetails: v,
    //   // //   };
    //   // //   treeData.length === 0 ? setTreeData([wait_obj]) : node_test(wait_obj);
    //   // //   if (mainTree_Top.length) {
    //   // //     let b = mainTree_Top.map((trigger) => {
    //   // //       trigger.actionDetails = [...trigger.actionDetails, wait_obj];
    //   // //       return trigger;
    //   // //     });
    //   // //     setmainTree_Top(b);
    //   // //   }

    //   // //   handleSubmit_Action_Modals();
    //   }
    //   else {
    //     swal({
    //       title: "Fill all the required fields",
    //       icon: "error",
    //     });
    //   }
    // }
  };

  const Handle_if_elseSubmit = (value) => {
    // let ifelse_obj = {
    //   bgColor: "#535353",
    //   color: "#fffff",
    //   id: Math.random() + new Date(),
    //   label: `Does this following condition match?( ${value.select_option_1} ${value.select_condition_1} ${value.condition_value_1} ${value.and_or_1}
    //    ${value.select_option_2} ${value.select_condition_2} ${value.condition_value_2} ${value.and_or_2} ${value.select_option_3} ${value.select_condition_3} ${value.condition_value_3} )`,
    //   icon: "fas fa-question-circle",
    //   children: [],
    //   ifelse: true,
    //   formData: [value],
    //   actionDetails: value,
    // };
    let reqField = [];

    If_ELSE_Array.length &&
      If_ELSE_Array.map((obj) => {
        Object.keys(obj).map((item) => {
          if (item !== "between") {
            if (!obj[item]) {
              reqField.push("segement");
            }
          }
        });
      });

    let s = If_ELSE_Array.reduce((acc, val, index) => {
      return acc.concat(
        ` (In ${index > 0 ? val.select : ""} ${val.module_name}  ${
          val.field_name
        } ${val.condition_value} ${val.SearchInput} )`
      );
    }, "");
    let ifelse_obj = {
      type: "if_else",

      title: "if_else",
      bgColor: "#535353",
      color: "#fffff",
      id: Math.random() + new Date(),
      label: `Does this following condition match? ${s}`,
      icon: "fas fa-question-circle",
      children: [
        {
          id: Math.random() + new Date(),
          label: "yes",
          type: "if",
          children: [],
        },
        {
          id: Math.random() + new Date(),
          label: "no",
          type: "else",
          children: [],
        },
      ],
      ifelse: true,
      formData: If_ELSE_Array,
      actionDetails: If_ELSE_Array,
    };
    // for (let key in value) {
    //   if (key.startsWith("select_option")) {
    //     const index = key.split("_")[2];
    //     const selectCondition = value[`select_condition_${index}`];
    //     const conditionValue = value[`condition_value_${index}`];
    //     const andOr = value[`and_or_${index}`];
    //     const selectOption = value[key];

    //     ifelse_obj.label += ` ${selectOption} ${selectCondition} ${conditionValue} ${
    //       andOr ? andOr : ""
    //     }`;
    //   }
    // }
    if (!reqField.length) {
      treeData.length === 0 ? setTreeData([ifelse_obj]) : node_test(ifelse_obj);
      if (mainTree_Top.length) {
        let b = mainTree_Top.map((trigger) => {
          trigger.actionDetails = [...trigger.actionDetails, ifelse_obj];
          return trigger;
        });
        setmainTree_Top(b);
      }
      handleSubmit_Action_Modals();
    } else {
      swal({
        title: "All Fields are Required",
        icon: "error",
      });
    }
  };
  const handleEnd_automation_submit = () => {
    let wait_obj = {
      type: "end_automation",

      id: Math.random() + new Date(),
      label: `End this automation`,
      icon: "fas fa-cancel",
      children: [],
      bgColor: "#69afff",
      color: "#00000",
      ifelse: false,
      formdata: [],
      actionDetails: {},
    };
    treeData.length === 0 ? setTreeData([wait_obj]) : node_test(wait_obj);
    handleSubmit_Action_Modals();
    if (mainTree_Top.length) {
      let b = mainTree_Top.map((trigger) => {
        trigger.actionDetails = [...trigger.actionDetails, wait_obj];
        return trigger;
      });
      setmainTree_Top(b);
    }
  };
  const handle_add_contact_tag = (
    value,
    setAutomation_addTag_module,
    module_name,
    backgroundColor
  ) => {
    console.log("value");
    if (value.tag_to_add_contact) {
      let contact_tag_obj = {
        type: "add_tag",
        title: `add_tag_${module_name}`,
        id: Math.random() + new Date(),
        label: `Add Tag ${value.tag_to_add_contact} in ${module_name}`,
        icon: "fas fa-tags",
        children: [],
        bgColor: backgroundColor,
        color: "#00000",
        ifelse: false,
        formdata: [{ tag_value: value.tag_to_add_contact }],
        actionDetails: {
          tittle: ` in_${module_name}`,
          type: "add_tag",
          module_name: module_name,
          tag_value: value.tag_to_add_contact,
        },
      };
      treeData.length === 0
        ? setTreeData([contact_tag_obj])
        : node_test(contact_tag_obj);
      if (mainTree_Top.length) {
        let b = mainTree_Top.map((trigger) => {
          trigger.actionDetails = [...trigger.actionDetails, contact_tag_obj];
          return trigger;
        });
        setmainTree_Top(b);
      }
      setSameAllCOPE(false);
      setshow1(false);
      setAutomation_addTag_module(false);
    }
  };
  /////////////// EDIT ACTION START //////////////////

  // /////// Edit Event All funcion///
  useEffect(() => {
    if (state.add_event) {
      axios.get(`${config.apiEndPoint}getAllViewActionPriority`).then((res) => {
        setPriority_list(res.data);
        setPriority(res.data.length ? res.data[0]?.priority_id : "");
      });
    }
  }, [state.add_event]);

  const handleClick = (item) => {
    if (item?.lead_follower !== "yes") {
      setSearchval(item.uname);
      setAssgns({
        id: item.id,
        image: item.avatar,
      });
    }
    setListOpen(false);
  };
  const handleClose = () => {
    setShowEdit2(false);
  };
  const handleSubmitAssign = () => {
    setOwnerhidden(Assgns.id);
    setAssigntoImg(Assgns.image);
    setShowEdit2(false);
  };
  const handleList = () => {
    let formdataOwner = new FormData();
    formdataOwner.append("userType", "typeSearch");
    formdataOwner.append("query", searchval);
    // formdataOwner.append("uLead", Id);
    apiMethodowner("postSpecifiesUsers", formdataOwner);
    setListOpen(!listOpen);
  };
  const handleAssignModalOpen = () => {
    setShowEdit2(true);
  };
  const handleEditEventActionSubmit = () => {
    let formobj = {};
    if (title.trim() != "") {
      formobj.title = title;
    } else {
      swal({
        title: "Fill required field",
        icon: "error",
      });
      return;
    }
    formobj.event_type = "action";
    formobj.pipeline = pipelines;
    formobj.priority = priority;
    formobj.event_members = ownerhidden;
    formobj.module = edit_action_data?.actionDetails?.module_name;
    formobj.create_event = "cReaTe_mOduLe_eVENt";
    formobj.eve_start_date = dayjs(dateValue)
      .tz(permissions["system-user_timezone"]?.setting_value)
      .format("DD-MM-YYYY");
    formobj.eve_start_time = dayjs(dateValue)
      .tz(permissions["system-user_timezone"]?.setting_value)
      .format("HH:mm:ss");
    formobj.eve_end_date = dayjs(dateValue)
      .add(1, "hour")
      .tz(permissions["system-user_timezone"]?.setting_value)
      .format("DD-MM-YYYY");
    formobj.eve_end_time = dayjs(dateValue)
      .add(1, "hour")
      .tz(permissions["system-user_timezone"]?.setting_value)
      .format("HH:mm:ss");

    handle_addEvent_module_edit(
      formobj,
      edit_action_data?.actionDetails?.module_name,
      edit_action_data?.bgColor
    );
  };
  const onOk = (value) => {
    setDateValue(value);
  };
  const Handle_if_elseSubmit_edit = (value) => {
    // let ifelse_obj = {
    //   bgColor: "#535353",
    //   color: "#fffff",
    //   id: Math.random() + new Date(),
    //   label: `Does this following condition match?( ${value.select_option_1} ${value.select_condition_1} ${value.condition_value_1} ${value.and_or_1}
    //    ${value.select_option_2} ${value.select_condition_2} ${value.condition_value_2} ${value.and_or_2} ${value.select_option_3} ${value.select_condition_3} ${value.condition_value_3} )`,
    //   icon: "fas fa-question-circle",
    //   children: [],
    //   ifelse: true,
    //   formData: [value],
    //   actionDetails: value,
    // };
    let reqField = [];

    If_ELSE_Array.length &&
      If_ELSE_Array.map((obj) => {
        Object.keys(obj).map((item) => {
          if (item !== "between") {
            if (!obj[item]) {
              reqField.push("segement");
            }
          }
        });
      });

    let s = If_ELSE_Array.reduce((acc, val, index) => {
      return acc.concat(
        ` (In ${index > 0 ? val.select : ""} ${val.module_name}  ${
          val.field_name
        } ${val.condition_value} ${val.SearchInput} )`
      );
    }, "");
    let ifelse_obj = {
      type: "if_else",

      title: "if_else",
      bgColor: "#535353",
      color: "#fffff",
      id: Math.random() + new Date(),
      label: `Does this following condition match? ${s}`,
      icon: "fas fa-question-circle",
      children: [
        {
          id: Math.random() + new Date(),
          label: "yes",
          type: "if",
          children: [],
        },
        {
          id: Math.random() + new Date(),
          label: "no",
          type: "else",
          children: [],
        },
      ],
      ifelse: true,
      formData: If_ELSE_Array,
      actionDetails: If_ELSE_Array,
    };
    // for (let key in value) {
    //   if (key.startsWith("select_option")) {
    //     const index = key.split("_")[2];
    //     const selectCondition = value[`select_condition_${index}`];
    //     const conditionValue = value[`condition_value_${index}`];
    //     const andOr = value[`and_or_${index}`];
    //     const selectOption = value[key];

    //     ifelse_obj.label += ` ${selectOption} ${selectCondition} ${conditionValue} ${
    //       andOr ? andOr : ""
    //     }`;
    //   }
    // }
    if (!reqField.length) {
      treeData.length === 0
        ? setTreeData([ifelse_obj])
        : node_test_edit(ifelse_obj);
      if (mainTree_Top.length) {
        let b = mainTree_Top.map((trigger) => {
          trigger.actionDetails.map((action_obj, index) => {
            if (action_obj.id === action_id) {
              updateProperties(action_obj, ifelse_obj);
            }
          });
          return trigger;
        });
        setmainTree_Top(b);
      }
      handleSubmit_Action_Modals();
      dispatch({ key: "if_else", value: false });
    } else {
      swal({
        title: "All Fields are Required",
        icon: "error",
      });
    }
  };

  // //// Edit update field action
  const handleFieldChangeValue_update_field_edit = (value) => {
    axios
      .post(`${config.apiEndPoint}postAutomationTriggerModuleFilds`, {
        submit: "automationTrgrModuleFild",
        module: value,
      })
      .then((res) => {
        setfieldChangeField_list_update_field_edit(
          res.data.fetchColumns.map((val) => ({
            label: val.Field ? val.Field : val.name,
            type: val.type ? val.type : val.Type,
          }))
        );
      });
  };

  // /////// Edit Event All funcion///

  const handle_add_contact_tag_edit = (
    value,
    setAutomation_addTag_module,
    module_name,
    backgroundColor
  ) => {
    if (value.tag_to_add_contact) {
      console.log("valueedit", treeData);
      let contact_tag_obj = {
        type: "add_tag",
        title: `add_tag_${module_name}`,
        id: Math.random() + new Date(),
        label: `Add Tag ${value.tag_to_add_contact} in ${module_name}`,
        icon: "fas fa-tags",
        children: [],
        bgColor: backgroundColor,
        color: "#00000",
        ifelse: false,
        formdata: [{ tag_value: value.tag_to_add_contact }],
        actionDetails: {
          tittle: ` in_${module_name}`,
          type: "add_tag",
          module_name: module_name,
          tag_value: value.tag_to_add_contact,
        },
      };
      treeData.length === 0
        ? setTreeData([contact_tag_obj])
        : node_test_edit(contact_tag_obj);
      if (mainTree_Top.length) {
        let b = mainTree_Top.map((trigger) => {
          trigger.actionDetails.map((action_obj, index) => {
            if (action_obj.id === action_id) {
              updateProperties(action_obj, contact_tag_obj);
            }
          });
          return trigger;
        });
        setmainTree_Top(b);
      }
      setSameAllCOPE(false);
      setshow1(false);
      setAutomation_addTag_module({ key: "add_tag", value: false });
    }
  };
  const handle_remove_contact_tag_edit = (
    value,
    setAutomation_addTag_module,
    module_name,
    backgroundColor
  ) => {
    console.log(value);
    if (value.tag_to_add_contact) {
      let contact_tag_obj = {
        type: "remove_tag",

        title: `remove_tag_${module_name}`,
        id: Math.random() + new Date(),
        label: `remove Tag ${value.tag_to_add_contact} in ${module_name}`,
        icon: "fas fa-tags",
        children: [],
        bgColor: backgroundColor,
        color: "#00000",
        ifelse: false,
        formdata: [{ tag_value: value.tag_to_add_contact }],
        actionDetails: {
          tittle: ` in_${module_name}`,
          type: "remove_tag",
          tag_value: value.tag_to_add_contact,
          module_name: module_name,
        },
      };
      treeData.length === 0
        ? setTreeData([contact_tag_obj])
        : node_test_edit(contact_tag_obj);
      if (mainTree_Top.length) {
        let b = mainTree_Top.map((trigger) => {
          trigger.actionDetails.map((action_obj, index) => {
            if (action_obj.id === action_id) {
              updateProperties(action_obj, contact_tag_obj);
            }
          });
          return trigger;
        });
        setmainTree_Top(b);
      }
      setSameAllCOPE(false);
      setshow1(false);
      setAutomation_addTag_module({ key: "remove_tag", value: false });
    }
  };

  const handle_addNote_module_edit = (value, module_name, backgroundColor) => {
    console.log(value);
    if (value) {
      let contact_tag_obj = {
        type: "add_note",
        title: `add_note_${module_name}`,
        id: Math.random() + new Date(),
        label: `Add Note ${value} in ${module_name}`,
        icon: "fa fa-sticky-note ",
        children: [],
        bgColor: backgroundColor,
        color: "#00000",
        ifelse: false,
        formdata: [{ tag_value: value }],
        actionDetails: {
          tittle: ` in_${module_name}`,
          type: "add_note",
          note_value: value,
          module_name: module_name,
        },
      };
      treeData.length === 0
        ? setTreeData([contact_tag_obj])
        : node_test_edit(contact_tag_obj);
      if (mainTree_Top.length) {
        let b = mainTree_Top.map((trigger) => {
          trigger.actionDetails.map((action_obj, index) => {
            if (action_obj.id === action_id) {
              updateProperties(action_obj, contact_tag_obj);
            }
          });
          return trigger;
        });
        setmainTree_Top(b);
      }
      setSameAllCOPE(false);
      setshow1(false);
      // setAutomation_addTag_module(false);
      dispatch({ key: "add_note", value: false });
    }
  };
  const handle_addEvent_module_edit = (value, module_name, backgroundColor) => {
    console.log(value);
    if (value) {
      let contact_tag_obj = {
        type: "add_event",
        title: `add_event_${module_name}`,
        id: Math.random() + new Date(),
        label: `Add Event "${value.title}" in ${module_name}`,
        icon: "fa fa-sticky-note ",
        children: [],
        bgColor: backgroundColor,
        color: "#00000",
        ifelse: false,
        formdata: [value],
        actionDetails: {
          tittle: ` in_${module_name}`,
          type: "add_event",
          module_name: module_name,
          ...value,
        },
      };
      treeData.length === 0
        ? setTreeData([contact_tag_obj])
        : node_test_edit(contact_tag_obj);
      if (mainTree_Top.length) {
        let b = mainTree_Top.map((trigger) => {
          trigger.actionDetails.map((action_obj, index) => {
            if (action_obj.id === action_id) {
              updateProperties(action_obj, contact_tag_obj);
            }
          });
          return trigger;
        });
        setmainTree_Top(b);
      }
      setSameAllCOPE(false);
      setshow1(false);
      dispatch({ key: "add_event", value: false });
    }
  };
  const handle_updateField_module_edit = (
    values,
    module_name,
    backgroundColor
  ) => {
    if (values.module_name === "contact") {
      if (
        values.field_module &&
        values.field_name &&
        values.updated_field_value
      ) {
        let contact_tag_obj = {
          type: "update_field",
          title: `update_field_${module_name}`,
          id: Math.random() + new Date(),
          label: `Update field ${values.field_name} in ${module_name}`,
          icon: "fa fa-sticky-note ",
          children: [],
          bgColor: backgroundColor,
          color: "#00000",
          ifelse: false,
          formdata: [
            {
              module_name: module_name,
              field_name: values.field_name,
              updated_field_value: values.updated_field_value,
            },
          ],
          actionDetails: {
            tittle: ` in_${module_name}`,
            type: "update_field",
            module_name: module_name,
            field_name: values.field_name,
            updated_field_value: values.updated_field_value,
          },
        };
        treeData.length === 0
          ? setTreeData([contact_tag_obj])
          : node_test_edit(contact_tag_obj);
        if (mainTree_Top.length) {
          let b = mainTree_Top.map((trigger) => {
            trigger.actionDetails.map((action_obj, index) => {
              if (action_obj.id === action_id) {
                updateProperties(action_obj, contact_tag_obj);
              }
            });
            return trigger;
          });
          setmainTree_Top(b);
        }
        setSameAllCOPE(false);
        setshow1(false);
        dispatch({ key: "update_field", value: false });
      } else {
        swal({
          title: "Fill all the required fields",
          icon: "error",
        });
      }
    } else {
      if (values.field_name && values.updated_field_value) {
        let contact_tag_obj = {
          type: "update_field",
          title: `update_field_${module_name}`,
          id: Math.random() + new Date(),
          label: `Update field ${values.field_name} in ${module_name}`,
          icon: "fa fa-sticky-note ",
          children: [],
          bgColor: backgroundColor,
          color: "#00000",
          ifelse: false,
          formdata: [
            {
              module_name: module_name,
              field_name: values.field_name,
              updated_field_value: values.updated_field_value,
            },
          ],
          actionDetails: {
            tittle: ` in_${module_name}`,
            type: "update_field",
            module_name: module_name,
            field_name: values.field_name,
            updated_field_value: values.updated_field_value,
          },
        };
        treeData.length === 0
          ? setTreeData([contact_tag_obj])
          : node_test_edit(contact_tag_obj);
        if (mainTree_Top.length) {
          let b = mainTree_Top.map((trigger) => {
            trigger.actionDetails.map((action_obj, index) => {
              if (action_obj.id === action_id) {
                updateProperties(action_obj, contact_tag_obj);
              }
            });
            return trigger;
          });
          setmainTree_Top(b);
        }
        setSameAllCOPE(false);
        setshow1(false);
        dispatch({ key: "update_field", value: false });
      } else {
        swal({
          title: "Fill all the required fields",
          icon: "error",
        });
      }
    }
  };
  const handle_add_other_automation_edit = (values, add_or_end) => {
    if (values.add_automation_select.length) {
      let s = values?.add_automation_select_list.reduce((acc, val) => {
        return acc.concat(` "${val.children}" ,`);
      }, "");

      let contact_tag_obj = {
        type: `${add_or_end}_automation`,
        title: `${add_or_end} automation `,
        id: Math.random() + new Date(),
        label: `${add_or_end} automation ${s} `,
        icon: "fa fa-sticky-note ",
        children: [],
        bgColor: "#535353",
        color: "#00000",
        ifelse: false,
        formdata: [
          {
            automation_select: values.add_automation_select,
          },
        ],
        actionDetails: {
          automation_select: values.add_automation_select,
        },
      };
      treeData.length === 0
        ? setTreeData([contact_tag_obj])
        : node_test_edit(contact_tag_obj);
      if (mainTree_Top.length) {
        let b = mainTree_Top.map((trigger) => {
          trigger.actionDetails.map((action_obj, index) => {
            if (action_obj.id === action_id) {
              updateProperties(action_obj, contact_tag_obj);
            }
          });
          return trigger;
        });
        setmainTree_Top(b);
      }
      handleSubmit_Action_Modals();

      setSameAllCOPE(false);
      setshow1(false);
      dispatch({ key: `${add_or_end}_automation`, value: false });
      // console.log(values.add_automation_select);
    } else {
      swal({
        title: "Select atleast one automation",
        icon: "error",
      });
    }
  };
  const handle_PerfomMath_edit = (values) => {
    if (
      values.field_math &&
      values.math_operation &&
      values.value_number &&
      values.module_math
    ) {
      let contact_tag_obj = {
        type: `math_operation_automation`,
        title: `MATH OPERATION ${values.module_math}  ${values.field_math} ${values.math_operation} ${values.value_number}`,
        id: Math.random() + new Date(),
        label: `MATH OPERATION ${values.module_math}  ${values.field_math} ${values.math_operation} ${values.value_number}`,
        icon: "fa fa-sticky-note ",
        children: [],
        bgColor: "#eb7a7a",
        color: "#00000",
        ifelse: false,
        formdata: [values],
        actionDetails: values,
      };
      treeData.length === 0
        ? setTreeData([contact_tag_obj])
        : node_test_edit(contact_tag_obj);
      if (mainTree_Top.length) {
        let b = mainTree_Top.map((trigger) => {
          trigger.actionDetails.map((action_obj, index) => {
            if (action_obj.id === action_id) {
              updateProperties(action_obj, contact_tag_obj);
            }
          });
          return trigger;
        });
        setmainTree_Top(b);
      }
      dispatch({ key: `math_operation_automation`, value: false });

      handleSubmit_Action_Modals();

      setSameAllCOPE(false);
      setshow1(false);
    } else {
      swal({
        title: "All Fields are Required",
        icon: "error",
      });
    }
  };
  const Handle_ActionWaitSubmit_edit = (v) => {
    if (v.radioOption1 && v.radioOption1 === "wait_1") {
      if (v.wait_for && v.wait_time_count && v.wait_time_days) {
        let wait_obj = {
          type: "wait_for",

          id: Math.random() + new Date(),
          label: `${v.wait_for} ${v.wait_time_count ? v.wait_time_count : ""} ${
            v.wait_time_days ? v.wait_time_days : ""
          }`,
          icon: "fas fa-clock",
          children: [],
          bgColor: "#69afff",
          color: "#00000",
          ifelse: false,
          formdata: [v],
          actionDetails: v,
        };
        treeData.length === 0
          ? setTreeData([wait_obj])
          : node_test_edit(wait_obj);
        if (mainTree_Top.length) {
          let b = mainTree_Top.map((trigger) => {
            trigger.actionDetails.map((action_obj, index) => {
              if (action_obj.id === action_id) {
                updateProperties(action_obj, wait_obj);
              }
            });
            return trigger;
          });
          setmainTree_Top(b);
        }
        dispatch({ key: `wait_for`, value: false });
        handleSubmit_Action_Modals();

        // console.log(treeData, "asdasds");
      } else {
        swal({
          title: "Fill all the required fields",
          icon: "error",
        });
      }
    } else if (v.radioOption1 && v.radioOption1 === "wait_2") {
      if (v.time_limit) {
        let wait_obj = {
          type: "wait_for",
          title: "wait_for",
          id: Math.random() + new Date(),
          label: v.time_limit,
          icon: "fas fa-clock",
          children: [],
          bgColor: "#69afff",
          color: "#00000",
          ifelse: false,
          formdata: [v],
          actionDetails: v,
        };
        treeData.length === 0
          ? setTreeData([wait_obj])
          : node_test_edit(wait_obj);
        if (mainTree_Top.length) {
          let b = mainTree_Top.map((trigger) => {
            trigger.actionDetails.map((action_obj, index) => {
              if (action_obj.id === action_id) {
                updateProperties(action_obj, wait_obj);
              }
            });
            return trigger;
          });
          setmainTree_Top(b);
        }
        dispatch({ key: `wait_for`, value: false });

        handleSubmit_Action_Modals();
      } else {
        swal({
          title: "Fill all the required fields",
          icon: "error",
        });
      }
    }
  };
  /////////////// EDIT ACTION END //////////////////
  const handle_remove_contact_tag = (
    value,
    setAutomation_addTag_module,
    module_name,
    backgroundColor
  ) => {
    console.log(value);
    if (value.tag_to_add_contact) {
      let contact_tag_obj = {
        type: "remove_tag",

        title: `remove_tag_${module_name}`,
        id: Math.random() + new Date(),
        label: `remove Tag ${value.tag_to_add_contact} in ${module_name}`,
        icon: "fas fa-tags",
        children: [],
        bgColor: backgroundColor,
        color: "#00000",
        ifelse: false,
        formdata: [{ tag_value: value.tag_to_add_contact }],
        actionDetails: {
          tittle: ` in_${module_name}`,
          type: "remove_tag",
          tag_value: value.tag_to_add_contact,
          module_name: module_name,
        },
      };
      treeData.length === 0
        ? setTreeData([contact_tag_obj])
        : node_test(contact_tag_obj);
      if (mainTree_Top.length) {
        let b = mainTree_Top.map((trigger) => {
          trigger.actionDetails = [...trigger.actionDetails, contact_tag_obj];
          return trigger;
        });
        setmainTree_Top(b);
      }
      setSameAllCOPE(false);
      setshow1(false);
      setAutomation_addTag_module(false);
    }
  };
  const handle_addNote_module = (value, module_name, backgroundColor) => {
    console.log(value);
    if (value) {
      let contact_tag_obj = {
        type: "add_note",
        title: `add_note_${module_name}`,
        id: Math.random() + new Date(),
        label: `Add Note ${value} in ${module_name}`,
        icon: "fa fa-sticky-note ",
        children: [],
        bgColor: backgroundColor,
        color: "#00000",
        ifelse: false,
        formdata: [{ tag_value: value }],
        actionDetails: {
          tittle: ` in_${module_name}`,
          type: "add_note",
          note_value: value,
          module_name: module_name,
        },
      };
      treeData.length === 0
        ? setTreeData([contact_tag_obj])
        : node_test(contact_tag_obj);
      if (mainTree_Top.length) {
        let b = mainTree_Top.map((trigger) => {
          trigger.actionDetails = [...trigger.actionDetails, contact_tag_obj];
          return trigger;
        });
        setmainTree_Top(b);
      }
      setSameAllCOPE(false);
      setshow1(false);
      // setAutomation_addTag_module(false);
    }
  };
  const handle_addEvent_module = (value, module_name, backgroundColor) => {
    console.log(value);
    if (value) {
      let contact_tag_obj = {
        type: "add_event",
        title: `add_event_${module_name}`,
        id: Math.random() + new Date(),
        label: `Add Event "${value.title}" in ${module_name}`,
        icon: "fa fa-sticky-note ",
        children: [],
        bgColor: backgroundColor,
        color: "#00000",
        ifelse: false,
        formdata: [value],
        actionDetails: {
          tittle: ` in_${module_name}`,
          type: "add_event",
          module_name: module_name,
          ...value,
        },
      };
      treeData.length === 0
        ? setTreeData([contact_tag_obj])
        : node_test(contact_tag_obj);
      if (mainTree_Top.length) {
        let b = mainTree_Top.map((trigger) => {
          trigger.actionDetails = [...trigger.actionDetails, contact_tag_obj];
          return trigger;
        });
        setmainTree_Top(b);
      }
      setSameAllCOPE(false);
      setshow1(false);
      // setAutomation_addTag_module(false);
    }
  };
  const handle_updateField_module = (values, module_name, backgroundColor) => {
    if (values.module_name === "contact") {
      if (
        values.field_module &&
        values.field_name &&
        values.updated_field_value
      ) {
        let contact_tag_obj = {
          type: "update_field",
          title: `update_field_${module_name}`,
          id: Math.random() + new Date(),
          label: `Update field ${values.field_name} in ${module_name}`,
          icon: "fa fa-sticky-note ",
          children: [],
          bgColor: backgroundColor,
          color: "#00000",
          ifelse: false,
          formdata: [
            {
              module_name: module_name,
              field_name: values.field_name,
              updated_field_value: values.updated_field_value,
            },
          ],
          actionDetails: {
            tittle: ` in_${module_name}`,
            type: "update_field",
            module_name: module_name,
            field_name: values.field_name,
            updated_field_value: values.updated_field_value,
          },
        };
        treeData.length === 0
          ? setTreeData([contact_tag_obj])
          : node_test(contact_tag_obj);
        if (mainTree_Top.length) {
          let b = mainTree_Top.map((trigger) => {
            trigger.actionDetails = [...trigger.actionDetails, contact_tag_obj];
            return trigger;
          });
          setmainTree_Top(b);
        }
        setSameAllCOPE(false);
        setshow1(false);
      } else {
        swal({
          title: "Fill all the required fields",
          icon: "error",
        });
      }
    } else {
      if (values.field_name && values.updated_field_value) {
        let contact_tag_obj = {
          type: "update_field",
          title: `update_field_${module_name}`,
          id: Math.random() + new Date(),
          label: `Update field ${values.field_name} in ${module_name}`,
          icon: "fa fa-sticky-note ",
          children: [],
          bgColor: backgroundColor,
          color: "#00000",
          ifelse: false,
          formdata: [
            {
              module_name: module_name,
              field_name: values.field_name,
              updated_field_value: values.updated_field_value,
            },
          ],
          actionDetails: {
            tittle: ` in_${module_name}`,
            type: "update_field",
            module_name: module_name,
            field_name: values.field_name,
            updated_field_value: values.updated_field_value,
          },
        };
        treeData.length === 0
          ? setTreeData([contact_tag_obj])
          : node_test(contact_tag_obj);
        if (mainTree_Top.length) {
          let b = mainTree_Top.map((trigger) => {
            trigger.actionDetails = [...trigger.actionDetails, contact_tag_obj];
            return trigger;
          });
          setmainTree_Top(b);
        }
        setSameAllCOPE(false);
        setshow1(false);
      } else {
        swal({
          title: "Fill all the required fields",
          icon: "error",
        });
      }
    }
  };
  const handle_add_other_automation = (values, add_or_end) => {
    if (values.add_automation_select.length) {
      let s = values?.add_automation_select_list.reduce((acc, val) => {
        return acc.concat(` "${val.children}" ,`);
      }, "");

      let contact_tag_obj = {
        type: `${add_or_end}_automation`,
        title: `${add_or_end} automation `,
        id: Math.random() + new Date(),
        label: `${add_or_end} automation ${s} `,
        icon: "fa fa-sticky-note ",
        children: [],
        bgColor: "#535353",
        color: "#00000",
        ifelse: false,
        formdata: [
          {
            automation_select: values.add_automation_select,
          },
        ],
        actionDetails: {
          automation_select: values.add_automation_select,
        },
      };
      treeData.length === 0
        ? setTreeData([contact_tag_obj])
        : node_test(contact_tag_obj);
      if (mainTree_Top.length) {
        let b = mainTree_Top.map((trigger) => {
          trigger.actionDetails = [...trigger.actionDetails, contact_tag_obj];
          return trigger;
        });
        setmainTree_Top(b);
      }
      handleSubmit_Action_Modals();

      setSameAllCOPE(false);
      setshow1(false);

      console.log(values.add_automation_select);
    } else {
      swal({
        title: "Select atleast one automation",
        icon: "error",
      });
    }
  };
  const handle_PerfomMath = (values) => {
    if (
      values.field_math &&
      values.math_operation &&
      values.value_number &&
      values.module_math
    ) {
      let contact_tag_obj = {
        type: `math_operation_automation`,
        title: `MATH OPERATION ${values.module_math}  ${values.field_math} ${values.math_operation} ${values.value_number}`,
        id: Math.random() + new Date(),
        label: `MATH OPERATION ${values.module_math}  ${values.field_math} ${values.math_operation} ${values.value_number}`,
        icon: "fa fa-sticky-note ",
        children: [],
        bgColor: "#eb7a7a",
        color: "#00000",
        ifelse: false,
        formdata: [values],
        actionDetails: values,
      };
      treeData.length === 0
        ? setTreeData([contact_tag_obj])
        : node_test(contact_tag_obj);
      if (mainTree_Top.length) {
        let b = mainTree_Top.map((trigger) => {
          trigger.actionDetails = [...trigger.actionDetails, contact_tag_obj];
          return trigger;
        });
        setmainTree_Top(b);
      }
      handleSubmit_Action_Modals();

      setSameAllCOPE(false);
      setshow1(false);
    } else {
      swal({
        title: "All Fields are Required",
        icon: "error",
      });
    }
  };

  // ................................./                ////////////////////////////////////////////////////////// for handle submit for triggers
  const HandleSubmitTagAdded = (values) => {
    let reqArray = [];
    let isEmptyField = false;

    for (let key in values) {
      if (values[key] === "" && key !== "segment_checkbox_tag_added") {
        reqArray.push(key);
        isEmptyField = true;
      }
    }
    if (values.segment_checkbox_tag_added) {
      segment_tags_add.length &&
        segment_tags_add.map((obj) => {
          Object.keys(obj).map((item) => {
            if (item !== "between") {
              if (!obj[item]) {
                reqArray.push("segement");
                isEmptyField = true;
              }
            }
          });
        });
    }
    let s = segment_tags_add.reduce((acc, val) => {
      return acc.concat(
        ` ${val.select} (In ${val.module_name}  ${val.field_name} ${val.condition_value} ${val.SearchInput})`
      );
    }, "");
    console.log(segment_tags_add, s);

    if (!isEmptyField) {
      mainTree_Top.forEach((val, index) => {
        if (val.id === trigger_id) {
          let copy_arr = [...mainTree_Top];
          copy_arr[index] = {
            edit_name: "TAG ADDED",
            tittle: "TAG ADDED",
            trigger_name: "TAG ADDED",

            sub_tittle: `In (${
              values.tags_added_module
            }) ${values?.tags_added?.replaceAll(
              "_",
              " "
            )} ${values?.tags_added_runs?.replaceAll("_", " ")}  ${
              values.segment_checkbox_tag_added ? s : ""
            }`,
            id: Math.random() + new Date(),
            formData: [values],
            actionDetails:
              copy_arr.length && copy_arr[0].actionDetails
                ? [...copy_arr[0].actionDetails]
                : [],
            segment: values.segment_checkbox_tag_added ? segment_tags_add : [],
          };
          setmainTree_Top(copy_arr);
        }
      });
      !mainTree_Top.some((val) => val.id === trigger_id) &&
        setmainTree_Top((prev_data) => [
          ...prev_data,
          {
            edit_name: "TAG ADDED",
            tittle: "TAG ADDED",
            trigger_name: "TAG ADDED",

            sub_tittle: `In (${
              values.tags_added_module
            }) ${values?.tags_added?.replaceAll(
              "_",
              " "
            )} ${values?.tags_added_runs?.replaceAll("_", " ")}  ${
              values.segment_checkbox_tag_added ? s : ""
            }`,
            id: Math.random() + new Date(),
            formData: [values],
            actionDetails:
              prev_data.length && prev_data[0].actionDetails
                ? [...prev_data[0].actionDetails]
                : [],
            segment: values.segment_checkbox_tag_added ? segment_tags_add : [],
          },
        ]);
      console.log(segment_tags_add);
      setshow2(false);
    } else {
      swal({
        title: "Fill all the required fields",
        icon: "error",
      });
    }
  };

  const HandleSubmitTagRemoved = (values) => {
    let reqArray = [];
    let isEmptyField = false;

    for (let key in values) {
      if (values[key] === "" && key !== "segment_checkbox_tag_removed") {
        reqArray.push(key);
        isEmptyField = true;
      }
    }
    if (values.segment_checkbox_tag_removed) {
      segment_tags_remove.length &&
        segment_tags_remove.map((obj) => {
          Object.keys(obj).map((item) => {
            if (item !== "between") {
              if (!obj[item]) {
                reqArray.push("segement");
                isEmptyField = true;
              }
            }
          });
        });
    }
    let s = segment_tags_remove.reduce((acc, val) => {
      return acc.concat(
        ` ${val.select} (In ${val.module_name}  ${val.field_name} ${val.condition_value} ${val.SearchInput})`
      );
    }, "");
    console.log(segment_tags_remove, s);

    if (!isEmptyField) {
      mainTree_Top.forEach((val, index) => {
        if (val.id === trigger_id) {
          let copy_arr = [...mainTree_Top];
          copy_arr[index] = {
            edit_name: "TAG REMOVED",
            tittle: "TAG Removed",
            trigger_name: "TAG REMOVED",

            sub_tittle: `In (${
              values.tags_removed_module
            }) ${values?.tags_removed?.replaceAll(
              "_",
              " "
            )} ${values?.tags_removed_runs?.replaceAll("_", " ")}  ${
              values.segment_checkbox_tag_removed ? s : ""
            }`,
            id: Math.random() + new Date(),
            formData: [values],
            actionDetails:
              copy_arr.length && copy_arr[0].actionDetails
                ? [...copy_arr[0].actionDetails]
                : [],
            segment: values.segment_checkbox_tag_removed
              ? segment_tags_remove
              : [],
          };
          setmainTree_Top(copy_arr);
        }
      });

      !mainTree_Top.some((val) => val.id === trigger_id) &&
        setmainTree_Top((prev_data) => [
          ...prev_data,
          {
            edit_name: "TAG REMOVED",
            tittle: "TAG Removed",
            trigger_name: "TAG REMOVED",

            sub_tittle: `In (${
              values.tags_removed_module
            }) ${values?.tags_removed?.replaceAll(
              "_",
              " "
            )} ${values?.tags_removed_runs?.replaceAll("_", " ")}  ${
              values.segment_checkbox_tag_removed ? s : ""
            }`,
            id: Math.random() + new Date(),
            formData: [values],
            actionDetails:
              prev_data.length && prev_data[0].actionDetails
                ? [...prev_data[0].actionDetails]
                : [],
            segment: values.segment_checkbox_tag_removed
              ? segment_tags_remove
              : [],
          },
        ]);
      setshow2(false);
    } else {
      swal({
        title: "Fill all the required fields",
        icon: "error",
      });
    }
  };
  const handle_submit_date_based_trigger = (values) => {
    let reqArray = [];
    let isEmptyField = false;

    for (let key in values) {
      if (values[key] === "") {
        if (key !== "segment_date_based") {
          reqArray.push(key);
          isEmptyField = true;
        }
      }
    }

    if (values.segment_date_based) {
      segment_date_based.length &&
        segment_date_based.map((obj) => {
          Object.keys(obj).map((item) => {
            if (item !== "between") {
              if (!obj[item]) {
                reqArray.push("segement");
                isEmptyField = true;
              }
            }
          });
        });
    }
    let s = segment_date_based.reduce((acc, val) => {
      return acc.concat(
        ` ${val.select} (In ${val.module_name}  ${val.field_name} ${val.condition_value} ${val.SearchInput})`
      );
    }, "");

    if (!isEmptyField) {
      mainTree_Top.forEach((val, index) => {
        if (val.id === trigger_id) {
          let copy_arr = [...mainTree_Top];
          copy_arr[index] = {
            edit_name: "DATE BASED",
            tittle: "DATE BASED",
            trigger_name: "DATE BASED",
            sub_tittle: `${values?.module_date_based} starts ${
              values?.start_num_date_based
            } ${values?.start_days_date_based} 
            ${values?.start_on_bef_aft_date_based} the ${
              values?.start_on_the_date_date_based
            } check ${values?.start_run_day_date_based}
           around ${values?.start_hour_date_based}: 00 ${
              values?.timezone_date_based_value
            } runs when ${values?.runs_date_based}
            ${values.segment_date_based ? ` ${s}` : ""}`,
            id: Math.random() + new Date(),
            formData: [values],
            actionDetails:
              copy_arr.length && copy_arr[0].actionDetails
                ? [...copy_arr[0].actionDetails]
                : [],
            segment: values.segment_date_based ? segment_date_based : [],
          };
          setmainTree_Top(copy_arr);
        }
      });

      !mainTree_Top.some((val) => val.id === trigger_id) &&
        setmainTree_Top((prev_data) => [
          ...prev_data,
          {
            edit_name: "DATE BASED",
            tittle: "DATE BASED",
            trigger_name: "DATE BASED",
            sub_tittle: `${values?.module_date_based} starts ${
              values?.start_num_date_based
            } ${values?.start_days_date_based} 
            ${values?.start_on_bef_aft_date_based} the ${
              values?.start_on_the_date_date_based
            } check ${values?.start_run_day_date_based}
           around ${values?.start_hour_date_based} : 00 ${
              values?.timezone_date_based_value
            } runs when ${values?.runs_date_based}
            ${values.segment_date_based ? ` ${s}` : ""}`,
            id: Math.random() + new Date(),
            formData: [values],
            actionDetails:
              prev_data.length && prev_data[0].actionDetails
                ? [...prev_data[0].actionDetails]
                : [],
            segment: values.segment_date_based ? segment_date_based : [],
          },
        ]);
      closeModal();
    } else {
      swal({
        title: "Fill all the required fields",
        icon: "error",
      });
    }
  };

  const HandleSubmitBooking = (values, actions) => {
    console.log(values);
    let reqArray = [];
    let isEmptyField = false;

    for (let key in values) {
      if (values[key] === "") {
        reqArray.push(key);
        isEmptyField = true;
      }
    }

    if (!isEmptyField) {
      mainTree_Top.forEach((val, index) => {
        if (val.id === trigger_id) {
          let copy_arr = [...mainTree_Top];
          copy_arr[index] = {
            edit_name: "BOOKING CALENDAR",
            tittle: "Booking Calender",
            trigger_name: "BOOKING CALENDAR",

            sub_tittle: `${values?.booking_calendar_appoinment_name?.replaceAll(
              "_",
              " "
            )} ${values?.booking_calendar_run?.replaceAll("_", " ")}`,
            id: Math.random() + new Date(),
            formData: [values],
            actionDetails:
              copy_arr.length && copy_arr[0].actionDetails
                ? [...copy_arr[0].actionDetails]
                : [],
          };
          setmainTree_Top(copy_arr);
        }
      });
      !mainTree_Top.some((val) => val.id === trigger_id) &&
        setmainTree_Top((prev_data) => [
          ...prev_data,
          {
            edit_name: "BOOKING CALENDAR",
            tittle: "Booking Calender",
            trigger_name: "BOOKING CALENDAR",

            sub_tittle: `${values?.booking_calendar_appoinment_name?.replaceAll(
              "_",
              " "
            )} ${values?.booking_calendar_run?.replaceAll("_", " ")}`,
            id: Math.random() + new Date(),
            formData: [values],
            actionDetails:
              prev_data.length && prev_data[0].actionDetails
                ? [...prev_data[0].actionDetails]
                : [],
          },
        ]);

      closeModal();
    } else {
      swal({
        title: "Fill all the required fields",
        icon: "error",
      });
    }
  };
  const HandleSubmitWebPage = (values) => {
    let reqArray = [];
    let isEmptyField = false;

    for (let key in values) {
      if (values[key] === "") {
        if (key !== "web_page_segment") {
          reqArray.push(key);
          isEmptyField = true;
        }
      }
    }

    if (values.web_page_segment) {
      segment_webPage_visited.length &&
        segment_webPage_visited.map((obj) => {
          Object.keys(obj).map((item) => {
            if (item !== "between") {
              if (!obj[item]) {
                reqArray.push("segement");
                isEmptyField = true;
              }
            }
          });
        });
    }
    let s = segment_webPage_visited.reduce((acc, val) => {
      return acc.concat(
        ` ${val.select} (In ${val.module_name}  ${val.field_name} ${val.condition_value} ${val.SearchInput})`
      );
    }, "");

    if (!isEmptyField) {
      mainTree_Top.forEach((val, index) => {
        if (val.id === trigger_id) {
          let copy_arr = [...mainTree_Top];
          copy_arr[index] = {
            edit_name: "WEB PAGE VISITED",
            tittle: "WEB PAGE VISITED",
            trigger_name: "WEB PAGE VISITED",
            sub_tittle: `${values?.web_page_URL_name?.replaceAll(
              "_",
              " "
            )}/${values?.web_page_URL_sub?.replaceAll(
              "_",
              " "
            )}  (${values?.runs?.replaceAll("_", " ")}) ${
              values.web_page_segment ? s : ""
            }`,
            id: Math.random() + new Date(),
            formData: [values],
            actionDetails:
              copy_arr.length && copy_arr[0].actionDetails
                ? [...copy_arr[0].actionDetails]
                : [],
            segment: values.web_page_segment ? segment_webPage_visited : [],
          };
          setmainTree_Top(copy_arr);
        }
      });

      !mainTree_Top.some((val) => val.id === trigger_id) &&
        setmainTree_Top((prev_data) => [
          ...prev_data,
          {
            edit_name: "WEB PAGE VISITED",
            tittle: "WEB PAGE VISITED",
            trigger_name: "WEB PAGE VISITED",
            sub_tittle: `${values?.web_page_URL_name?.replaceAll(
              "_",
              " "
            )}/${values?.web_page_URL_sub?.replaceAll(
              "_",
              " "
            )}  (${values?.runs?.replaceAll("_", " ")})  ${
              values.web_page_segment ? s : ""
            }`,
            id: Math.random() + new Date(),
            formData: [values],
            actionDetails:
              prev_data.length && prev_data[0].actionDetails
                ? [...prev_data[0].actionDetails]
                : [],
            segment: values.web_page_segment ? segment_webPage_visited : [],
          },
        ]);
      closeModal();
    } else {
      swal({
        title: "Fill all the required fields",
        icon: "error",
      });
    }
  };
  const handle_add_webHook_params = () => {
    setwebHook_params([
      ...webHook_params,
      {
        id: webHook_params.length + 1,
        key: "",
        value: "",
      },
    ]);
  };
  const handle_params_valueChange = (obj, index, value) => {
    let updateObj = { ...webHook_params[index], value };
    let copyArray = [...webHook_params];
    copyArray[index] = updateObj;
    setwebHook_params(copyArray);
  };
  const handle_params_keyChange = (obj, index, value) => {
    let updateObj = { ...webHook_params[index], key: value };
    let copyArray = [...webHook_params];
    copyArray[index] = updateObj;
    setwebHook_params(copyArray);
  };
  const handleDelete = (element, index) => {
    setwebHook_params(webHook_params.filter((item) => item.id != element.id));
  };
  const handle_add_webHook = (values) => {
    if (isUrl(values.request_url)) {
      let params_obj = webHook_params.reduce((acc, val) => {
        if (val.key) {
          acc[val.key] = val.value;
        }
        return acc;
      }, {});
      let webHook_obj = {
        type: "webhook",
        title: `webhook`,
        id: Math.random() + new Date(),
        label: `Post info to ${values.request_url}`,
        icon: "fas fa-tags",
        children: [],
        bgColor: "#8C7EB3",
        color: "#00000",
        ifelse: false,
        formdata: [{ ...values, ...params_obj }],
        actionDetails: {
          ...values,
          ...params_obj,
          type: "webhook",
          title: `webhook`,
        },
      };
      treeData.length === 0
        ? setTreeData([webHook_obj])
        : node_test(webHook_obj);
      if (mainTree_Top.length) {
        let b = mainTree_Top.map((trigger) => {
          trigger.actionDetails = [...trigger.actionDetails, webHook_obj];
          return trigger;
        });
        setmainTree_Top(b);
      }
      setAtionWebhook(false);
      setActionsTriggerModal(false);
      setshow1(false);
    } else {
      swal({
        title: "URL is required",
        dangerMode: true,
        icon: "error",
      });
    }
  };

  const HandleDelete_Trigger = (trigger) => {
    swal({
      text: "Are you sure to delete the trigger?",
      icon: "warning",
      buttons: ["Close", true],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const updatedMainTree = mainTree_Top.filter(
          (fieldName) => fieldName.id !== trigger.id
        );
        // console.log(mainTree_Top);
        setmainTree_Top(updatedMainTree);
      }
    });
  };
  const HandleEdit_Trigger = (e, trigger) => {
    settrigger_id(trigger.id);
    console.log(trigger, "sdaasd");
    if (trigger.edit_name) {
      if (trigger.edit_name == "FIELD CHANGE") {
        setInitialFieldChangeTriger({ ...trigger.formData[0] });

        if (trigger?.formData[0].segment_checkbox_field) {
          let copy_arr = [...trigger?.segment];
          setSegment_field_change(copy_arr);
        }
      } else if (trigger.edit_name === "TAG ADDED") {
        setInitialAddedTagTriger({ ...trigger.formData[0] });
        if (trigger?.formData[0].segment_checkbox_tag_added) {
          let copy_arr = [...trigger?.segment];
          setSegment_tags_add(copy_arr);
        }
      } else if (trigger.edit_name === "TAG REMOVED") {
        setInitialRemoveTagTriger({ ...trigger.formData[0] });
        if (trigger?.formData[0].segment_checkbox_tag_removed) {
          let copy_arr = [...trigger?.segment];
          setSegment_tags_remove(copy_arr);
        }
      } else if (trigger.edit_name === "BOOKING CALENDAR") {
        setInitialBookingCalenderTriger({ ...trigger.formData[0] });
      } else if (trigger.edit_name === "WEB PAGE VISITED") {
        setInitialWebPageVisitedTriger({ ...trigger.formData[0] });
        // console.log(trigger);

        if (trigger?.formData[0].web_page_segment) {
          let copy_arr = [...trigger?.segment];
          setSegment_date_based(copy_arr);
        }
      } else if (trigger.edit_name === "DATE BASED") {
        setInitialDateBasedTriger({ ...trigger.formData[0] });
        // console.log(trigger);

        if (trigger?.formData[0].web_page_segment) {
          let copy_arr = [...trigger?.segment];
          setSegment_date_based(copy_arr);
        }
      }

      showmodal(trigger.edit_name);
    }
  };
  const HandleDelete_Action = (data) => {};
  const HandleEdit_Action = (data, index) => {
    console.log(data);
    setaction_id(data.id);
    setedit_action_data(data);
    dispatch({ key: data.type, value: true });
    if (data?.type === "update_field") {
      handleFieldChangeValue_update_field_edit(
        data?.actionDetails?.module_name
      );
    } else if (data?.type === "add_note") {
      setContent(data?.actionDetails?.note_value);
    } else if (data?.type === "if_else") {
      setIf_ELSE_Array(data?.actionDetails);
    } else if (data?.type === "wait_for") {
      // if(data?.actionDetails?.radioOption1==="wait_1"  ) {
      //   setWaitRadio1(true); setWaitRadio2(false)
      // }else{
      //   setWaitRadio2(true);setWaitRadio1(false)}
    }
  };
  return (
    <>
      <div className="Autmation_section row">
        <div className="col-md-12 mt-3 automationbtn_header">
          <button
            onClick={() => {
              if (mainTree_Top.length && treeData.length) {
                setshowCreateName(true);
              } else {
                swal({
                  title: "Please select atleast one trigger and action",
                  icon: "warning",
                });
              }
            }}
          >
            Save This Automation
          </button>
        </div>
        <Tree
          lineWidth={"4px"}
          lineColor={"#000"}
          lineBorderRadius={"10px"}
          label={
            <div className="d-flex flex-column gap-1">
              <div className="d-flex gap-1 tittle_list  justify-content-center w-100 flex-wrap">
                {mainTree_Top.length > 0 &&
                  mainTree_Top.map((fieldName, index) => (
                    <div
                      className={`treedataheader border m-0`}
                      key={index}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                      // onClick={() => HandleDelete_Trigger(fieldName)}>
                    >
                      {" "}
                      {HoveredIndex === index && (
                        <div className="hover-delete">
                          <div className="delete-icon d-flex gap-2 py-2">
                            {!not_edit_trigger.includes(
                              fieldName?.trigger_name
                            ) && (
                              <FaEdit
                                onClick={(e) =>
                                  HandleEdit_Trigger(e, fieldName)
                                }
                              />
                            )}
                            <FaTrash
                              onClick={() => HandleDelete_Trigger(fieldName)}
                            />
                          </div>
                        </div>
                      )}
                      <b> {fieldName.tittle}</b>
                      {fieldName.sub_tittle ? `"${fieldName.sub_tittle}"` : ""}
                    </div>
                  ))}
              </div>
              <div className="mx-auto">
                {" "}
                <div
                  className={`treedataheader`}
                  onClick={() => {
                    setshow(true);
                    settrigger_id(null);
                  }}
                >
                  {" "}
                  Add a Start Trigger
                </div>
              </div>
            </div>
          }
        >
          {treeData && treeData.length > 0 ? (
            <Tree
              lineWidth={"2px"}
              lineColor={"#000"}
              lineBorderRadius={"10px"}
            >
              {generateTreeNodes(treeData)}
            </Tree>
          ) : (
            <TreeNode
              label={
                <div
                  className="treedata bg-primary"
                  onClick={() => {
                    if (mainTree_Top.length) {
                      setshow1(true);
                    } else {
                      swal({
                        title: "Atleast add one trigger first",
                        icon: "error",
                      });
                    }
                  }}
                >
                  <button>
                    <i className="fas fa-plus"></i>
                  </button>{" "}
                </div>
              }
            />
          )}
        </Tree>
      </div>
      {/* Select a trigger modal Start*/}

      <Modal
        show={showCreateName}
        onHide={() => setshowCreateName(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Formik
          initialValues={{ create_name: "" }}
          onSubmit={(values) => {
            if (values.create_name && values.create_name.trim()) {
              let data_to_send = {
                actions: { ...treeData[0] },
                triggers: mainTree_Top,
              };
              const formdata = new FormData();
              formdata.append("name", values.create_name);
              formdata.append("submit", "createAutomation");
              formdata.append("data", JSON.stringify(data_to_send));
              formdata.append("created_at", new Date().toLocaleDateString());
              apimethodCreateAutomation(
                "postCreateAutomationAction///////",
                formdata
              );
              swal({
                title: "Automation created successfully",
                icon: "success",
              });
              setshowCreateName(false);
            } else {
              swal({
                title: "Automation name is required",
                icon: "error",
              });
            }
          }}
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5 className="modal-title">Automation Name</h5>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="col-12 row">
                <FormControl
                  className="form-control my-1"
                  required={true}
                  label={Translation(translations, "Automation Name")}
                  name="create_name"
                  control="input3"
                  placeholder={Translation(translations, "Name")}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="col-md-12 mt-3 automationbtn_header">
                <button type="submit">Save</button>
              </div>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
      <Modal
        show={show}
        onHide={() => setshow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5 className="modal-title">Select a Trigger</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 triggerRightSide">
              <div className="blockWrapper_automat">
                <div className="item_automat" onClick={showmodal}>
                  <div className="iconWrapper_automat">
                    <div className="icon_automat">
                      <i className="fas fa-tags"></i>
                    </div>
                  </div>
                  <div className="heading">WEB HOOK LISTNER</div>
                </div>
                <div className="item_automat" onClick={showmodal}>
                  <div className="iconWrapper_automat">
                    <div className="icon_automat">
                      <i className="fas fa-retweet "></i>
                    </div>
                  </div>
                  <div className="heading">FIELD CHANGE</div>
                </div>
                <div
                  className="item_automat"
                  onClick={Add_new_conatact_trigger}
                >
                  <div className="iconWrapper_automat">
                    <div className="icon_automat">
                      <i className="fas fa-user-circle "></i>
                    </div>
                  </div>
                  <div className="heading">NEW CONTACT</div>
                </div>
                <div
                  className="item_automat"
                  onClick={Add_new_opportunity_trigger}
                >
                  <div className="iconWrapper_automat">
                    <div className="icon_automat">
                      <i className="fas fa-capsules "></i>
                    </div>
                  </div>
                  <div className="heading">NEW OPPORTUNITY</div>
                </div>
                <div className="item_automat" onClick={Add_new_project_trigger}>
                  <div className="iconWrapper_automat">
                    <div className="icon_automat">
                      <i className="fas fa-transgender "></i>
                    </div>
                  </div>
                  <div className="heading">NEW PROJECT</div>
                </div>
                <div className="item_automat" onClick={Add_new_event_trigger}>
                  <div className="iconWrapper_automat">
                    <div className="icon_automat">
                      <i className="fas fa-search-dollar "></i>
                    </div>
                  </div>
                  <div className="heading"> NEW EVENT</div>
                </div>
                <div className="item_automat" onClick={showmodal}>
                  <div className="iconWrapper_automat">
                    <div className="icon_automat">
                      <i className="fas fa-search-dollar "></i>
                    </div>
                  </div>
                  <div className="heading">TAG ADDED</div>
                </div>
                <div className="item_automat" onClick={showmodal}>
                  <div className="iconWrapper_automat">
                    <div className="icon_automat">
                      <i className="fas fa-user-shield "></i>
                    </div>
                  </div>
                  <div className="heading">TAG REMOVED</div>
                </div>
                <div className="item_automat" onClick={showmodal}>
                  <div className="iconWrapper_automat">
                    <div className="icon_automat">
                      <i className="fas fa-minus-square "></i>
                    </div>
                  </div>
                  <div className="heading">BOOKING CALENDAR</div>
                </div>
                {/* <div className="item_automat">
                  <div className="iconWrapper_automat">
                    <div className="icon_automat">
                      <i className="fas fa-minus-square "></i>
                    </div>
                  </div>
                  <div className="heading">EMAIL OPENED</div>
                </div>
                <div className="item_automat">
                  <div className="iconWrapper_automat">
                    <div className="icon_automat">
                      <i className="fas fa-graduation-cap "></i>
                    </div>
                  </div>
                  <div className="heading">CLICK IN AN EMAIL LINK</div>
                </div>
                <div className="item_automat">
                  <div className="iconWrapper_automat">
                    <div className="icon_automat">
                      <i className="fas fa-shopping-bag "></i>
                    </div>
                  </div>
                  <div className="heading">FORWARD AN EMAIL</div>
                </div>
                <div className="item_automat">
                  <div className="iconWrapper_automat">
                    <div className="icon_automat">
                      <i className="fas fa-times "></i>
                    </div>
                  </div>
                  <div className="heading">REPLIES AN EMAIL</div>
                </div> */}
                <div className="item_automat" onClick={showmodal}>
                  <div className="iconWrapper_automat">
                    <div className="icon_automat">
                      <i className="fas fa-percent "></i>
                    </div>
                  </div>
                  <div className="heading">WEB PAGE VISITED</div>
                </div>
                <div className="item_automat">
                  <div className="iconWrapper_automat">
                    <div className="icon_automat">
                      <i className="fas fa-fill-drip "></i>
                    </div>
                  </div>
                  <div className="heading">CLICK IN A SUPERLINK</div>
                </div>
                {/* <div className="item_automat">
                  <div className="iconWrapper_automat">
                    <div className="icon_automat">
                      <i className="fas fa-star-half-alt "></i>
                    </div>
                  </div>
                  <div className="heading">EVENT TRACKING</div>
                </div> */}
                <div className="item_automat" onClick={showmodal}>
                  <div className="iconWrapper_automat">
                    <div className="icon_automat">
                      <i className="fas fa-star-half-alt "></i>
                    </div>
                  </div>
                  <div className="heading">DATE BASED</div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-md-12 mt-3 automationbtn_header">
            <button>Start Without a Trigger</button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Select a trigger modal END*/}
      {/* ADD NEW ACTION MODAL start */}
      <Modal
        show={show1}
        onHide={() => setshow1(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5 className="modal-title">Add a New Action</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={3}>
                <Nav
                  variant="pills"
                  className="flex-column addActionsMenu_automat"
                >
                  <Nav.Item className="automation_navitems">
                    <Nav.Link eventKey="first">Sending Options </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="automation_navitems">
                    <Nav.Link eventKey="second">
                      Conditions and Workflow
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="automation_navitems">
                    <Nav.Link eventKey="three">Contacts</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="automation_navitems">
                    <Nav.Link eventKey="four">OPPORTUNITIES</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="automation_navitems">
                    <Nav.Link eventKey="five">PROJECTS</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="automation_navitems">
                    <Nav.Link eventKey="six">EVENTS</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="first" className="pane_inner">
                    <div className="addActionsMenuContent_automat">
                      {/* <div className='addActionsMenuContent_automat_each' style={{ background: "#65758A" }}>
                                                <div className='ActionsMenuContent_icons'>
                                                    <i className="fas fa-envelope"></i>
                                                </div>
                                                <div>
                                                    <p>Send an email</p>
                                                    <p>Send an email campaign to a contact.</p>
                                                </div>
                                            </div>
                                            <div className='addActionsMenuContent_automat_each' style={{ background: "#71C5ED" }}>
                                                <div className='ActionsMenuContent_icons'>
                                                    <i className="fas fa-bell"></i>
                                                </div>
                                                <div>
                                                    <p>Send a notification email</p>
                                                    <p>Send a notification email to up to 5 email addresses at once that the contact has reached this point in an automation.</p>
                                                </div>
                                            </div>
                                            <div className='addActionsMenuContent_automat_each' style={{ background: "#EB7A7A" }}>
                                                <div className='ActionsMenuContent_icons'>
                                                    <i className="fas fa-mobile-alt"></i>
                                                </div>
                                                <div>
                                                    <p>Send an SMS</p>
                                                    <p>Send an SMS to the contact or admin user.</p>
                                                </div>
                                            </div>
                                            <div className='addActionsMenuContent_automat_each' style={{ background: "#EB7A7A" }}>
                                                <div className='ActionsMenuContent_icons'>
                                                    <i className="fas fa-mobile-alt"></i>
                                                </div>
                                                <div>
                                                    <p>Notification SMS</p>
                                                    <p>Send notification SMS to the contact or admin user.</p>
                                                </div>
                                            </div> */}
                      future development
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second" className="pane_inner">
                    <div className="addActionsMenuContent_automat">
                      <div
                        className="addActionsMenuContent_automat_each"
                        onClick={HandleActionName}
                        style={{ background: "#fff", color: "#000000" }}
                      >
                        <div className="ActionsMenuContent_icons">
                          <i className="fas fa-clock"></i>
                        </div>
                        <div>
                          <p>Wait</p>
                          <p>
                            Wait for a certain period of time, or until
                            conditions are matched.
                          </p>
                        </div>
                      </div>
                      <div
                        className="addActionsMenuContent_automat_each"
                        onClick={HandleActionName}
                        style={{ background: "#535353" }}
                      >
                        <div className="ActionsMenuContent_icons">
                          <i className="fas fa-question-circle"></i>
                        </div>
                        <div>
                          <p>If/Else</p>
                          <p>
                            Continue the automation in a different way depending
                            on whether the conditions are matched.
                          </p>
                        </div>
                      </div>
                      <div
                        className="addActionsMenuContent_automat_each"
                        onClick={HandleActionName}
                        style={{ background: "#535353" }}
                      >
                        <div className="ActionsMenuContent_icons">
                          <i className="fas fa-question-circle"></i>
                        </div>
                        <div>
                          <p>Start an automation</p>
                          <p>choose an automation to start as an action</p>
                        </div>
                      </div>
                      <div
                        className="addActionsMenuContent_automat_each"
                        onClick={handleEnd_automation_submit}
                        style={{ background: "#535353" }}
                      >
                        <div className="ActionsMenuContent_icons">
                          <i className="fas fa-question-circle"></i>
                        </div>
                        <div>
                          <p>End this automation</p>
                          <p>This is where this automation will end</p>
                        </div>
                      </div>
                      <div
                        className="addActionsMenuContent_automat_each"
                        onClick={HandleActionName}
                        style={{ background: "#535353" }}
                      >
                        <div className="ActionsMenuContent_icons">
                          <i className="fas fa-question-circle"></i>
                        </div>
                        <div>
                          <p>End another automation</p>
                          <p>
                            Have this contact end another automation if they are
                            currently in it.
                          </p>
                        </div>
                      </div>
                      <div
                        className="addActionsMenuContent_automat_each"
                        onClick={HandleActionName}
                        style={{ background: "#8C7EB3" }}
                      >
                        <div className="ActionsMenuContent_icons">
                          <i className="fas fa-crosshairs"></i>
                        </div>
                        <div>
                          <p>Webhook</p>
                          <p>Post a contact data to a URL of your choice.</p>
                        </div>
                      </div>
                      <div
                        className="addActionsMenuContent_automat_each"
                        onClick={HandleActionName}
                        style={{ background: "#EB7A7A" }}
                      >
                        <div className="ActionsMenuContent_icons">
                          <i className="fas fa-calculator"></i>
                        </div>
                        <div>
                          <p>Perform math</p>
                          <p>
                            Perform math on a contact or deal numeric or date
                            custom field.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="three" className="pane_inner">
                    <div className="addActionsMenuContent_automat">
                      <Automation_addTag_module
                        setshow1={setshow1}
                        module_name={"contact"}
                        handle_submit={handle_add_contact_tag}
                        backgroundColor={"#4A7CB7"}
                      >
                        <div
                          className="addActionsMenuContent_automat_each"
                          // onClick={SameModel}
                          style={{ background: "#4A7CB7" }}
                        >
                          <div className="ActionsMenuContent_icons">
                            <i className="fas fa-user"></i>
                          </div>
                          <div>
                            <p>Add Tag</p>
                          </div>
                        </div>
                      </Automation_addTag_module>
                      <Automation_removeTag_module
                        setshow1={setshow1}
                        module_name={"contact"}
                        handle_submit={handle_remove_contact_tag}
                        backgroundColor={"#4A7CB7"}
                      >
                        <div
                          className="addActionsMenuContent_automat_each"
                          // onClick={SameModel}
                          style={{ background: "#4A7CB7" }}
                        >
                          <div className="ActionsMenuContent_icons">
                            <i className="fas fa-user"></i>
                          </div>
                          <div>
                            <p>Remove Tag</p>
                          </div>
                        </div>
                      </Automation_removeTag_module>

                      <Automation_addNote_module
                        setshow1={setshow1}
                        module_name={"contact"}
                        handle_submit={handle_addNote_module}
                        backgroundColor={"#4A7CB7"}
                      >
                        <div
                          className="addActionsMenuContent_automat_each"
                          // onClick={SameModel}
                          style={{ background: "#4A7CB7" }}
                        >
                          <div className="ActionsMenuContent_icons">
                            <i className="fas fa-user"></i>
                          </div>
                          <div>
                            <p>Add Note</p>
                          </div>
                        </div>
                      </Automation_addNote_module>
                      <Automation_addEvent_module
                        setshow1={setshow1}
                        module_name={"contact"}
                        handle_submit={handle_addEvent_module}
                        backgroundColor={"#4A7CB7"}
                      >
                        <div
                          className="addActionsMenuContent_automat_each"
                          // onClick={SameModel}
                          style={{ background: "#4A7CB7" }}
                        >
                          <div className="ActionsMenuContent_icons">
                            <i className="fas fa-user"></i>
                          </div>
                          <div>
                            <p>Add Event</p>
                          </div>
                        </div>
                      </Automation_addEvent_module>
                      <Automation_updateField_module
                        setshow1={setshow1}
                        module_name={"contact"}
                        handle_submit={handle_updateField_module}
                        backgroundColor={"#4A7CB7"}
                      >
                        <div
                          className="addActionsMenuContent_automat_each"
                          // onClick={SameModel}
                          style={{ background: "#4A7CB7" }}
                        >
                          <div className="ActionsMenuContent_icons">
                            <i className="fas fa-user"></i>
                          </div>
                          <div>
                            <p>Update a field</p>
                          </div>
                        </div>
                      </Automation_updateField_module>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="four" className="pane_inner">
                    <div className="addActionsMenuContent_automat">
                      <Automation_addTag_module
                        setshow1={setshow1}
                        module_name={"oppotunities"}
                        handle_submit={handle_add_contact_tag}
                        backgroundColor={"#60CB98"}
                      >
                        <div
                          className="addActionsMenuContent_automat_each"
                          // onClick={SameModel}
                          style={{ background: "#60CB98" }}
                        >
                          <div className="ActionsMenuContent_icons">
                            <i className="fas fa-user"></i>
                          </div>
                          <div>
                            <p>Add Tag</p>
                          </div>
                        </div>
                      </Automation_addTag_module>
                      <Automation_removeTag_module
                        setshow1={setshow1}
                        module_name={"opportunities"}
                        handle_submit={handle_remove_contact_tag}
                        backgroundColor={"#60CB98"}
                      >
                        <div
                          className="addActionsMenuContent_automat_each"
                          // onClick={SameModel}
                          style={{ background: "#60CB98" }}
                        >
                          <div className="ActionsMenuContent_icons">
                            <i className="fas fa-user"></i>
                          </div>
                          <div>
                            <p>Remove Tag</p>
                          </div>
                        </div>
                      </Automation_removeTag_module>
                      <Automation_addNote_module
                        setshow1={setshow1}
                        module_name={"opportunities"}
                        handle_submit={handle_addNote_module}
                        backgroundColor={"#60CB98"}
                      >
                        <div
                          className="addActionsMenuContent_automat_each"
                          // onClick={SameModel}
                          style={{ background: "#60CB98" }}
                        >
                          <div className="ActionsMenuContent_icons">
                            <i className="fas fa-user"></i>
                          </div>
                          <div>
                            <p>Add Note</p>
                          </div>
                        </div>
                      </Automation_addNote_module>
                      <Automation_addEvent_module
                        setshow1={setshow1}
                        module_name={"opportunities"}
                        handle_submit={handle_addEvent_module}
                        backgroundColor={"#60CB98"}
                      >
                        <div
                          className="addActionsMenuContent_automat_each"
                          // onClick={SameModel}
                          style={{ background: "#60CB98" }}
                        >
                          <div className="ActionsMenuContent_icons">
                            <i className="fas fa-user"></i>
                          </div>
                          <div>
                            <p>Add an Event</p>
                          </div>
                        </div>
                      </Automation_addEvent_module>
                      <Automation_updateField_module
                        setshow1={setshow1}
                        module_name={"Opportunity"}
                        handle_submit={handle_updateField_module}
                        backgroundColor={"#60CB98"}
                      >
                        <div
                          className="addActionsMenuContent_automat_each"
                          // onClick={SameModel}
                          style={{ background: "#60CB98" }}
                        >
                          <div className="ActionsMenuContent_icons">
                            <i className="fas fa-user"></i>
                          </div>
                          <div>
                            <p>Update a field</p>
                          </div>
                        </div>
                      </Automation_updateField_module>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="five" className="pane_inner">
                    <div className="addActionsMenuContent_automat">
                      <Automation_addTag_module
                        setshow1={setshow1}
                        module_name={"projects"}
                        handle_submit={handle_add_contact_tag}
                        backgroundColor={"#60CB98"}
                      >
                        <div
                          className="addActionsMenuContent_automat_each"
                          // onClick={SameModel}
                          style={{ background: "#60CB98" }}
                        >
                          <div className="ActionsMenuContent_icons">
                            <i className="fas fa-user"></i>
                          </div>
                          <div>
                            <p>Add Tag</p>
                          </div>
                        </div>
                      </Automation_addTag_module>
                      <Automation_removeTag_module
                        setshow1={setshow1}
                        module_name={"projects"}
                        handle_submit={handle_remove_contact_tag}
                        backgroundColor={"#60CB98"}
                      >
                        <div
                          className="addActionsMenuContent_automat_each"
                          // onClick={SameModel}
                          style={{ background: "#60CB98" }}
                        >
                          <div className="ActionsMenuContent_icons">
                            <i className="fas fa-user"></i>
                          </div>
                          <div>
                            <p>Remove Tag</p>
                          </div>
                        </div>
                      </Automation_removeTag_module>
                      <Automation_addNote_module
                        setshow1={setshow1}
                        module_name={"projects"}
                        handle_submit={handle_addNote_module}
                        backgroundColor={"#60CB98"}
                      >
                        <div
                          className="addActionsMenuContent_automat_each"
                          // onClick={SameModel}
                          style={{ background: "#60CB98" }}
                        >
                          <div className="ActionsMenuContent_icons">
                            <i className="fas fa-user"></i>
                          </div>
                          <div>
                            <p>Add Note</p>
                          </div>
                        </div>
                      </Automation_addNote_module>
                      <Automation_addEvent_module
                        setshow1={setshow1}
                        module_name={"projects"}
                        handle_submit={handle_addEvent_module}
                        backgroundColor={"#60CB98"}
                      >
                        <div
                          className="addActionsMenuContent_automat_each"
                          // onClick={SameModel}
                          style={{ background: "#60CB98" }}
                        >
                          <div className="ActionsMenuContent_icons">
                            <i className="fas fa-user"></i>
                          </div>
                          <div>
                            <p>Add an Event</p>
                          </div>
                        </div>
                      </Automation_addEvent_module>
                      <Automation_updateField_module
                        setshow1={setshow1}
                        module_name={"Project"}
                        handle_submit={handle_updateField_module}
                        backgroundColor={"#60CB98"}
                      >
                        <div
                          className="addActionsMenuContent_automat_each"
                          // onClick={SameModel}
                          style={{ background: "#60CB98" }}
                        >
                          <div className="ActionsMenuContent_icons">
                            <i className="fas fa-user"></i>
                          </div>
                          <div>
                            <p>Update a field</p>
                          </div>
                        </div>
                      </Automation_updateField_module>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="six" className="pane_inner">
                    <div className="addActionsMenuContent_automat">
                      <Automation_addTag_module
                        setshow1={setshow1}
                        module_name={"events"}
                        handle_submit={handle_add_contact_tag}
                        backgroundColor={"#60CB98"}
                      >
                        <div
                          className="addActionsMenuContent_automat_each"
                          // onClick={SameModel}
                          style={{ background: "#60CB98" }}
                        >
                          <div className="ActionsMenuContent_icons">
                            <i className="fas fa-user"></i>
                          </div>
                          <div>
                            <p>Add Tag</p>
                          </div>
                        </div>
                      </Automation_addTag_module>
                      <Automation_removeTag_module
                        setshow1={setshow1}
                        module_name={"events"}
                        handle_submit={handle_remove_contact_tag}
                        backgroundColor={"#60CB98"}
                      >
                        <div
                          className="addActionsMenuContent_automat_each"
                          // onClick={SameModel}
                          style={{ background: "#60CB98" }}
                        >
                          <div className="ActionsMenuContent_icons">
                            <i className="fas fa-user"></i>
                          </div>
                          <div>
                            <p>Remove Tag</p>
                          </div>
                        </div>
                      </Automation_removeTag_module>
                      <Automation_addNote_module
                        setshow1={setshow1}
                        module_name={"events"}
                        handle_submit={handle_addNote_module}
                        backgroundColor={"#60CB98"}
                      >
                        <div
                          className="addActionsMenuContent_automat_each"
                          // onClick={SameModel}
                          style={{ background: "#60CB98" }}
                        >
                          <div className="ActionsMenuContent_icons">
                            <i className="fas fa-user"></i>
                          </div>
                          <div>
                            <p>Add Note</p>
                          </div>
                        </div>
                      </Automation_addNote_module>
                      <Automation_addEvent_module
                        setshow1={setshow1}
                        module_name={"events"}
                        handle_submit={handle_addEvent_module}
                        backgroundColor={"#60CB98"}
                      >
                        <div
                          className="addActionsMenuContent_automat_each"
                          // onClick={SameModel}
                          style={{ background: "#60CB98" }}
                        >
                          <div className="ActionsMenuContent_icons">
                            <i className="fas fa-user"></i>
                          </div>
                          <div>
                            <p>Add an Event</p>
                          </div>
                        </div>
                      </Automation_addEvent_module>
                      <Automation_updateField_module
                        setshow1={setshow1}
                        module_name={"events"}
                        handle_submit={handle_updateField_module}
                        backgroundColor={"#60CB98"}
                      >
                        <div
                          className="addActionsMenuContent_automat_each"
                          // onClick={SameModel}
                          style={{ background: "#60CB98" }}
                        >
                          <div className="ActionsMenuContent_icons">
                            <i className="fas fa-user"></i>
                          </div>
                          <div>
                            <p>Update a field</p>
                          </div>
                        </div>
                      </Automation_updateField_module>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      {/* ADD NEW ACTION MODAL End */}

      {AutomationModal && (
        <Modal
          show={show2}
          onHide={closeModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h5 className="modal-title">{HeaderModalName}</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Formik
                        // initialValues={}
                        // onSubmit={}
                        >
                            <Form name="myForm">
                                <div className="row">
                                    {selctor_show &&
                                        <FormControl
                                            className="form-control my-1"
                                            required={true}
                                            label={"Select Tag"}
                                            name="fname"
                                            control="input"
                                            placeholder={Translation(translations, "First Name")}
                                        />
                                    }
                                    {
                                        showdatamodal_form &&
                                        <div className="row">
                                            <div>
                                                <FormControl
                                                    className="form-control my-1"
                                                    required={true}
                                                    label={"Select Tag"}
                                                    name="fname"
                                                    control="input"
                                                    placeholder={Translation(translations, "First Name")}
                                                />
                                            </div>
                                            <div>
                                                <div>
                                                    <p className="m-0">From</p>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            value="option1"
                                                            checked={selectedOption === "option1"}
                                                            onChange={handleOptionChange}
                                                        />

                                                        Any value
                                                    </label>

                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            value="option2"
                                                            checked={selectedOption === "option2"}
                                                            onChange={handleOptionChange}
                                                        />

                                                        A specific value
                                                    </label>
                                                    {selectedOption === "option2" ?
                                                        <FormControl
                                                            className="form-control my-1"
                                                            name="fname"
                                                            control="input"
                                                            placeholder={Translation(translations, "Enter Specific Input ")}
                                                        /> : null}
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <p className="m-0">To</p>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            value="option1"
                                                            checked={selectedOption2 === "option1"}
                                                            onChange={handleOptionChange2}
                                                        />

                                                        Any value
                                                    </label>

                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            value="option2"
                                                            checked={selectedOption2 === "option2"}
                                                            onChange={handleOptionChange2}
                                                        />

                                                        A specific value
                                                    </label>
                                                    {selectedOption2 === "option2" ?
                                                        <FormControl
                                                            className="form-control my-1"
                                                            name="fname"
                                                            control="input"
                                                            placeholder={Translation(translations, "Enter Specific Input ")}
                                                        /> : null}
                                                </div>
                                                <div>
                                                    <FormControl
                                                        label={Translation(translations, "The contact is")}
                                                        className="form-control my-1"
                                                        name="fname"
                                                        control="input"
                                                        placeholder={Translation(translations, "The contact is ")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Form>
                        </Formik> */}
            {WebHookTriger && (
              <Formik
                initialValues={InitialWebHookTriger}
                // onSubmit={ }
              >
                <Form name="myForm">
                  <div className="row">
                    <div className="col-md-12">
                      <FormControl
                        className="form-control"
                        label={Translation(
                          translations,
                          `${"Copy This Link:"}`
                        )}
                        name="facebook"
                        control="input2"
                        placeholder={"https://www.facebook.com/xxxx"}
                      />
                    </div>
                    <div className="col-md-12">
                      <FormControl
                        className="form-control"
                        label={Translation(translations, `${"Last Request"}`)}
                        name="street_address"
                        rows="3"
                        control="textarea2"
                      />
                    </div>
                    <div className="col-md-12">
                      <span>Map Fields :</span>{" "}
                      <button
                        className="mapbtn_automation"
                        onClick={() => {
                          setMapFieldMoadalShow(true);
                          setAutomationModal(false);
                          // setmainTree_Top((prev_data) => [
                          //   ...prev_data,
                          //   {
                          //     tittle: "MAP Fields",
                          //     sub_tittle: "maps",
                          //     id: "1",
                          //   },
                          // ]);
                        }}
                      >
                        Map Fields
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            )}
            {FieldChangeTriger && (
              <Formik
                initialValues={InitialFieldChangeTriger}
                onSubmit={(value) => {
                  let reqField = [];
                  value.field_change_module = fieldChangeModule;
                  for (const key in value) {
                    if (value[key]) {
                      const element = value[key];
                    } else {
                      if (key === "field_change_from_specific_value") {
                        if (value?.field_change_from === "A Specific Value") {
                          reqField.push(key);
                        }
                      } else if (key === "field_change_to_specific_value") {
                        if (value?.field_change_to === "A Specific Value") {
                          reqField.push(key);
                        }
                      } else if (key === "segment_checkbox_field") {
                      } else {
                        reqField.push(key.replaceAll("_", " "));
                      }
                    }
                  }
                  if (value.segment_checkbox_field) {
                    segment_field_change.length &&
                      segment_field_change.map((obj) => {
                        Object.keys(obj).map((item) => {
                          if (item !== "between") {
                            if (!obj[item]) {
                              reqField.push("segement");
                            }
                          }
                        });
                      });
                    console.log(segment_field_change);
                  }
                  let s = segment_field_change.reduce((acc, val) => {
                    return acc.concat(
                      ` ${val.select} (In ${val.module_name}  ${val.field_name} ${val.condition_value} ${val.SearchInput})`
                    );
                  }, "");
                  console.log(segment_field_change, s);
                  if (!reqField.length) {
                    mainTree_Top.forEach((val, index) => {
                      if (val.id === trigger_id) {
                        let copy_arr = [...mainTree_Top];
                        copy_arr[index] = {
                          edit_name: "FIELD CHANGE",
                          tittle: `Field change ${value?.field}`,
                          trigger_name: "FIELD CHANGE",

                          sub_tittle: ` run ${value?.run_time?.replaceAll(
                            "_",
                            " "
                          )} ${value.segment_checkbox_field ? s : ""}`,
                          id: Math.random() + new Date(),
                          formData: [value],
                          actionDetails:
                            copy_arr.length && copy_arr[0].actionDetails
                              ? [...copy_arr[0].actionDetails]
                              : [],
                          segment: value.segment_checkbox_field
                            ? segment_field_change
                            : [],
                        };
                        setmainTree_Top(copy_arr);
                      }
                    });
                    !mainTree_Top.some((val) => val.id === trigger_id) &&
                      setmainTree_Top((prev_data) => [
                        ...prev_data,
                        {
                          edit_name: "FIELD CHANGE",
                          trigger_name: "FIELD CHANGE",

                          tittle: `Field change ${value?.field}`,
                          sub_tittle: ` run ${value?.run_time?.replaceAll(
                            "_",
                            " "
                          )} ${value.segment_checkbox_field ? s : ""}`,
                          id: Math.random() + new Date(),
                          formData: [value],
                          actionDetails:
                            prev_data.length && prev_data[0].actionDetails
                              ? [...prev_data[0].actionDetails]
                              : [],
                          segment: value.segment_checkbox_field
                            ? segment_field_change
                            : [],
                        },
                      ]);
                    closeModal();
                  } else {
                    swal({
                      title: "Fill all the required field",
                      icon: "error",
                    });
                  }
                }}
              >
                {({ values }) => (
                  <Form name="myForm">
                    <div className="row">
                      <div className="col-md-6">
                        <p className="m-0 fw-bold">Module</p>
                        <FormControl
                          className="form-control my-1"
                          firstSelect={"--select--"}
                          // label={Translation(translations, "Field")}
                          name="field_change_module"
                          selectList={DropDownDatas}
                          custom_label_name="Module"
                          customer_value_name="Module"
                          onChange={(event) =>
                            handleFieldChangeValue(event.target.value)
                          }
                          value={fieldChangeModule}
                          control="select_custom_options"
                        />
                        <p className="m-0 fw-bold">Field</p>
                        <FormControl
                          className="form-control my-1"
                          firstSelect={"--select--"}
                          // label={Translation(translations, "Field")}
                          name="field"
                          selectList={fieldChangeField_list}
                          custom_label_name="label"
                          customer_value_name="label"
                          defaultValue={values.field}
                          // onChange={(event) => handleTemplate(event)}
                          control="select_custom_options"
                        />
                      </div>
                      <div className="col-md-12">
                        <FormControl
                          options={["Any Value", "A Specific Value"]}
                          required={true}
                          label={Translation(translations, `From`)}
                          name={"field_change_from"}
                          control="radio3"
                        />
                        {values.field_change_from === "A Specific Value" && (
                          <FormControl
                            className="form-control my-1"
                            required={true}
                            label={Translation(translations, "Specific Value")}
                            name="field_change_from_specific_value"
                            control="input3"
                            placeholder={Translation(
                              translations,
                              "Specific Value"
                            )}
                          />
                        )}
                      </div>
                      <div className="col-md-12">
                        <FormControl
                          options={["Any Value", "A Specific Value"]}
                          required={true}
                          label={Translation(translations, `To`)}
                          name={"field_change_to"}
                          control="radio3"
                        />
                        {values.field_change_to === "A Specific Value" && (
                          <FormControl
                            className="form-control my-1"
                            required={true}
                            label={Translation(translations, "Specific Value")}
                            name="field_change_to_specific_value"
                            control="input3"
                            placeholder={Translation(
                              translations,
                              "Specific Value"
                            )}
                          />
                        )}
                      </div>
                      <div className="col-md-12">
                        <p className="m-0 fw-bold">Runs</p>
                      </div>
                      <div className="row align-items-center">
                        <div className="col-md-5">
                          <FormControl
                            className="form-control my-1"
                            firstSelect={"--select--"}
                            // label={Translation(translations, "Runs")}
                            name="run_time"
                            selectList={run_time}
                            defaultValue={values.run_time}
                            custom_label_name="label"
                            customer_value_name="value"
                            // onChange={(event) => handleTemplate(event)}
                            control="select_custom_options"
                          />
                        </div>
                        {/* <div className="col-md-2 fieldtop">
                          <span>the contact is</span>
                        </div>
                        <div className="col-md-5">
                          <FormControl
                            className="form-control my-1"
                            firstSelect={"--select--"}
                            // label={Translation(translations, "")}
                            name="add_update"
                            selectList={add_update}
                            custom_label_name="label"
                            customer_value_name="value"
                            // onChange={(event) => handleTemplate(event)}
                            control="select_custom_options"
                          />
                        </div> */}
                      </div>
                      <div className="col-md-2">
                        <p>ADVANCED</p>
                      </div>
                      <div className="col-md-10">
                        <hr className="my-hr" />
                      </div>
                      <div className="col-md-12">
                        <label>
                          <Field
                            type="checkbox"
                            name="segment_checkbox_field"
                            className="mr-2"
                          />
                          Segment the contact is entering this automation
                        </label>
                        <div>
                          {values.segment_checkbox_field && (
                            <div>
                              <Automation_segments
                                DropDownDatas={DropDownDatas}
                                setSegment_field_change={
                                  setSegment_field_change
                                }
                                segment_field_change={segment_field_change}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <Modal.Footer>
                      <div className="d-flex justify-content-between w-100 mt-3 align-items-baseline">
                        <div>
                          <button className="triggerbtn-back" type="button">
                            Back
                          </button>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="btn btn-success px-4"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            )}
            {AddedTagTriger && (
              <Formik
                initialValues={InitialAddedTagTriger}
                onSubmit={HandleSubmitTagAdded}
              >
                {({ values }) => (
                  <Form name="myForm">
                    <div className="row">
                      <div className=" row justify-content-between ">
                        <div className="col-md-6 ">
                          <FormControl
                            className="form-control tag-added-run"
                            firstSelect={"--select--"}
                            label={Translation(translations, `${"Module"}`)}
                            selectList={DropDownDatas}
                            custom_label_name="Module"
                            customer_value_name="Module"
                            defaultValue={values.tags_added_module}
                            name="tags_added_module"
                            // onChange={(event) => handleTemplate(event)}
                            control="select_custom_options"
                          />
                        </div>
                        <div className="col-md-6 p-0 m-0">
                          <FormControl
                            className="form-control tag-added-run"
                            firstSelect={"--select--"}
                            label={Translation(translations, "Runs")}
                            name="tags_added_runs"
                            selectList={run_time}
                            custom_label_name="label"
                            customer_value_name="value"
                            defaultValue={values.tags_added_runs}
                            // onChange={(event) => handleTemplate(event)}
                            control="select_custom_options"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mt-3   ">
                        <FormControl
                          className="form-control my-1"
                          label={Translation(translations, "Tags")}
                          name="tags_added"
                          control="input3"
                          placeholder={Translation(translations, "Tags")}
                        />
                      </div>
                      <div className="col-md-2 mt-2">
                        <p>ADVANCED</p>
                      </div>
                      <div className="col-md-10 mt-2">
                        <hr className="my-hr" />
                      </div>
                      <div className="col-md-12">
                        <label>
                          <Field
                            type="checkbox"
                            name="segment_checkbox_tag_added"
                            className="mr-2"
                            defaultValue={values.segment_checkbox_tag_added}
                          />
                          Segment the contacts entering this automation
                        </label>
                        <div>
                          {values.segment_checkbox_tag_added && (
                            <div>
                              <Automation_segments
                                DropDownDatas={DropDownDatas}
                                setSegment_field_change={setSegment_tags_add}
                                segment_field_change={segment_tags_add}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <Modal.Footer>
                      <div className="d-flex justify-content-between w-100 mt-3 align-items-baseline">
                        <div>
                          <button className="triggerbtn-back" type="button">
                            Back
                          </button>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="btn btn-success px-4"
                            // onClick={() => {
                            //   setmainTree_Top((prev_data) => [
                            //     ...prev_data,
                            //     {
                            //       tittle: "Add trigger",
                            //       sub_tittle: "testing tags",
                            //       id: "3",
                            //     },
                            //   ]);
                            // }}
                          >
                            Add Start
                          </button>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            )}
            {RemoveTagTriger && (
              <Formik
                initialValues={InitialRemoveTagTriger}
                onSubmit={HandleSubmitTagRemoved}
              >
                {({ values }) => (
                  <Form name="myForm">
                    <div className="row">
                      <div className=" row justify-content-between ">
                        <div className="col-md-6 ">
                          <FormControl
                            className="form-control tag-added-run"
                            firstSelect={"--select--"}
                            label={Translation(translations, `${"Module"}`)}
                            selectList={DropDownDatas}
                            custom_label_name="Module"
                            customer_value_name="Module"
                            name="tags_removed_module"
                            defaultValue={values.tags_removed_module}
                            // onChange={(event) => handleTemplate(event)}
                            control="select_custom_options"
                          />
                        </div>
                        <div className="col-md-6 p-0 m-0">
                          <FormControl
                            className="form-control tag-added-run"
                            firstSelect={"--select--"}
                            label={Translation(translations, "Runs")}
                            name="tags_removed_runs"
                            selectList={run_time}
                            custom_label_name="label"
                            customer_value_name="value"
                            defaultValue={values.tags_removed_runs}
                            // onChange={(event) => handleTemplate(event)}
                            control="select_custom_options"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mt-3   ">
                        <FormControl
                          className="form-control my-1"
                          label={Translation(translations, "Tags")}
                          name="tags_removed"
                          control="input3"
                          placeholder={Translation(translations, "Tags")}
                        />
                      </div>
                      <div className="col-md-2 mt-2">
                        <p>ADVANCED</p>
                      </div>
                      <div className="col-md-10 mt-2">
                        <hr className="my-hr" />
                      </div>
                      <div className="col-md-12">
                        <label>
                          <Field
                            type="checkbox"
                            name="segment_checkbox_tag_removed"
                            className="mr-2"
                          />
                          Segment the contacts entering this automation
                        </label>
                        <div>
                          {values.segment_checkbox_tag_removed && (
                            <div>
                              <Automation_segments
                                DropDownDatas={DropDownDatas}
                                setSegment_field_change={setSegment_tags_remove}
                                segment_field_change={segment_tags_remove}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <Modal.Footer>
                      <div className="d-flex justify-content-between w-100 mt-3 align-items-baseline">
                        <div>
                          <button className="triggerbtn-back" type="button">
                            Back
                          </button>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="btn btn-success px-4"
                            // onClick={() => {
                            //   setmainTree_Top((prev_data) => [
                            //     ...prev_data,
                            //     {
                            //       tittle: "Add trigger",
                            //       sub_tittle: "testing tags",
                            //       id: "3",
                            //     },
                            //   ]);
                            // }}
                          >
                            Add Start
                          </button>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            )}
            {BookingCalenderTriger && (
              <Formik
                initialValues={InitialBookingCalenderTriger}
                onSubmit={HandleSubmitBooking}
              >
                {({ values }) => (
                  <Form name="myForm">
                    <div className="row">
                      <div className="col-md-12">
                        <Select
                          showSearch
                          onSearch={(v) => {}}
                          onChange={(v1, v2) => {
                            console.log(v1, v2);
                            values.booking_calendar_appoinment_id = v1;
                            values.booking_calendar_appoinment_name =
                              v2.children;
                            // handleFieldNameChange(v1,index,v2)
                          }}
                          style={{ width: "100%", height: 40 }}
                          placeholder={"Search calendar name"}
                          defaultValue={values.booking_calendar_appoinment_id}
                        >
                          {/* Array.isArray(addFollower) && datas.lead_data && 
                        addFollower.filter(ite => (datas?.parent_ids?.includes(ite?.id) === false)) */}
                          {bookingCalendar_list.length &&
                            bookingCalendar_list.map(
                              ({ calendar_name, calendar_id }) => (
                                <Select.Option
                                  value={calendar_id}
                                  key={calendar_id}
                                  type={calendar_name}
                                >
                                  {calendar_name}
                                </Select.Option>
                              )
                            )}
                        </Select>
                        {/* <FormControl
                        className="form-control my-1"
                        firstSelect={"--select--"}
                        label={Translation(
                          translations,
                          "Run when appointment status changed for this calander :"
                        )}
                        name="booking_calendar_appoinment"
                        selectList={bookingCalendar_list}
                        custom_label_name="calendar_name"
                        customer_value_name="calendar_id"
                        onChange={(event) =>{values.booking_calendar_appoinment="tttt"}}
                        control="select_custom_options2"
                      /> */}
                      </div>
                      <div className="col-md-12">
                        <FormControl
                          className="form-control my-1"
                          firstSelect={"--select--"}
                          label={Translation(
                            translations,
                            "For which status should this run:"
                          )}
                          name="booking_calendar_run"
                          selectList={[
                            { label: "Scheduled" },
                            { label: "Rescheduled" },
                            { label: "Cancelled" },
                          ]}
                          custom_label_name="label"
                          customer_value_name="label"
                          // onChange={(event) => handleTemplate(event)}
                          control="select_custom_options2"
                          defaultValue={values.booking_calendar_run}
                          selected={values.booking_calendar_run}
                        />
                      </div>
                    </div>
                    <Modal.Footer>
                      <div className="d-flex justify-content-between w-100 mt-3 align-items-baseline">
                        <div>
                          <button className="triggerbtn-back" type="button">
                            Back
                          </button>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="btn btn-success px-4"
                          >
                            Add Start
                          </button>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            )}
            {WebPageVisitedTriger && (
              <Formik
                initialValues={InitialWebPageVisitedTriger}
                onSubmit={HandleSubmitWebPage}
              >
                {({ values, setFieldValue }) => (
                  <Form name="myForm">
                    <div className="row">
                      <div className="col-md-6">
                        <FormControl
                          className="form-control "
                          firstSelect={"--select--"}
                          label={Translation(translations, "Web page URL")}
                          name="web_page_URL_id"
                          selectList={web_page_url_list}
                          custom_label_name="url"
                          customer_value_name="id"
                          onChange={(event) => {
                            console.log(
                              web_page_url_list[
                                event.target.options.selectedIndex - 1
                              ]
                            );
                            values.web_page_URL_id = event.target.value;
                            values.web_page_URL_name =
                              web_page_url_list[
                                event.target.options.selectedIndex - 1
                              ].url;
                            setFieldValue(values);
                          }}
                          control="select_custom_options2"
                          defaultValue={values.web_page_URL_id}
                        />
                      </div>
                      <div className="col-md-1  mt-2 ">
                        <span>/</span>
                      </div>
                      <div className="col-md-5 ">
                        <FormControl
                          className="form-control"
                          // label={Translation(translations, `${"Facebook"}`)}
                          name="web_page_URL_sub"
                          control="input2"
                          placeholder={"https://www.facebook.com/xxxx"}
                        />
                      </div>
                      <div className="col-md-12 ">
                        <div className="col-md-6  p-0 m-0">
                          <FormControl
                            className="form-control "
                            firstSelect={"--select--"}
                            label={Translation(translations, "Runs")}
                            name="runs"
                            selectList={run_time}
                            custom_label_name="label"
                            customer_value_name="value"
                            // onChange={(event) => handleTemplate(event)}
                            defaultValue={values.runs}
                            control="select_custom_options2"
                          />
                        </div>
                      </div>
                      <div className="col-md-2 mt-2">
                        <p>ADVANCED</p>
                      </div>
                      <div className="col-md-10 mt-2">
                        <hr className="my-hr" />
                      </div>
                      <div className="col-md-12">
                        <label>
                          <Field type="checkbox" name="web_page_segment" />
                          Segment the contacts entering this automation
                        </label>
                        <div>
                          {values.web_page_segment && (
                            <div>
                              <Automation_segments
                                DropDownDatas={DropDownDatas}
                                setSegment_field_change={
                                  setSegment_webPage_visited
                                }
                                segment_field_change={segment_webPage_visited}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <Modal.Footer>
                      <div className="d-flex justify-content-between w-100 mt-3 align-items-baseline">
                        <div>
                          <button className="triggerbtn-back" type="button">
                            Back
                          </button>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="btn btn-success px-4"
                          >
                            Add Start
                          </button>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            )}
            {DateBasedTriger && (
              <Formik
                initialValues={InitialDateBasedTriger}
                onSubmit={handle_submit_date_based_trigger}
              >
                {({ values, setFieldValue }) => (
                  <Form name="myForm">
                    <div className="row">
                      <div className="row ">
                        <div className="col-md-2 p-0">
                          <span>
                            <b>Module:</b>
                          </span>
                        </div>

                        <div className="col-8">
                          <FormControl
                            className="form-control "
                            firstSelect={"--select--"}
                            // label={Translation(translations, "Runs")}
                            name="module_date_based"
                            selectList={DropDownDatas}
                            custom_label_name="Module"
                            customer_value_name="Module"
                            onChange={(event) => {
                              values.module_date_based = event.target.value;
                              console.log(values);
                              setFieldValue(values);
                              handle_module_change_data_based(
                                event.target.value
                              );
                            }}
                            // onChange={(event) => handleTemplate(event)}
                            control="select_custom_options"
                            defaultValue={values.module_date_based}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-2 p-0">
                          <span>
                            <b>Starts</b>
                          </span>
                        </div>
                        <div className="col-md-1">
                          <FormControl
                            className="form-control tag-added-run date_base_num"
                            name="start_num_date_based"
                            defaultValue={values.start_num_date_based}
                            // onChange={(event) => handleTemplate(event)}
                            control="input"
                            type="number"
                            min="0"
                          />
                        </div>
                        <div className="col-md-2">
                          <FormControl
                            className="form-control tag-added-run"
                            firstSelect={"--select--"}
                            selectList={[
                              { label: "Days" },
                              { label: "Weeks" },
                              { label: "Months" },
                              { label: "Years" },
                            ]}
                            custom_label_name="label"
                            customer_value_name="label"
                            name="start_days_date_based"
                            defaultValue={values.start_days_date_based}
                            // onChange={(event) => handleTemplate(event)}
                            control="select_custom_options"
                          />
                        </div>
                        <div className="col-md-2">
                          <FormControl
                            className="form-control "
                            firstSelect={"--select--"}
                            // label={Translation(translations, "Runs")}
                            name="start_on_bef_aft_date_based"
                            selectList={[
                              { label: "on" },
                              { label: "before" },
                              { label: "after" },
                            ]}
                            custom_label_name="label"
                            customer_value_name="label"
                            // onChange={(event) => handleTemplate(event)}
                            control="select_custom_options"
                            defaultValue={values.start_on_bef_aft_date_based}
                          />
                        </div>

                        <div className="col-md-1 d-flex align-items-center">
                          <span className="ml-1">the</span>
                        </div>
                        <div className="col-2">
                          <FormControl
                            className="form-control my-1"
                            firstSelect={"--select--"}
                            // label={Translation(translations, "Field")}
                            name="start_on_the_date_date_based"
                            selectList={module_change_data_based}
                            custom_label_name="label"
                            customer_value_name="label"
                            // onChange={(event) => handleTemplate(event)}
                            control="select_custom_options"
                            defaultValue={values.start_on_the_date_date_based}
                          />
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-2 p-0 ">
                          <span>
                            <b>Check</b>
                          </span>
                        </div>
                        <div className="col-md-2">
                          <FormControl
                            className="form-control "
                            firstSelect={"--select--"}
                            // label={Translation(translations, "Runs")}
                            name="start_run_day_date_based"
                            selectList={[
                              { label: "Daily" },
                              { label: "WeekDays" },
                              { label: "Weekends" },
                            ]}
                            custom_label_name="label"
                            customer_value_name="label"
                            // onChange={(event) => handleTemplate(event)}
                            control="select_custom_options"
                            defaultValue={values.start_run_day_date_based}
                          />
                        </div>
                        <div className="col-md-1 d-flex align-items-center">
                          <span className="mx-1">around</span>
                        </div>
                        <div className="col-md-2">
                          <FormControl
                            className="form-control "
                            firstSelect={"--select--"}
                            // label={Translation(translations, "Runs")}
                            name="start_hour_date_based"
                            selectList={timeArray}
                            custom_label_name="label"
                            customer_value_name="value"
                            // onChange={(event) => handleTemplate(event)}
                            control="select_custom_options"
                            defaultValue={values.start_hour_date_based}
                          />
                        </div>
                        <div className="col-md-3 mt-2  timezone_date_based">
                          <Dropdown
                            name="timezone_date_based"
                            list={timeZone}
                            onChange={(v) => {
                              values.timezone_date_based_value = v.value;
                              values.timezone_date_based = v;
                              console.log(values);
                              setFieldValue(values);
                            }}
                            value={values.timezone_date_based}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <span>
                          <b>Runs</b>
                        </span>
                      </div>
                      <div className="col-md-10">
                        <label className="custom-control custom-radio custom-control-inline">
                          <Field
                            className="custom-control-input"
                            name="runs_date_based"
                            type="radio"
                            value="month_day_match"
                            // onClick={hanldeMarrow}
                            // placeholder=" First Name"
                          />
                          <span className="custom-control-label">
                            {" "}
                            <b> when month and day match conditions</b>
                            <p>
                              Examples: Birthdays, annual contracts ,and other
                              dates that recur yearly .
                            </p>
                          </span>
                        </label>
                        <label className="custom-control custom-radio custom-control-inline">
                          <Field
                            className="custom-control-input"
                            name="runs_date_based"
                            type="radio"
                            value="year_month_day_match"
                            // onClick={hanldeMarrow}
                            // placeholder=" First Name"
                          />
                          <span className="custom-control-label">
                            {" "}
                            <b> when year,month and day match conditions</b>
                            <p>
                              Examples: Events,Contract expirations,and other
                              datesthat do not recur yearly. Hint: if you have a
                              field that you update over time (such as a
                              contract experiration) the automationwill trigger
                              each time we find a match .
                            </p>
                          </span>
                        </label>
                      </div>
                      <div className="col-md-2 mt-2">
                        <p>ADVANCED</p>
                      </div>
                      <div className="col-md-10 mt-2">
                        <hr className="my-hr" />
                      </div>
                      <div className="col-md-12">
                        <label>
                          <Field
                            type="checkbox"
                            name="segment_date_based"
                            className="mr-2"
                          />
                          Segment the contact is entering this automation
                        </label>
                        <div>
                          {values.segment_date_based && (
                            <div>
                              <Automation_segments
                                DropDownDatas={DropDownDatas}
                                setSegment_field_change={setSegment_date_based}
                                segment_field_change={segment_date_based}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <Modal.Footer>
                      <div className="d-flex justify-content-between w-100 mt-3 align-items-baseline">
                        <div>
                          <button className="triggerbtn-back">Back</button>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="btn btn-success px-4"
                          >
                            Add Start
                          </button>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            )}
          </Modal.Body>
        </Modal>
      )}
      {/* // Map Field modal   */}
      <Modal
        show={MapFieldMoadalShow}
        onHide={() => {
          setMapFieldMoadalShow(false);
          setAutomationModal(true);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5 className="modal-title">Map Fields</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover size="sm">
            <thead className="">
              <tr>
                <th className="text-start">Field Id</th>
                <th className="text-start">Value</th>
                <th className="text-start">Map To</th>
              </tr>
            </thead>
            <tbody>
              {mapField_list.map((list, index) => {
                return (
                  <tr key={index}>
                    <td className="">{list.name}</td>
                    <td className="">{list.value}</td>
                    <td>
                      <select className="form-control" name="map_select" id="">
                        {list.map_to.lists.map((value, key) => {
                          return <option>{value.label}</option>;
                        })}
                      </select>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button>Save Changes</Button>
        </Modal.Footer>
      </Modal>
      {/* // Map Field modal end  */}

      {ActionsTriggerModal && (
        <Modal
          show={ActionsTriggerModal}
          // onHide={() => {
          //     setActionsTriggerModal(false)
          //     setshow1(true)
          //     setWaitRadio2(false)
          //     setWaitRadio1(false)
          // }}
          onHide={ActionModalCanelHandle}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h5 className="modal-title">{HeaderModalActions}</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {ActionWait && (
              <Formik
                initialValues={InitialWaitAction}
                onSubmit={HandleActionWaitSubmit}
              >
                {({ values, setFieldValue }) => (
                  <Form name="myForm">
                    <div className="row">
                      <div className="col-sm-12">
                        <label className="custom-control custom-radio custom-control-inline">
                          <Field
                            className="custom-control-input"
                            name="radioOption1"
                            type="radio"
                            required={true}
                            value="wait_1"
                          />
                          <span className="custom-control-label">
                            Wait for a specified period of time
                          </span>
                        </label>
                      </div>
                      <div className="col-sm-12">
                        <label className="custom-control custom-radio custom-control-inline">
                          <Field
                            className="custom-control-input"
                            name="radioOption1"
                            type="radio"
                            value="wait_2"
                            required={true}
                          />
                          <span className="custom-control-label">
                            Wait until specific conditions are met
                          </span>
                        </label>
                      </div>
                      <div className="col-sm-12">
                        {values.radioOption1 === "wait_1" ? (
                          <div className="row w-100 justify-content-evenly">
                            <div className="col-md-4">
                              <FormControl
                                className="form-control "
                                // label={Translation(translations, "Runs")}
                                name="wait_for"
                                selectList={[
                                  { label: "wait for", value: "wait for" },
                                ]}
                                required={true}
                                value={"wait_for"}
                                custom_label_name="label"
                                customer_value_name="value"
                                // onChange={(event) => handleTemplate(event)}
                                control="select_custom_options"
                              />
                            </div>
                            <div className="col-md-6 p-0 m-0 d-flex align-items-center">
                              <FormControl
                                className="form-control "
                                name="wait_time_count"
                                type="number"
                                control="input"
                                placeholder={"Value"}
                                required={true}
                              />
                            </div>
                            <div className="col-md-2">
                              <FormControl
                                className="form-control "
                                firstSelect={"--Day(s)--"}
                                required={true}
                                // label={Translation(translations, "Runs")}
                                name="wait_time_days"
                                selectList={[
                                  { label: "days", value: "days" },
                                  { label: "hour", value: "hour" },
                                  { label: "minutes", value: "minutes" },
                                ]}
                                custom_label_name="label"
                                customer_value_name="value"
                                // onChange={(event) => handleTemplate(event)}
                                control="select_custom_options"
                              />
                            </div>
                          </div>
                        ) : null}
                        {values.radioOption1 === "wait_2" ? (
                          <Automation_segments
                            DropDownDatas={DropDownDatas}
                            setSegment_field_change={setsegmentaction_wait_for}
                            segment_field_change={segmentaction_wait_for}
                          />
                        ) : null}
                      </div>
                      {/* <div className="col-sm-12">
                        {values.radioOption1 === "wait_2" ? (
                          <>
                            <Automation_segments
                              DropDownDatas={DropDownDatas}
                              setSegment_field_change={
                                setsegmentaction_wait_for
                              }
                              segment_field_change={segmentaction_wait_for}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      {WaitRadio1 && (
                        <div className="row">
                          <div className="col-sm-12"></div>
                        </div>
                      )}
                      {WaitRadio2 && (
                        <div className="row">
                          <FormControl
                            className="form-control "
                            firstSelect={"--No time limit--"}
                            label={Translation(translations, "The Limit")}
                            name="time_limit"
                            selectList={[
                              { label: "No time limit", value: "No_limit" },
                              { label: "Upto", value: "upto" },
                            ]}
                            custom_label_name="label"
                            customer_value_name="value"
                            // onChange={(event) => handleTemplate(event)}
                            control="select_custom_options"
                          />
                          {values.time_limit === "upto" ? (
                            <div className="row w-100 justify-content-evenly">
                              <div className="col-md-4">
                                <FormControl
                                  className="form-control "
                                  // label={Translation(translations, "Runs")}
                                  name="wait_for"
                                  selectList={[
                                    { label: "wait for", value: "wait for" },
                                  ]}
                                  required={true}
                                  value={"wait_for"}
                                  custom_label_name="label"
                                  customer_value_name="value"
                                  // onChange={(event) => handleTemplate(event)}
                                  control="select_custom_options"
                                />
                              </div>
                              <div className="col-md-6 p-0 m-0 d-flex align-items-center">
                                <FormControl
                                  className="form-control "
                                  name="wait_time_count"
                                  type="number"
                                  control="input"
                                  placeholder={"Value"}
                                  required={true}
                                />
                              </div>
                              <div className="col-md-2">
                                <FormControl
                                  className="form-control "
                                  firstSelect={"--Day(s)--"}
                                  required={true}
                                  // label={Translation(translations, "Runs")}
                                  name="wait_time_days"
                                  selectList={[
                                    { label: "days", value: "days" },
                                    { label: "hour", value: "hour" },
                                    { label: "minutes", value: "minutes" },
                                  ]}
                                  custom_label_name="label"
                                  customer_value_name="value"
                                  // onChange={(event) => handleTemplate(event)}
                                  control="select_custom_options"
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      )} */}
                    </div>
                    <Modal.Footer>
                      <div className="d-flex justify-content-between w-100 mt-3 align-items-baseline">
                        <div>
                          <button
                            className="triggerbtn-back"
                            onClick={ActionModalCanelHandle}
                          >
                            Back
                          </button>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="btn btn-success px-4"
                          >
                            Add Start
                          </button>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            )}
            {ActionIf_Else && (
              <Formik
                initialValues={InitialIf_ElseAction}
                onSubmit={Handle_if_elseSubmit}
              >
                <Form>
                  <div className="row">
                    <Automation_segments
                      DropDownDatas={DropDownDatas}
                      setSegment_field_change={setIf_ELSE_Array}
                      segment_field_change={If_ELSE_Array}
                    />
                    {/* <div className="row mt-2">
                      <div className="col-sm-2">
                        <label className="custom-control custom-radio custom-control-inline">
                          <Field
                            className="custom-control-input"
                            name="and_or_1"
                            type="radio"
                            value="and"
                            // onClick={hanldeMarrow}
                            // placeholder=" First Name"
                          />
                          <span className="custom-control-label">And</span>
                        </label>
                        <label className="custom-control custom-radio custom-control-inline">
                          <Field
                            className="custom-control-input"
                            name="and_or_1"
                            type="radio"
                            value="or"
                          />
                          <span className="custom-control-label">Or</span>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <FormControl
                          className="form-control "
                          firstSelect={"--Select Option--"}
                          // label={Translation(translations, "Runs")}
                          name="select_option_2"
                          selectList={DropDownDatas}
                          custom_label_name="Module"
                          customer_value_name="Module"
                          // onChange={(event) => handleTemplate(event)}
                          control="select_custom_options"
                        />
                      </div>
                      <div className="col-sm-3">
                        <FormControl
                          className="form-control "
                          firstSelect={"--Select Condition--"}
                          // label={Translation(translations, "Runs")}
                          name="select_condition_2"
                          selectList={[
                            { label: "Is" },
                            { label: "Is not" },
                            { label: "Contains" },
                            { label: "Does not contains" },
                          ]}
                          custom_label_name="label"
                          customer_value_name="label"
                          // onChange={(event) => handleTemplate(event)}
                          control="select_custom_options"
                        />
                      </div>
                      <div className="col-sm-3">
                        <FormControl
                          className="form-control"
                          // label={Translation(translations, `${"Facebook"}`)}
                          name="condition_value_2"
                          control="input2"
                          placeholder={"Enter Condition Value"}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-sm-2">
                        <label className="custom-control custom-radio custom-control-inline">
                          <Field
                            className="custom-control-input"
                            name="and_or_2"
                            type="radio"
                            value="and"
                            // onClick={hanldeMarrow}
                            // placeholder=" First Name"
                          />
                          <span className="custom-control-label">And</span>
                        </label>
                        <label className="custom-control custom-radio custom-control-inline">
                          <Field
                            className="custom-control-input"
                            name="and_or_2"
                            type="radio"
                            value="or"
                            // onClick={hanldeMarrow}
                            // placeholder=" First Name"
                          />
                          <span className="custom-control-label">Or</span>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <FormControl
                          className="form-control "
                          firstSelect={"--Select Option--"}
                          // label={Translation(translations, "Runs")}
                          name="select_option_3"
                          selectList={DropDownDatas}
                          custom_label_name="Module"
                          customer_value_name="Module"
                          // onChange={(event) => handleTemplate(event)}
                          control="select_custom_options"
                        />
                      </div>
                      <div className="col-sm-3">
                        <FormControl
                          className="form-control "
                          firstSelect={"--Select Condition--"}
                          // label={Translation(translations, "Runs")}
                          name="select_condition_3"
                          selectList={[
                            { label: "Is" },
                            { label: "Is not" },
                            { label: "Contains" },
                            { label: "Does not contains" },
                          ]}
                          custom_label_name="label"
                          customer_value_name="label"
                          // onChange={(event) => handleTemplate(event)}
                          control="select_custom_options"
                        />
                      </div>
                      <div className="col-sm-3">
                        <FormControl
                          className="form-control"
                          // label={Translation(translations, `${"Facebook"}`)}
                          name="condition_value_3"
                          control="input2"
                          placeholder={"Enter Condition Value"}
                        />
                      </div>
                    </div>

                    {If_ELSE_Array?.map((i, index) => {
                      const uniquevalue = index + 4;
                      // console.log(uniquevalue);
                      return (
                        <div className="row mt-2" key={index}>
                          <div className="col-sm-2">
                            <label className="custom-control custom-radio custom-control-inline">
                              <Field
                                className="custom-control-input"
                                name={`and_or_${uniquevalue}`}
                                type="radio"
                                value="and"
                                // onClick={hanldeMarrow}
                                // placeholder=" First Name"
                              />
                              <span className="custom-control-label">And</span>
                            </label>
                            <label className="custom-control custom-radio custom-control-inline">
                              <Field
                                className="custom-control-input"
                                name={`and_or_${uniquevalue}`}
                                type="radio"
                                value="or"
                                // onClick={hanldeMarrow}
                                // placeholder=" First Name"
                              />
                              <span className="custom-control-label">Or</span>
                            </label>
                          </div>
                          <div className="col-sm-3">
                            <FormControl
                              className="form-control "
                              firstSelect={"--Select Option--"}
                              // label={Translation(translations, "Runs")}
                              name={`select_option_${uniquevalue}`}
                              selectList={DropDownDatas}
                              custom_label_name="Module"
                              customer_value_name="Module"
                              // onChange={(event) => handleTemplate(event)}
                              control="select_custom_options"
                            />
                          </div>
                          <div className="col-sm-3">
                            <FormControl
                              className="form-control "
                              firstSelect={"--Select Condition--"}
                              // label={Translation(translations, "Runs")}
                              name={`select_condition_${uniquevalue}`}
                              selectList={[
                                { label: "Is" },
                                { label: "Is not" },
                                { label: "Contains" },
                                { label: "Does not contains" },
                              ]}
                              custom_label_name="label"
                              customer_value_name="label"
                              // onChange={(event) => handleTemplate(event)}
                              control="select_custom_options"
                            />
                          </div>
                          <div className="col-sm-3">
                            <FormControl
                              className="form-control"
                              // label={Translation(translations, `${"Facebook"}`)}
                              name={`condition_value_${uniquevalue}`}
                              control="input2"
                              placeholder={"Enter Condition Value"}
                            />
                          </div>
                        </div>
                      );
                    })} */}
                  </div>
                  <Modal.Footer>
                    <div className="d-flex justify-content-between w-100 mt-3 align-items-baseline">
                      <div>
                        <button
                          className="triggerbtn-back"
                          onClick={ActionModalCanelHandle}
                        >
                          Back
                        </button>
                      </div>
                      <div>
                        <button type="submit" className="btn btn-success px-4">
                          Add Start
                        </button>
                      </div>
                    </div>
                  </Modal.Footer>
                </Form>
              </Formik>
            )}
            {ActionStartAutomation && (
              <Formik
                initialValues={InitialStartAutomationAction}
                onSubmit={(values) => {
                  handle_add_other_automation(values, "add");
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className="row">
                      {/* <div className="col-sm-12">
                      <FormControl
                        className="form-control "
                        firstSelect={"Enter all other automations"}
                        label={Translation(
                          translations,
                          "Start an automation to enter"
                        )}
                        name="sign_temp"
                        // selectList={""}
                        custom_label_name="template_name"
                        customer_value_name="template_id"
                        // onChange={(event) => handleTemplate(event)}
                        control="select_custom_options"
                      />
                    </div> */}
                      <div className="col-sm-12">
                        <div className="form-group row">
                          <b className="col-sm-4 col-form-label">
                            {Translation(
                              translations,
                              "Search automation to add"
                            )}
                          </b>
                          <div className="col-sm-12">
                            <Select
                              showSearch
                              mode="multiple"
                              // fieldNames={"add_automation_select"}
                              onSearch={(v) => {}}
                              onChange={(v1, v2) => {
                                values.add_automation_select = v1;
                                values.add_automation_select_list = v2;
                                setFieldValue(values);

                                console.log(v1, v2);
                                // values.booking_calendar_appoinment_id = v1;
                                // values.booking_calendar_appoinment_name =
                                //   v2.children;
                                // handleFieldNameChange(v1,index,v2)
                              }}
                              defaultValue={
                                InitialStartAutomationAction.add_automation_select
                              }
                              style={{ width: "100%", height: 40 }}
                              placeholder={"Search calendar name"}
                            >
                              {/* Array.isArray(addFollower) && datas.lead_data && 
                        addFollower.filter(ite => (datas?.parent_ids?.includes(ite?.id) === false)) */}
                              {allAutomation_data.length &&
                                allAutomation_data.map(({ name, id }) => (
                                  <Select.Option
                                    value={id}
                                    key={id}
                                    type={name}
                                  >
                                    {name}
                                  </Select.Option>
                                ))}
                            </Select>
                          </div>
                        </div>
                      </div>
                      <Modal.Footer>
                        <div className="d-flex justify-content-between w-100 mt-3 align-items-baseline">
                          <div>
                            <button
                              className="triggerbtn-back"
                              onClick={ActionModalCanelHandle}
                            >
                              Back
                            </button>
                          </div>
                          <div>
                            <button
                              type="submit"
                              className="btn btn-success px-4"
                            >
                              Add Start
                            </button>
                          </div>
                        </div>
                      </Modal.Footer>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
            {ActionEndAnotherAutomation && (
              <Formik
                initialValues={InitialEndAutomationAction}
                onSubmit={(values) => {
                  handle_add_other_automation(values, "end_another");
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className="row">
                      {/* <div className="col-sm-12">
                      <FormControl
                        className="form-control "
                        firstSelect={"Enter all other automations"}
                        label={Translation(
                          translations,
                          "Start an automation to enter"
                        )}
                        name="sign_temp"
                        // selectList={""}
                        custom_label_name="template_name"
                        customer_value_name="template_id"
                        // onChange={(event) => handleTemplate(event)}
                        control="select_custom_options"
                      />
                    </div> */}
                      <div className="col-sm-12">
                        <div className="form-group row">
                          <b className="col-sm-4 col-form-label">
                            {Translation(
                              translations,
                              "Search automation to End"
                            )}
                          </b>
                          <div className="col-sm-12">
                            <Select
                              showSearch
                              mode="multiple"
                              // fieldNames={"add_automation_select"}
                              onSearch={(v) => {}}
                              onChange={(v1, v2) => {
                                values.add_automation_select = v1;
                                values.add_automation_select_list = v2;
                                setFieldValue(values);

                                console.log(v1, v2);
                                // values.booking_calendar_appoinment_id = v1;
                                // values.booking_calendar_appoinment_name =
                                //   v2.children;
                                // handleFieldNameChange(v1,index,v2)
                              }}
                              defaultValue={
                                InitialStartAutomationAction.add_automation_select
                              }
                              style={{ width: "100%", height: 40 }}
                              placeholder={"Search calendar name"}
                            >
                              {/* Array.isArray(addFollower) && datas.lead_data && 
                        addFollower.filter(ite => (datas?.parent_ids?.includes(ite?.id) === false)) */}
                              {allAutomation_data.length &&
                                allAutomation_data.map(({ name, id }) => (
                                  <Select.Option
                                    value={id}
                                    key={id}
                                    type={name}
                                  >
                                    {name}
                                  </Select.Option>
                                ))}
                            </Select>
                          </div>
                        </div>
                      </div>
                      <Modal.Footer>
                        <div className="d-flex justify-content-between w-100 mt-3 align-items-baseline">
                          <div>
                            <button
                              className="triggerbtn-back"
                              onClick={ActionModalCanelHandle}
                            >
                              Back
                            </button>
                          </div>
                          <div>
                            <button
                              type="submit"
                              className="btn btn-success px-4"
                            >
                              Add Start
                            </button>
                          </div>
                        </div>
                      </Modal.Footer>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
            {AtionWebhook && (
              <Formik
                initialValues={InitialEndAnotherAutomationAction}
                onSubmit={(values) => {
                  handle_add_webHook(values);
                }}
              >
                <Form>
                  <div className="row">
                    <div className="row p-0 m-0">
                      <div className="col-sm-4">
                        <FormControl
                          className=" form-control border rounded-left rounded-right"
                          style={{ height: "35px", width: "100%" }}
                          firstSelect={"POST"}
                          label={Translation(translations, "Target URL:")}
                          name="request_type"
                          defaultValue={"Post"}
                          selectList={[
                            { value: "Post" },
                            { value: "Get" },
                            { value: "Update" },
                            { value: "Delete" },
                            { value: "Put" },
                          ]}
                          custom_label_name="value"
                          customer_value_name="value"
                          // onChange={(event) => handleTemplate(event)}
                          control="select_custom_options3"
                        />
                      </div>
                      <div className="col-sm-5 p-0 m-0 ">
                        <FormControl
                          className="form-control  rounded-left  ml-2"
                          // label={Translation(translations, `${"Tag"}`)}
                          name="request_url"
                          control="input6"
                          placeholder={"https://www.facebook.com/xxxx"}
                        />
                      </div>
                      {/* <div className="col-sm-2  p-0 m-0">
                        <button className="EndAnothebtn">Send Test</button>
                      </div> */}
                    </div>
                    <div className="col-sm-4">
                      <FormControl
                        className="form-control "
                        label={Translation(translations, "Content-Type")}
                        name="content_type"
                        firstSelect={"---select---"}
                        selectList={[
                          { value: "text/plain" },
                          { value: "application/json" },
                          { value: "application/xml" },
                          { value: "multipart/form-data" },
                        ]}
                        custom_label_name="value"
                        customer_value_name="value"
                        // onChange={(event) => handleTemplate(event)}
                        control="select_custom_options2"
                      />
                    </div>
                    <div className="col-sm-12">
                      <p>
                        <b>Headers</b>
                      </p>
                    </div>
                    <div className="col-sm-12">
                      <div className="row p-0 border-left border-right border-bottom">
                        <div className="col-sm-5 p-0">
                          <div className="WebhookFormHeaders rounded-left">
                            <p className="p-0 m-0">Key</p>
                          </div>
                        </div>
                        <div className="col-sm-5 p-0">
                          <div className="WebhookFormHeaders">
                            <p className="p-0 m-0">Value</p>
                          </div>
                        </div>
                        <div className="col-sm-2 p-0">
                          <div className="WebhookFormHeaders rounded-right">
                            <p className="p-0 m-0">Action</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-5">
                            <div className="mt-2">
                              <FormControl
                                className="form-control "
                                // label={Translation(translations, `${"Tag"}`)}
                                name="header_key"
                                control="input"
                                placeholder={"xyz..."}
                              />
                            </div>
                          </div>
                          <div className="col-sm-5">
                            <div className="mt-2">
                              <FormControl
                                className="form-control "
                                // label={Translation(translations, `${"Tag"}`)}
                                name="header_value"
                                control="input"
                                placeholder={"xyz..."}
                              />
                            </div>
                          </div>
                          <div className="col-sm-2"></div>
                        </div>
                      </div>
                      <div>
                        <p className="webhooktext">
                          Headers are optional,but may be required to
                          authenticate the request
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <p>
                        <b>Params</b>
                      </p>
                    </div>
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-5 p-0">
                          <div className="WebhookFormHeaders rounded-left">
                            <p className="p-0 m-0">Key</p>
                          </div>
                        </div>
                        <div className="col-sm-5 p-0">
                          <div className="WebhookFormHeaders">
                            <p className="p-0 m-0">Value</p>
                          </div>
                        </div>
                        <div className="col-sm-2 p-0">
                          <div className="WebhookFormHeaders rounded-right">
                            <p className="p-0 m-0">Action</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 border-left border-right border-bottom">
                      <div className="row mt-2 col-10">
                        {webHook_params.map((val, index) => (
                          <div className="row " key={index}>
                            <div className="col-sm-5">
                              <div className="mt-2">
                                <FormControl
                                  className="form-control "
                                  // label={Translation(translations, `${"Tag"}`)}
                                  name="facebook"
                                  control="input"
                                  placeholder={"https://www.facebook.com/xxxx"}
                                  value={val.key}
                                  onChange={(e) =>
                                    handle_params_keyChange(
                                      val,
                                      index,
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-sm-5">
                              <div className="mt-2">
                                <FormControl
                                  className="form-control "
                                  // label={Translation(translations, `${"Tag"}`)}
                                  name="facebook"
                                  control="input"
                                  placeholder={"https://www.facebook.com/xxxx"}
                                  value={val.value}
                                  onChange={(e) =>
                                    handle_params_valueChange(
                                      val,
                                      index,
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            </div>
                            {index !== 0 && (
                              <span className="input-group-append col-2 my-2">
                                <button
                                  className="btn btn-info flb"
                                  type="button"
                                  onClick={() => handleDelete(val, index)}
                                >
                                  <i className="fa fa-trash"></i>{" "}
                                </button>
                              </span>
                            )}
                          </div>
                        ))}
                        <div className="col-sm-2"></div>

                        <div className="col-sm-2"></div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-success mt-2 col-2"
                      onClick={handle_add_webHook_params}
                    >
                      Add more
                    </button>

                    <div className="col-sm-12">
                      <span className="webhooktext">
                        params are the body of the request which will be read by
                        the receiver.The key is definition of the data while the
                        Value is the value.Use replacement codes to populate the
                        values fields
                      </span>
                    </div>
                    <Modal.Footer>
                      <div className="d-flex justify-content-between w-100 mt-3 align-items-baseline">
                        <div>
                          <button
                            className="triggerbtn-back"
                            onClick={ActionModalCanelHandle}
                          >
                            Back
                          </button>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="btn btn-success px-4"
                          >
                            Add Start
                          </button>
                        </div>
                      </div>
                    </Modal.Footer>
                  </div>
                </Form>
              </Formik>
            )}
            {ActionPerformMath && (
              <Formik
                initialValues={InitialPerformMathAction}
                onSubmit={(values) => {
                  handle_PerfomMath(values);
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className="row">
                      <div className="col-12 row">
                        <div className="col-6">
                          <p className="m-0 fw-bold">Module</p>
                          <FormControl
                            className="form-control my-1"
                            firstSelect={"--select--"}
                            // label={Translation(translations, "Field")}
                            name="module_math"
                            selectList={DropDownDatas}
                            custom_label_name="Module"
                            customer_value_name="Module"
                            onChange={(event) => {
                              values.module_math = event.target.value;
                              setFieldValue(values);
                              handleFieldChangeValue2(event.target.value);
                            }}
                            // value={fieldChangeModule}
                            control="select_custom_options"
                          />
                        </div>
                        <div className="col-6">
                          <p className="m-0 fw-bold">Field</p>
                          <FormControl
                            className="form-control my-1"
                            firstSelect={"--select--"}
                            // label={Translation(translations, "Field")}
                            name="field_math"
                            selectList={fieldChangeField_list_math}
                            custom_label_name="label"
                            customer_value_name="label"
                            // onChange={(event) => handleTemplate(event)}
                            control="select_custom_options"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p className="m-0 fw-bold">Operation</p>
                          <FormControl
                            className="form-control my-1"
                            firstSelect={"--select--"}
                            // label={Translation(translations, "Field")}
                            name="math_operation"
                            selectList={math_operation_list}
                            custom_label_name="label"
                            customer_value_name="label"
                            // onChange={(event) => handleTemplate(event)}
                            control="select_custom_options"
                          />
                        </div>
                        <div className="col-6">
                          <p className="m-0 fw-bold">Value</p>
                          <FormControl
                            className="form-control my-1"
                            type={"number"}
                            firstSelect={"--select--"}
                            // label={Translation(translations, "Field")}
                            name="value_number"
                            // onChange={(event) => handleTemplate(event)}
                            control="input"
                          />
                        </div>
                      </div>

                      <Modal.Footer>
                        <div className="d-flex justify-content-between w-100 mt-3 align-items-baseline">
                          <div>
                            <button
                              className="triggerbtn-back"
                              onClick={ActionModalCanelHandle}
                            >
                              Back
                            </button>
                          </div>
                          <div>
                            <button
                              type="submit"
                              className="btn btn-success px-4"
                            >
                              Add Start
                            </button>
                          </div>
                        </div>
                      </Modal.Footer>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      )}
      {/* {edit action modal} */}
      {edit_action_data && (
        <Modal
          show={state.add_tag}
          onHide={() => {
            dispatch({ key: "add_tag", value: false });
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Formik
            initialValues={InitialSameModal}
            onSubmit={(value) => {
              handle_add_contact_tag_edit(
                value,
                dispatch,
                edit_action_data?.actionDetails?.module_name,
                edit_action_data.bgColor
              );
              //   setshow1(false);
            }}
          >
            <Form name="myForm">
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <h5 className="modal-title">
                    {edit_action_data?.actionDetails?.module_name} add tags
                  </h5>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-sm-4">
                    {/* <FormControl
                    className="form-control "
                    firstSelect={"--Select--"}
                    // label={Translation(translations, "Runs")}
                    name="sign_temp"
                    selectList={DropDownDatas}
                    custom_label_name="Module"
                    customer_value_name="id"
                    // onChange={(event) => handleTemplate(event)}
                    control="select_custom_options"
                  /> */}
                  </div>
                  <div className="col-sm-12 mt-2">
                    <FormControl
                      className="form-control"
                      // required={true}
                      defaultValue={InitialSameModal.tag_to_add_contact}
                      label={"Tag to be Added"}
                      name="tag_to_add_contact"
                      control="input"
                      // placeholder={Translation(translations, "First Name")}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-content-end g-2  w-100 mt-3">
                  <div>
                    <button
                      onClick={() => {
                        dispatch({ key: edit_action_data.type, value: false });
                      }}
                      className="triggerbtn-back"
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="ms-2">
                    <button type="submit" className="btn btn-success px-4">
                      Save
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </Form>
          </Formik>
        </Modal>
      )}
      {edit_action_data && (
        <Modal
          show={state.remove_tag}
          onHide={() => {
            dispatch({ key: "remove_tag", value: false });
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Formik
            initialValues={InitialSameModal2}
            onSubmit={(value) => {
              handle_remove_contact_tag_edit(
                value,
                dispatch,
                edit_action_data?.actionDetails?.module_name,
                edit_action_data.bgColor
              );
              //   setshow1(false);
            }}
          >
            <Form name="myForm">
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <h5 className="modal-title">
                    {edit_action_data?.actionDetails?.module_name} remove tag
                  </h5>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-sm-4"></div>
                  <div className="col-sm-12 mt-2">
                    <FormControl
                      className="form-control"
                      // required={true}
                      defaultValue={InitialSameModal2.tag_to_add_contact}
                      label={"Tag to be Removed"}
                      name="tag_to_add_contact"
                      control="input"
                      // placeholder={Translation(translations, "First Name")}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-content-end g-2  w-100 mt-3">
                  <div>
                    <button
                      onClick={() => {
                        dispatch({ key: "remove_tag", value: false });
                      }}
                      className="triggerbtn-back"
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>

                  <div className="ms-2">
                    <button type="submit" className="btn btn-success px-4">
                      Save
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </Form>
          </Formik>
        </Modal>
      )}
      {edit_action_data && (
        <Modal
          show={state.add_note}
          onHide={() => {
            dispatch({ key: "add_note", value: false });
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h5 className="modal-title">
                {edit_action_data?.actionDetails?.module_name} add note
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CKEditor
              editor={ClassicEditor}
              data={content}
              onChange={handleEditorChange}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-end g-2  w-100 mt-3">
              <div>
                <button
                  onClick={() => {
                    dispatch({ key: "add_note", value: false });
                  }}
                  className="triggerbtn-back"
                  type="button"
                >
                  Cancel
                </button>
              </div>

              <div className="ms-2">
                <button
                  type="button"
                  onClick={() => {
                    handle_addNote_module_edit(
                      content,
                      edit_action_data?.actionDetails?.module_name,
                      edit_action_data.bgColor
                    );
                  }}
                  className="btn btn-success px-4"
                >
                  Save
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {edit_action_data && (
        <Modal
          show={state.add_event}
          onHide={() => {
            dispatch({ key: "add_event", value: false });
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Formik initialValues={InitialSameModal_add_event_edit}>
            <Form name="myForm">
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <h5 className="modal-title">
                    {edit_action_data?.actionDetails?.module_name} add event
                  </h5>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div
                    className=""
                    style={{
                      display: "grid",
                      gridTemplateColumns: "2fr 2fr 1fr 1fr 0.5fr ",
                      gap: "0.6rem",
                    }}
                  >
                    <div className="">
                      <div className="dropdown">
                        <DatePicker
                          allowClear={false}
                          format="YYYY-MM-DD HH:mm"
                          showTime={{ format: "HH:mm" }}
                          onOk={onOk}
                          value={dateValue}
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </div>
                    </div>

                    <div className="">
                      <div className="form-group">
                        <input
                          type="text"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          className="form-control"
                          name="example-text-input"
                          placeholder="What do you need to get done?"
                        />
                      </div>
                    </div>

                    <div className="">
                      <div className="form-group multiselect_div">
                        <select
                          value={pipelines}
                          onChange={(e) => setPipelines(e.target.value)}
                          id="single-selection"
                          name="single_selection"
                          className="multiselect multiselect-custom form-control"
                        >
                          {Array.isArray(Actionlist) ? (
                            Actionlist.map((item) => {
                              return (
                                <option key={item.db_id} value={item.db_id}>
                                  {item?.pipeline_title}
                                </option>
                              );
                            })
                          ) : (
                            <option></option>
                          )}
                        </select>
                      </div>
                    </div>

                    <div className="">
                      <div className="form-group multiselect_div">
                        <select
                          value={priority}
                          onChange={(e) => setPriority(e.target.value)}
                          id="single-selection"
                          name="single_selection"
                          className="multiselect multiselect-custom form-control"
                        >
                          {Array.isArray(priority_list) &&
                          priority_list.length ? (
                            priority_list.map((item) => {
                              return (
                                <option
                                  key={item.priority_id}
                                  value={item.priority_id}
                                >
                                  {item?.priority_label}
                                </option>
                              );
                            })
                          ) : (
                            <option></option>
                          )}
                        </select>
                      </div>
                    </div>

                    <div className="text-center mt-1">
                      <img
                        onClick={handleAssignModalOpen}
                        className="avatar"
                        src={
                          assigntoImg
                            ? assigntoImg.includes("http")
                              ? assigntoImg
                              : `${config.baseurl2}${assigntoImg}`
                            : "https://www.gravatar.com/avatar/b39d3037b9a666e9944ac081e76e3e28?s=160"
                        }
                      />
                    </div>

                    {/* <div className="">
                    <button
                      type="button"
                      className="btn btn-icon btn-primary btn-success"
                      onClick={() => {
                        handleActionSubmit();
                      }}
                    >
                      <i className="fe fe-plus"></i>
                    </button>
                  </div> */}
                  </div>

                  <Modal show={showEdit2} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Assign Action</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="col-md-12 mt-3">
                        <label className="form-label">
                          {Translation(translations, "Lead Assign to")}
                        </label>
                        <div ref={ownerRef} className="searchDropDown">
                          <input
                            type="text"
                            className="form-control"
                            ref={inputElement}
                            name="contact_owner"
                            value={searchval}
                            onChange={(e) => setSearchval(e.target.value)}
                          />
                          <button
                            className="nav-link clickButton"
                            type="button"
                            id="dropdownMenuButton"
                            onClick={() => handleList()}
                          >
                            <FaSearch />
                          </button>
                        </div>
                        <div
                          className={`dropDownCustom ${listOpen && "active"}`}
                        >
                          {resowner.data && (
                            <ul className="list gfdgd">
                              {resowner.isLoading ? (
                                ""
                              ) : !resowner.data.message ? (
                                resowner.data.map((item, index) => {
                                  return (
                                    <li
                                      key={index}
                                      onClick={() => handleClick(item)}
                                    >
                                      {" "}
                                      {Translation(
                                        translations,
                                        `${item.uname} (${item.role_name})`
                                      )}
                                    </li>
                                  );
                                })
                              ) : (
                                <li>
                                  {Translation(
                                    translations,
                                    `${resowner.data.message}`
                                  )}
                                </li>
                              )}
                            </ul>
                          )}
                        </div>
                      </div>

                      <div className="modal-footer mt-3">
                        <button
                          type="button"
                          onClick={handleClose}
                          className="btn btn-default not_now"
                        >
                          Not Now
                        </button>
                        <button
                          type="button"
                          onClick={handleSubmitAssign}
                          className="btn btn-primary btn-leadassignst"
                        >
                          Assign Action
                        </button>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-content-end g-2  w-100 mt-3">
                  <div>
                    <button
                      onClick={() => {
                        dispatch({ key: "add_event", value: false });
                      }}
                      className="triggerbtn-back"
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>

                  <div className="ms-2">
                    <button
                      type="button"
                      onClick={() => {
                        handleEditEventActionSubmit();
                      }}
                      className="btn btn-success px-4"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </Form>
          </Formik>
        </Modal>
      )}
      {edit_action_data && (
        <Modal
          show={state.update_field}
          onHide={() => {
            dispatch({ key: "update_field", value: false });
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Formik
            initialValues={InitialSameModal_update_field}
            onSubmit={(value) => {
              handle_updateField_module_edit(
                value,
                edit_action_data?.actionDetails?.module_name,
                edit_action_data.bgColor
              );
              // handle_submit(value,module_name,backgroundColor)
              //   setshow1(false);
            }}
          >
            {({ values, setFieldValue }) => (
              <Form name="myForm">
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    <h5 className="modal-title">
                      {edit_action_data?.actionDetails?.module_name} update
                      field
                    </h5>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="row">
                      {edit_action_data?.actionDetails?.module_name ===
                        "contact" && (
                        <div className="col-6">
                          <FormControl
                            className="form-control my-1"
                            firstSelect={"--select--"}
                            label={Translation(translations, "Module")}
                            name="field_change_module"
                            selectList={[
                              { Module: "Lead" },
                              { Module: "Prospect" },
                              { Module: "Client" },
                            ]}
                            custom_label_name="Module"
                            customer_value_name="Module"
                            onChange={(event) => {
                              values.field_module = event.target.value;
                              setFieldValue(values);
                              handleFieldChangeValue_update_field_edit(
                                event.target.value
                              );
                            }}
                            value={values.field_module}
                            //   value={fieldChangeModule}
                            control="select_custom_options"
                          />
                        </div>
                      )}
                      <div className="col-6 py-2">
                        <b className="mb-2">Fields</b>
                        <div className="mt-3">
                          <Select
                            showSearch
                            onSearch={(v) => {}}
                            onChange={(v1, v2) => {
                              console.log(v1, v2);
                              values.field_name = v1;
                              if (
                                v2.type === "datetime" ||
                                v2.type === "date" ||
                                v2.type === "timestamp" ||
                                v2.type === "time"
                              ) {
                                values.field_type = "date";
                              } else {
                                values.field_type = v2.type;
                              }

                              //   v2.children;
                              // handleFieldNameChange(v1,index,v2)
                              setFieldValue(values);
                            }}
                            value={values.field_name}
                            style={{ width: "100%", height: 40 }}
                            placeholder={"Search calendar name"}
                          >
                            {/* Array.isArray(addFollower) && datas.lead_data && 
                            addFollower.filter(ite => (datas?.parent_ids?.includes(ite?.id) === false)) */}
                            {fieldChangeField_list_update_field_edit.length &&
                              fieldChangeField_list_update_field_edit.map(
                                ({ label, type }) => (
                                  <Select.Option
                                    value={label}
                                    key={label}
                                    type={type}
                                  >
                                    {label}
                                  </Select.Option>
                                )
                              )}
                          </Select>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 mt-2">
                      {values.field_type === "date" ? (
                        <div>
                          <b>New Date</b>
                          <input
                            defaultValue={values.updated_field_value}
                            name="updated_field_value"
                            type={"date"}
                            className="form-control col-6 mt-2"
                          />
                        </div>
                      ) : (
                        <FormControl
                          className="form-control"
                          // required={true}
                          label={"New Content"}
                          name="updated_field_value"
                          control="input"
                          defaultValue={values.updated_field_value}
                          // placeholder={Translation(translations, "First Name")}
                        />
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex justify-content-end g-2  w-100 mt-3">
                    <div>
                      <button
                        onClick={() => {
                          dispatch({ key: "update_field", value: false });
                        }}
                        className="triggerbtn-back"
                        type="button"
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="ms-2">
                      <button type="submit" className="btn btn-success px-4">
                        Save
                      </button>
                    </div>
                  </div>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
      {edit_action_data && (
        <Modal
          show={state.if_else}
          onHide={() => {
            dispatch({ key: "if_else", value: false });
            setIf_ELSE_Array([
              {
                id: 1,
                module_name: "",
                field_name: null,
                condition_value: null,
                SearchInput: "",
                select: "AND",
                between: false,
              },
            ]);
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Formik
            initialValues={InitialIf_ElseAction}
            onSubmit={Handle_if_elseSubmit_edit}
          >
            <Form>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <h5 className="modal-title">
                    How would you like to split this automation ?
                  </h5>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <Automation_segments
                    DropDownDatas={DropDownDatas}
                    setSegment_field_change={setIf_ELSE_Array}
                    segment_field_change={If_ELSE_Array}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-content-between w-100 mt-3 align-items-baseline">
                  <div>
                    <button
                      className="triggerbtn-back"
                      onClick={ActionModalCanelHandle}
                    >
                      Back
                    </button>
                  </div>
                  <div>
                    <button type="submit" className="btn btn-success px-4">
                      Add Start
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </Form>
          </Formik>
        </Modal>
      )}
      {edit_action_data && (
        <Modal
          show={state.add_automation}
          onHide={() => {
            dispatch({ key: "add_automation", value: false });
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Formik
            initialValues={InitialSameModal_add_automation_edit}
            onSubmit={(values) => {
              handle_add_other_automation_edit(values, "add");
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    <h5 className="modal-title">
                      Edit or Select an automation to enter
                    </h5>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group row">
                        <b className="col-sm-4 col-form-label">
                          {Translation(
                            translations,
                            "Search automation to add"
                          )}
                        </b>
                        <div className="col-sm-12">
                          <Select
                            showSearch
                            mode="multiple"
                            // fieldNames={"add_automation_select"}
                            onSearch={(v) => {}}
                            onChange={(v1, v2) => {
                              values.add_automation_select = v1;
                              values.add_automation_select_list = v2;
                              setFieldValue(values);
                            }}
                            defaultValue={
                              InitialSameModal_add_automation_edit.add_automation_select
                            }
                            style={{ width: "100%", height: 40 }}
                            placeholder={"Search calendar name"}
                          >
                            {/* Array.isArray(addFollower) && datas.lead_data && 
                        addFollower.filter(ite => (datas?.parent_ids?.includes(ite?.id) === false)) */}
                            {allAutomation_data.length &&
                              allAutomation_data.map(({ name, id }) => (
                                <Select.Option value={id} key={id} type={name}>
                                  {name}
                                </Select.Option>
                              ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <Modal.Footer>
                      <div className="d-flex justify-content-between w-100 mt-3 align-items-baseline">
                        <div>
                          <button
                            className="triggerbtn-back"
                            onClick={ActionModalCanelHandle}
                          >
                            Back
                          </button>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="btn btn-success px-4"
                          >
                            Add Start
                          </button>
                        </div>
                      </div>
                    </Modal.Footer>
                  </div>
                </Modal.Body>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
      {edit_action_data && (
        <Modal
          show={state.end_another_automation}
          onHide={() => {
            dispatch({ key: "add_automation", value: false });
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Formik
            initialValues={InitialSameModal_add_automation_edit}
            onSubmit={(values) => {
              handle_add_other_automation_edit(values, "end_another");
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    <h5 className="modal-title">
                      Edit or Select an automation to End
                    </h5>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group row">
                        <b className="col-sm-4 col-form-label">
                          {Translation(
                            translations,
                            "Search automation to add"
                          )}
                        </b>
                        <div className="col-sm-12">
                          <Select
                            showSearch
                            mode="multiple"
                            // fieldNames={"add_automation_select"}
                            onSearch={(v) => {}}
                            onChange={(v1, v2) => {
                              values.add_automation_select = v1;
                              values.add_automation_select_list = v2;
                              setFieldValue(values);
                            }}
                            defaultValue={
                              InitialSameModal_add_automation_edit.add_automation_select
                            }
                            style={{ width: "100%", height: 40 }}
                            placeholder={"Search calendar name"}
                          >
                            {/* Array.isArray(addFollower) && datas.lead_data && 
                     addFollower.filter(ite => (datas?.parent_ids?.includes(ite?.id) === false)) */}
                            {allAutomation_data.length &&
                              allAutomation_data.map(({ name, id }) => (
                                <Select.Option value={id} key={id} type={name}>
                                  {name}
                                </Select.Option>
                              ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <Modal.Footer>
                      <div className="d-flex justify-content-between w-100 mt-3 align-items-baseline">
                        <div>
                          <button
                            className="triggerbtn-back"
                            onClick={ActionModalCanelHandle}
                          >
                            Back
                          </button>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="btn btn-success px-4"
                          >
                            Add Start
                          </button>
                        </div>
                      </div>
                    </Modal.Footer>
                  </div>
                </Modal.Body>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
      {edit_action_data && (
        <Modal
          show={state.math_operation_automation}
          onHide={() => {
            dispatch({ key: "math_operation_automation", value: false });
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Formik
            initialValues={InitialSameModal_math_operation_automation}
            onSubmit={(values) => {
              handle_PerfomMath_edit(values);
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    <h5 className="modal-title">Math operation</h5>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-12 row">
                      <div className="col-6">
                        <p className="m-0 fw-bold">Module</p>
                        <FormControl
                          className="form-control my-1"
                          firstSelect={"--select--"}
                          // label={Translation(translations, "Field")}
                          name="module_math"
                          selectList={DropDownDatas}
                          defaultValue={
                            InitialSameModal_math_operation_automation.module_math
                          }
                          custom_label_name="Module"
                          customer_value_name="Module"
                          onChange={(event) => {
                            values.module_math = event.target.value;
                            setFieldValue(values);
                            handleFieldChangeValue2(event.target.value);
                          }}
                          // value={fieldChangeModule}
                          control="select_custom_options"
                        />
                      </div>
                      <div className="col-6">
                        <p className="m-0 fw-bold">Field</p>
                        <FormControl
                          className="form-control my-1"
                          firstSelect={"--select--"}
                          // label={Translation(translations, "Field")}
                          name="field_math"
                          selectList={fieldChangeField_list_math}
                          defaultValue={
                            InitialSameModal_math_operation_automation.field_math
                          }
                          custom_label_name="label"
                          customer_value_name="label"
                          // onChange={(event) => handleTemplate(event)}
                          control="select_custom_options"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p className="m-0 fw-bold">Operation</p>
                        <FormControl
                          className="form-control my-1"
                          firstSelect={"--select--"}
                          // label={Translation(translations, "Field")}
                          defaultValue={
                            InitialSameModal_math_operation_automation.math_operation
                          }
                          name="math_operation"
                          selectList={math_operation_list}
                          custom_label_name="label"
                          customer_value_name="label"
                          // onChange={(event) => handleTemplate(event)}
                          control="select_custom_options"
                        />
                      </div>
                      <div className="col-6">
                        <p className="m-0 fw-bold">Value</p>
                        <FormControl
                          className="form-control my-1"
                          type={"number"}
                          firstSelect={"--select--"}
                          defaultValue={
                            InitialSameModal_math_operation_automation.value_number
                          }
                          // label={Translation(translations, "Field")}
                          name="value_number"
                          // onChange={(event) => handleTemplate(event)}
                          control="input"
                        />
                      </div>
                    </div>

                    <Modal.Footer>
                      <div className="d-flex justify-content-between w-100 mt-3 align-items-baseline">
                        <div>
                          <button
                            type="submit"
                            className="btn btn-success px-4"
                          >
                            Add Start
                          </button>
                        </div>
                      </div>
                    </Modal.Footer>
                  </div>
                </Modal.Body>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
      {edit_action_data && (
        <Modal
          show={state.wait_for}
          onHide={() => {
            dispatch({ key: "wait_for", value: false });
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Formik
            initialValues={InitialSameModal_wait_for}
            onSubmit={Handle_ActionWaitSubmit_edit}
          >
            <Form name="myForm">
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <h5 className="modal-title">Add a wait Condition</h5>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-sm-12">
                    <label className="custom-control custom-radio custom-control-inline">
                      <Field
                        className="custom-control-input"
                        name="radioOption1"
                        type="radio"
                        required={true}
                        value="wait_1"
                      />
                      <span className="custom-control-label">
                        Wait for a specified period of time
                      </span>
                    </label>
                  </div>
                  <div className="col-sm-12">
                    <label className="custom-control custom-radio custom-control-inline">
                      <Field
                        className="custom-control-input"
                        name="radioOption1"
                        type="radio"
                        value="wait_2"
                        required={true}
                      />
                      <span className="custom-control-label">
                        Wait unit specific conditions are met
                      </span>
                    </label>
                  </div>
                  {WaitRadio1 && (
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="row w-100 justify-content-evenly">
                          <div className="col-md-4">
                            <FormControl
                              className="form-control "
                              // label={Translation(translations, "Runs")}
                              name="wait_for"
                              selectList={[
                                { label: "wait for", value: "wait for" },
                              ]}
                              required={true}
                              value={"wait_for"}
                              custom_label_name="label"
                              customer_value_name="value"
                              // onChange={(event) => handleTemplate(event)}
                              control="select_custom_options"
                            />
                          </div>
                          <div className="col-md-6 p-0 m-0 d-flex align-items-center">
                            <FormControl
                              className="form-control "
                              name="wait_time_count"
                              type="number"
                              control="input"
                              placeholder={"Value"}
                              defaultValue={
                                InitialSameModal_wait_for.wait_time_count
                              }
                              required={true}
                            />
                          </div>
                          <div className="col-md-2">
                            <FormControl
                              className="form-control "
                              firstSelect={"--Day(s)--"}
                              required={true}
                              // label={Translation(translations, "Runs")}
                              name="wait_time_days"
                              selectList={[
                                { label: "days", value: "days" },
                                { label: "hour", value: "hour" },
                                { label: "minutes", value: "minutes" },
                              ]}
                              defaultValue={
                                InitialSameModal_wait_for.wait_time_days
                              }
                              custom_label_name="label"
                              customer_value_name="value"
                              // onChange={(event) => handleTemplate(event)}
                              control="select_custom_options"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {WaitRadio2 && (
                    <div className="row">
                      <FormControl
                        className="form-control "
                        firstSelect={"--No time limit--"}
                        label={Translation(translations, "The Limit")}
                        name="time_limit"
                        selectList={[
                          { label: "No time limit", value: "No time limit" },
                        ]}
                        defaultValue={InitialSameModal_wait_for.time_limit}
                        custom_label_name="label"
                        customer_value_name="value"
                        // onChange={(event) => handleTemplate(event)}
                        control="select_custom_options"
                      />
                    </div>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-content-between w-100 mt-3 align-items-baseline">
                  <div>
                    <button
                      className="triggerbtn-back"
                      onClick={ActionModalCanelHandle}
                    >
                      Back
                    </button>
                  </div>
                  <div>
                    <button type="submit" className="btn btn-success px-4">
                      Add Start
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </Form>
          </Formik>
        </Modal>
      )}
      {/* {edit action modal} */}
    </>
  );
}

export default Automation;
