import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaCalendarAlt, FaClock } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import config from "../../services/config.js";
import dayjs from 'dayjs';
import { MainAuthPermissionsContext } from "../../context/MainAuthPermissionsContext";
import usePost from '../../customHooks/usePost';
import { MainCalenderIdContext } from '../../context/MainCalenderIdContext';
import { getTokenSession } from '../../utils/common';
import axios from 'axios';
import GoogleSyn_event_modal from '../GoogleSyn_event_modal';
import swal from 'sweetalert';
import { RRule, rrulestr } from 'rrule'

function EditEventModal({ view, name, setDeleteResponse, range, editshow, removeEditModal, rangeTime, dateall, ID, type }) {
  const { permissions } = useContext(MainAuthPermissionsContext);
  const { addCalendarID } = useContext(MainCalenderIdContext);
  const { id2 } = rangeTime || { id2: '00' };
  const [show, setShow] = useState(false);
  const [deleteEvent, apiMethodDeleteEvent] = usePost();
  const [sync_user_modal, setsync_user_modal] = useState(false)
  const [calender, setcalender] = useState("")
  const [RRuletext, setRRuleText] = useState(false)
  const [this_option, setthis_option] = useState("this_event")




  // Test





  const handleDeleteEvent = () => {


    if (rangeTime.all_day_recurrence_type === 'do_not_repeat' && !rangeTime.recurring_type) {
      let formData = new FormData();
      formData.append('id', rangeTime.deleteId)
      formData.append('child_id', rangeTime.id2)
      formData.append('event_calendar_id', rangeTime.calendarId)
      formData.append('option', 'all_event')
      swal({
        title: "Are you sure, you want to delete?",
        icon: "warning",
        dangerMode: true,
        buttons: ["Cancel", "OK"],
      }).then((willDelete) => {
        if (willDelete) {
          apiMethodDeleteEvent('postDeleteInstance', formData)
          setcalender(rangeTime.calendarId)
        }
      });
    }
    else {
      handleShow()
    }
  }
  const handleDelete = (values) => {
    let formData = new FormData();
    formData.append('id', rangeTime.deleteId)
    formData.append('child_id', rangeTime.id2)
    formData.append('event_calendar_id', rangeTime.calendarId)
    formData.append('option', this_option)
    swal({
      title: "Are you sure, you want to delete?",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "OK"],
    }).then((willDelete) => {
      if (willDelete) {
        apiMethodDeleteEvent('postDeleteInstance', formData)
        setcalender(rangeTime.calendarId)
        handleClose()

      }
    });

  }
  // <----------- handle google sync ----------->

  const handleGoogleSync = () => {
    localStorage.setItem(`GoogleSync`, calender);
    localStorage.setItem("SyncRedirectPath", window.location.pathname)

    axios.defaults.headers = {
      "Content-Type": "multipart/form-data",
      authentication: `${getTokenSession()}`,
    };
    axios.get(`${config.apiEndPoint}getGoogleSync/id=${calender}&type=calendar`)
      .then((response) => {
        window.location.href = `${response.data}`;
      })
      .catch((err) => {
        console.log('eerr', err)
      })
  }

  // <----------- handle google sync ----------->
  useEffect(() => {
    if (deleteEvent?.data) {
      if (deleteEvent.data?.sync_users) {
        setsync_user_modal(true)

      } else {

        setDeleteResponse(deleteEvent?.data)
        // console.log(deleteEvent?.data?.message)
        handleClose()
        removeEditModal()
      }
    }
  }, [deleteEvent.data])

  const handleClose = () => setShow(false);
  const handleShow = (event) => {
    setShow(true)
  };

  const initialValues = {
    option: 'this_event'
  }

  useEffect(() => {
    if (rangeTime) {
      localStorage.setItem("parentId", rangeTime.parent_id ?? "00")
      localStorage.setItem("ChildId", rangeTime.id2 ?? "00")
      addCalendarID({
        parentId: rangeTime.parent_id ?? "00",
        ChildId: id2 ?? "00"
      })

      if (rangeTime.custom_rrule) {
        try {
          const rrule = rrulestr(rangeTime.custom_rrule)
          const rule = new RRule(rrule.origOptions)
          const text_rrule = rule.toText()
          setRRuleText(text_rrule)

        } catch (error) {

          console.log(error);
        }
      }
    }
  }, [rangeTime])
  const handleOptionChange = (event) => {
    setthis_option(event.target.value);
  };

  return (

    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header d-flex justify-content-between">
          <div className="left">
            <h5 className="modal-title" id="askmodalTitle">
              Edit recurring event
            </h5>
          </div>
        </div>

        <div className="modal-body">
          <div className="row">
            <div className="col-lg-12" id="this_only_rdBtn">
              <label className="custom-control custom-radio mgtf">
                <input
                  type="radio"
                  name="option"
                  value="this_event"
                  checked={this_option === 'this_event'}
                  onChange={handleOptionChange}
                />
                <div className="custom-control-label">This event</div>
              </label>
            </div>
            <div className="col-lg-12" id="this_and_following_rdBtn">
              <label className="custom-control custom-radio mgtf">
                <input
                  type="radio"
                  name="option"
                  value="this_and_following"
                  checked={this_option === 'this_and_following'}
                  onChange={handleOptionChange}
                />
                <div className="custom-control-label">This and following events</div>
              </label>
            </div>
            <div className="col-lg-12" id="all_event_rdBtn">
              <label className="custom-control custom-radio mgtf">
                <input type="radio" name="option" value="all_event"
                  onChange={handleOptionChange}
                  checked={this_option === 'all_event'}
                />
                <div className="custom-control-label">All events</div>
              </label>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button onClick={handleClose} type="button" className="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="submit" onClick={() => handleDelete()} className="btn btn-info askRadio_okay">
            Okay
          </button>
        </div>
      </Modal>
      <Modal show={editshow} onHide={removeEditModal}>

        <Modal.Header>
          <Modal.Title>{name}</Modal.Title>
          <div className="right">
            {!view ? <>
              <Link to={type === "meeting" ? `/${config.ddemoss}meeting/edit/${ID}` : `/${config.ddemoss}calendar/editevent/${ID}`} className="mbtn editmodal">
                <i className="fa-solid fa-pen-to-square"></i>
              </Link>
              <button onClick={handleDeleteEvent} type="button" className="mbtn editdelete" id="editdelete">
                <i className="fa-solid fa-trash"></i>
              </button>
              <button type="button" onClick={removeEditModal} className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa-solid fa-xmark"></i>
              </button>

            </> : <><Link to={type === "meeting" ? `/${config.ddemoss}meeting/edit/${ID}` : `/${config.ddemoss}view/${type}/${ID}`} className="mbtn editmodal">
              <i class="fa fa-eye"></i>
            </Link></>}
          </div>
        </Modal.Header>
        {
          range &&
          <Modal.Body>
            {(deleteEvent.isLoading || deleteEvent.isLoading) &&
              (<span className="span_loader">
                <i className="fa fa-pulse fa-spinner"></i>
              </span>)

            }
            <div className="row">

              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="">Event Title</label>
                  <input className="form-control etit" readOnly={true} value={name} placeholder="Calendar Name" type="text" />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="">Event Type</label>
                  <input className="form-control etit" readOnly={true} value={type} placeholder="Event Type" type="text" />
                </div>
              </div>
            </div>
            <div className="poptime mb-2 mt-2  col-12">
              <div className="poptimeBox d-flex align-items-center mt-3 gap-2">
                <div className="poptimeBox__img">
                  <FaClock />
                </div>
                <div>
                  <div className="poptimeBox__txt d-flex gap-1">
                    <div className="d-flex gap-1">

                      <span className="startdate mr-3 popbefore">
                        {dayjs(range.start).format('DD-MM-YYYY')}
                      </span>
                      <span>
                        {rangeTime.start_time && dayjs(
                          rangeTime.start_time, "HH:mm:ss").format('HH:mm:A')}
                      </span>
                    </div>
                    <div className="d-flex gap-1">
                      {
                        rangeTime?.all_day_recurrence_type !== "daily" &&
                        <>
                          ||
                          {
                            <span className="enddate mr-3 popbefore">
                              {(range.end != "Invalid Date") && (range.end_event_date != "" && range.end_event_date != undefined) ? range.end_event_date : dayjs(range.end).format('DD-MM-YYYY')}
                            </span>}
                        </>
                      }
                      {
                        rangeTime.end_time &&
                        <span>
                          {dayjs(
                            rangeTime.end_time, "HH:mm:ss").format('HH:mm:A')}
                        </span>
                      }
                    </div>

                  </div>
                  <div className='poptime_recurence_text ml-1' >{rangeTime?.custom_rrule && RRuletext ? `(${RRuletext ?? ""})` : "(Do not repeat)"}</div>

                </div>

              </div>

            </div>

            <div className="poptime col-12">
              <div className="poptimeBox d-flex align-items-center gap-2">
                <div className="poptimeBox__img">
                  <FaCalendarAlt />
                </div>
                <div className="poptimeBox__txt pr-4">
                  <div className="d-flex">
                    <span className="mr-4 popbefore" style={{ textTransform: 'capitalize' }}>{rangeTime?.privacy}</span>
                    <span className="mr-4 popbefore" style={{ textTransform: 'capitalize' }}>{rangeTime?.visibility}</span>
                    <div style={{ width: '100%' }}>
                      {Array.isArray(rangeTime?.notify) && rangeTime?.notify?.map(item => {
                        return (
                          <div style={{ textTransform: 'capitalize' }} key={item?.notify_db_id}>
                            {`Notify ${item?.event_notification_interval}  ${item?.event_notification_period} Before ${item?.event_notification_type !== "notification" ? ` as ${item?.event_notification_type}` : ""}`}
                          </div>
                        )
                      })}
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <hr />
          </Modal.Body>
        }
        <Modal.Footer>

          <Button variant="primary" onClick={removeEditModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {sync_user_modal && <GoogleSyn_event_modal handleGoogleSync={handleGoogleSync} setsync_user_modal={setsync_user_modal} sync_user_modal={sync_user_modal} data={deleteEvent?.data} />}

    </>

  )
}

export default EditEventModal