import React, { useState } from "react";
export const MainDefaultCalender = React.createContext();

const MainDefaultCalenderProvider = ({ children }) => {
  const [eventDefaultCalender, seteventDefaultCalender] = useState("");
  const addeventDefaultCalender = (newValue) => {
    seteventDefaultCalender(newValue);
  };
  return (
    <MainDefaultCalender.Provider value={{ eventDefaultCalender, addeventDefaultCalender }}>
      {children}
    </MainDefaultCalender.Provider>
  );
};

export default MainDefaultCalenderProvider;
