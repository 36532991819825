import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
  Component,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import usePost from "../customHooks/usePost";
import { MainHeadingContext } from "../context/MainHeadingContext";
import useWebSocket from "react-use-websocket";
import { Form, Formik } from "formik";
import { useParams } from "react-router-dom";
import { Modal, Col, Row } from "react-bootstrap";
import { MainAuthPermissionsContext } from "../context/MainAuthPermissionsContext";
import { FaSave } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import { Translation } from "../components/Translation";

import { FaBars, FaRegCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import Tag from "./Tag";
import Attendees from "./Attendees";
import axios from "axios";
import config from "../services/config.js";
import swal from "sweetalert";
import useFetch from "../customHooks/useFetch";
import SidebarDynamic from "./SidebardynamicField";
import moment from "moment";
import { handleFullScreen } from "../components/AllCustomFuntion";
import Dropdown from "react-bootstrap/Dropdown";
import { getTokenSession } from "../utils/common";
import { FaListOl } from "react-icons/fa";
import { MainTranslationContexts } from "../context/MainTranslationContexts";
import Media_image_display from "../Lead/Media_image_display";
import ActionCard from "../components/common/ActionCard";
import { MainLeadPermissionContext } from "../context/MainLeadPermissionContext";
import EditLeadAssetEditModal from "../Lead/EditLeadAssetEditModal";
import { Monthss } from "../components/Month";
import { MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from "mdb-react-ui-kit";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function ViewMeeting() {
  const { leadPermission } = useContext(MainLeadPermissionContext);
    const [justifyActive2, setJustifyActive2] = useState("tab20");
    const [viewAgenda, setViewagenda] = useState("");
  const { addHeading } = useContext(MainHeadingContext);
  const [notes, setNotes] = useState("");
  const [meeting, setMeeting] = useState("");
  const [attendences, setAttendence] = useState("");
  const [allData, setAllData] = useState("");
  const [decNotes, setdecNotes] = useState("");
  const [timeline, setTimeline] = useState("");
  const [overview, setOverView] = useState("");
  const [timelineShow, setTimelineShow] = useState(false);
  const { id } = useParams();
  const timeZone2 = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let timeZone3 = timeZone2.split("/");
  const {
    data: allMeetingData,
    loading33,
    error33,
  } = useFetch(
    { setAllData },
    `getEditViewMeeting/id=${id}&timezone=${timeZone3[0]}-${timeZone3[1]}`
  );
  const [tagVal, setTagVal] = useState("");
  const [tagCretVal, setCreTval] = useState("");
  const [restagPostSend, apiMethodtagPostSend] = usePost();
  const [res_postDragAndDropAgenda, apiMethod_postDragAndDropAgenda] =
    usePost();
  const [res_postDragAndDropAgenda2, apiMethod_postDragAndDropAgenda2] =
    usePost();
  const [agendaValue, setAgendaValue] = useState("");
  const [image, setImage] = useState("");
  const [editorvalue, seteditorvalue] = useState("");
  const [viewAgenda2, setViewagenda2] = useState("");
  const [voted, setVoted] = useState("");
  const [notVoted, setnotVoted] = useState("");
  const { permissions } = useContext(MainAuthPermissionsContext);
  const [states, setStates] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [stateSuggested, seTsuggested] = useState("");
  const [Members, setMembers] = useState("");
  const [showState, setShowState] = useState("");
  const [meetingCreateiD, setMeetingCreateId] = useState("");
  const [Fileitem_list, setFileitem_list] = useState();
  const [uploadImage, apiMethodUploadImage] = usePost();
  const [userTime, setUserTime] = useState();
  const [time, setTime] = useState(0);
  const [res_postCreateMeetingMedia, apiMethodpostCreateMeetingMedia] =
    usePost();
  const [res_postCreateMeetingFiles, apiMethodpostCreateMeetingFiles] =
    usePost();
  const [resDeleteAsset, apiMethodDeleteAsset] = usePost();

  // updated states for modal
  const navigate = useNavigate();
  const [titelinput, setTitelinput] = useState("");
  const [subinput, setSubinput] = useState("");
  const [subsubinput, setsubSubinput] = useState("");
  const [rescreate, apiMethodcreate] = usePost();
  const [resUpdate, apiMethodUpdate] = usePost();
  const { translations } = useContext(MainTranslationContexts);
  const [timelineShow2, setTimelineShow2] = useState("1");
  const [editorvalue2, seteditorvalue2] = useState("");
  const [file, setFile] = useState(null);
  const [resDeleteagenda, apiMethodDeleteAgenda] = usePost();
  const [EditModalData, setEditModalData] = useState();
  const [Typeof, setTypeof] = useState();
  const [Mediaitem_list, setMediaitem_list] = useState();
  const [Media_Rerender, setMedia_Rerender] = useState(false);
  const [Action_Pipelines, setAction_Pipelines] = useState([]);
  const [Action_list, setAction_list] = useState([]);
  const [pipeline_id, setpipeline_id] = useState();
  const [Auth_user, setAuth_user] = useState();
  // ....................................................................
  const {
    data: category_select_list1Media,
    loading11,
    error11,
  } = useFetch("", "getViewCategory");

  axios.defaults.headers = {
    "Content-Type": "multipart/form-data",
    authentication: `${getTokenSession()}`,
  };

  useEffect(() => {
    if (allMeetingData?.meeting)
      addHeading(`Meeting - ${allMeetingData?.meeting?.event_title}`);
  });

  const transmitMessage = () => {
    let tagdata = new FormData();
    tagdata.append("meeting_id", id);
    tagdata.append("tags", tagCretVal);
    tagdata.append("uMeet1", "typeTag");
    tagdata.append("typeTag", "typeNoteuTag");
    apiMethodtagPostSend("postCreateTagsMeetings", tagdata);
  };
  // const { sendJsonMessage, getWebSocket } = useWebSocket(
  //   "wss://dev.thesalesjourney.io/websocket",
  //   {
  //     onOpen: () => {
        
  //       console.log("WebSocket connection opened.")},
  //     onClose: () =>{
  //       // let data = {
  //       //   section: "user_active_status",
  //       //   user_attendee:permissions?.id,
  //       //   meeting_id: id,
  //       //   pipeline_id:pipeline_id,
  //       //   presence:"false"
  //       // };
  //       // sendJsonMessage(data);
  //       console.log("WebSocket connection closed.")},
  //     shouldReconnect: (closeEvent) => true,
  //     onMessage: (event) => handlePost(event.data),
  //   }
  // );

  const sendJsonMessage = (message) => {
    console.log(message)
  }
  
  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
        let data = {
          section: "Start Timer",
          user_id: "1",
          meeting_id: id,
          data: formatTime(time),
        };
        sendJsonMessage(data);
      }, 1000);
    } else if (!isActive && time !== 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, time]);

  const formatTime = (time) => {
    const pad = (n) => (n < 10 ? "0" + n : n);
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time - hours * 3600) / 60);
    const seconds = time % 60;
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  };

  useEffect(() => {
    if (allMeetingData) {
      if (allMeetingData.message) {
        navigate(`/`);
      }
    }
  }, [allMeetingData]);
  function handlePost(event) {
    var object = JSON.parse(event);
    console.log("websocket Response", object?.section);
    if (object.section == "tags" && object.meeting_id == id) {
      setTagVal(object?.data);
      timeLine();
    } else if (object.section == "Agenda" && object.meeting_id == id) {
      setViewagenda(object?.data);
      timeLine();
    } else if (object.section == "sugst_topc" && object.meeting_id == id) {
      setViewagenda2(object?.data);
      timeLine();
    } else if (object.section == "Meeting Media" && object.meeting_id == id) {
      setMedia_Rerender(!Media_Rerender);
      timeLine();
    } else if (
      object.section == "Meeting_Media_edit" &&
      object.meeting_id == id
    ) {
      setMedia_Rerender(!Media_Rerender);
      timeLine();
    } else if (object.section == "Meeting_Files" && object.meeting_id == id) {
      setMedia_Rerender(!Media_Rerender);
      timeLine();
    } else if (
      object.section == "Meeting_Files_edit" &&
      object.meeting_id == id
    ) {
      setMedia_Rerender(!Media_Rerender);
      timeLine();
    } else if (
      object.section == "Meeting_Files_delete" &&
      object.meeting_id == id
    ) {
      setMedia_Rerender(!Media_Rerender);
      timeLine();
    } else if (object.section == "Actions" && object.meeting_id == id) {
      setAction_list(object.data);
      timeLine();
    } else if (object.section == "Notes" && object.meeting_id == id) {
      // setNotes(data=>[...data,...object.data]);
      axios
        .get(
          `${config.apiEndPoint}getEditViewMeeting/id=${id}&timezone=${timeZone3[0]}-${timeZone3[1]}`
        )
        .then((response) => {
          setNotes(response.data.notes);
          // sendJsonMessage(data);
        });
      timeLine();
    } else if (object.section == "Followup" && object.meeting_id == id) {
      setnotVoted(object.data);
      timeLine();
    } else if (object.section == "Description" && object.meeting_id == id) {
      setdecNotes(object.data);
      timeLine();
    } else if (object.section == "Decisions" && object.meeting_id == id) {
      setVoted(object.data);
      timeLine();
    } else if (object.section == "Attendence" && object.meeting_id == id) {
      const array1 = object?.data?.all_attendees;
      const array2 = object?.data?.member;
      if (Array.isArray(array1) && Array.isArray(array2)) {
        const merragedata = array1.concat(array2);
        setAttendence(merragedata);
      } else if (Array.isArray(array2)) {
        setAttendence(array2);
      } else {
        setAttendence(array1);
      }
      timeLine();
      HandleAuth();
    } else if (object.section === "Start Timer" && object.meeting_id === id) {
      setUserTime(object.timer);
    } else if (object.section === "Stop Timer" && object.meeting_id === id) {
      toast.error("Meeting Stop");
    } else if (
      object.section === "Start Agenda Timer" &&
      object.meeting_id === id
    ) {
      // console.log("data",object.data);
   
        
        if(!object.is_suggested){  
          
          

        if (
        object.first_index !== undefined &&
        object.second_index !== undefined &&
        object.third_index !== undefined
      ) {
        let copy_array = [...viewAgenda];

        if (
          copy_array[object.first_index] &&
          copy_array[object.first_index].sub_items &&
          copy_array[object.first_index].sub_items[
            object.second_index
          ] &&
          copy_array[object.first_index].sub_items[
            object.second_index
          ].sub_items
        ) {
          const targetObject =
            copy_array[object.first_index].sub_items[
              object.second_index
            ].sub_items[object.third_index];

          if (targetObject) {
            let obj = { item_timer: object.timer };
            Object.assign(targetObject, obj);
       
          }
        }
        setViewagenda(copy_array);
      } else if (
        object.first_index !== undefined &&
        object.second_index !== undefined
      ) {
        let copy_array = [...viewAgenda];
        if (
          copy_array[object.first_index] &&
          copy_array[object.first_index].sub_items &&
          copy_array[object.first_index].sub_items[
            object.second_index
          ]
        ) {
          const targetObject =
            copy_array[object.first_index].sub_items[
              object.second_index
            ];

          if (targetObject) {
            let obj = { item_timer: object.timer };
            Object.assign(targetObject, obj);
           
          }
        }
        setViewagenda(copy_array);

       
      } else if (object.first_index !== undefined) {
        let copy_array = [...viewAgenda];
        if (copy_array[object.first_index]) {
          const targetObject = copy_array[object.first_index];

          if (targetObject) {
            let obj = { item_timer: object.timer };
            Object.assign(targetObject, obj);

          }
        }
        setViewagenda(copy_array);

     
      }
    }else{
      if (
        object.first_index !== undefined &&
        object.second_index !== undefined &&
        object.third_index !== undefined
      ) {
        let copy_array = [...viewAgenda2];

        if (
          copy_array[object.first_index] &&
          copy_array[object.first_index].sub_items &&
          copy_array[object.first_index].sub_items[
            object.second_index
          ] &&
          copy_array[object.first_index].sub_items[
            object.second_index
          ].sub_items
        ) {
          const targetObject =
            copy_array[object.first_index].sub_items[
              object.second_index
            ].sub_items[object.third_index];

          if (targetObject) {
            let obj = { item_timer: object.timer };
            Object.assign(targetObject, obj);
       
          }
        }
        setViewagenda2(copy_array);
      } else if (
        object.first_index !== undefined &&
        object.second_index !== undefined
      ) {
        let copy_array = [...viewAgenda2];
        if (
          copy_array[object.first_index] &&
          copy_array[object.first_index].sub_items &&
          copy_array[object.first_index].sub_items[
            object.second_index
          ]
        ) {
          const targetObject =
            copy_array[object.first_index].sub_items[
              object.second_index
            ];

          if (targetObject) {
            let obj = { item_timer: object.timer };
            Object.assign(targetObject, obj);
           
          }
        }
        setViewagenda2(copy_array);

      
      } else if (object.first_index !== undefined) {
        let copy_array = [...viewAgenda2];
        if (copy_array[object.first_index]) {
          const targetObject = copy_array[object.first_index];

          if (targetObject) {
            let obj = { item_timer: object.timer };
            Object.assign(targetObject, obj);

          }
        }
        setViewagenda2(copy_array);

       
      }
      }
   
    }else if(object.section === "user_active_status" && object.meeting_id === id){
      // axios
      // .get(`${config.apiEndPoint}getAllAttendeesByMeeting/${id}`)
      // .then((response) => {
      //   let data = {
      //     section: "Attendence",
      //     user_id: "1",
      //     meeting_id: id,
      //     data: response?.data,
      //   };
      //   sendJsonMessage(data);
      // })
      const array1 = object?.all_attendees;
      const array2 = object?.member;
    if (Array.isArray(array1) && Array.isArray(array2)) {
      const merragedata = array1.concat(array2);
      setAttendence(merragedata);
    } else if (Array.isArray(array2)) {
      setAttendence(array2);
    } else {
      setAttendence(array1);
    }
      console.log(object,"<atttrennnddeeeedsadadsada >")

    }else if(object.section === "event_deleted" && object.meeting_id === id){
      window.history.back()
          }
  }
  window.addEventListener("popstate", function (event) {
    let data = {
      section: "user_active_status",
      user_attendee:permissions?.id,
      meeting_id: id,
      pipeline_id:pipeline_id,
      presence:"false"
    };
    sendJsonMessage(data);
    console.log("WebSocket connection closed.")
  });
  // window.addEventListener("unload", function (event) {
  //   let data = {
  //     section: "user_active_status",
  //     user_attendee:permissions?.id,
  //     meeting_id: id,
  //     pipeline_id:pipeline_id,
  //     presence:"false"
  //   };
  //   sendJsonMessage(data);
  //   console.log("WebSocket connection closed.")
  // });
  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "multipart/form-data",
      authentication: `${getTokenSession()}`,
    };
    axios
      .get(
        `${config.apiEndPoint}getEditViewMeeting/id=${id}&timezone=${timeZone3[0]}-${timeZone3[1]}`
      )
      .then((response) => {
        setMediaitem_list(response?.data?.mediaData);
      })
      .catch((err) => {
        console.log("eerr", err);
      });
  }, [Media_Rerender]);

  useEffect(() => {
    if (restagPostSend.data && !restagPostSend.isLoading) {
      axios.defaults.headers = {
        "Content-Type": "multipart/form-data",
        authentication: `${getTokenSession()}`,
      };
      axios
        .get(
          `${config.apiEndPoint}getEditViewMeeting/id=${id}&timezone=${timeZone3[0]}-${timeZone3[1]}`
        )
        .then((response) => {
          let data = {
            section: "tags",
            user_id: "1",
            meeting_id: id,
            data: response?.data?.all_tags,
          };
          setMediaitem_list(response?.data?.mediaData);
          sendJsonMessage(data);
          setTagVal(response?.data?.all_tags);
        })
        .catch((err) => {
          console.log("eerr", err);
        });
    }
  }, [restagPostSend.data, Media_Rerender]);
  useEffect(() => {
    if (
      res_postDragAndDropAgenda.data &&
      !res_postDragAndDropAgenda.data.message
    ) {
      let data = {
        section: "Agenda",
        user_id: "1",
        meeting_id: id,
        data: res_postDragAndDropAgenda?.data,
      };
      sendJsonMessage(data);
    }
  }, [res_postDragAndDropAgenda.data]);
  useEffect(() => {
    if (
      res_postDragAndDropAgenda2.data &&
      !res_postDragAndDropAgenda2.data.message
    ) {
      let data = {
        section: "sugst_topc",
        user_id: "1",
        meeting_id: id,
        data: res_postDragAndDropAgenda2?.data,
      };
      sendJsonMessage(data);
    }
  }, [res_postDragAndDropAgenda2.data]);

  const handletagDelete = (item) => {
    let tagdeval = new FormData();
    tagdeval.append("meeting_id", id);
    tagdeval.append("mode", "meeting_rem");
    tagdeval.append("tags", item);
    apiMethodtagPostSend("postDeletedTagsMeetings", tagdeval);
  };
  const handleToggle = (e) => {
    e.preventDefault();
    let closestCard = e.target.closest(".card");
    if (closestCard.classList.contains("card-collapsed")) {
      closestCard.classList.remove("card-collapsed");
    } else {
      closestCard.classList.add("card-collapsed");
    }
  };

  useEffect(() => {
    if (allMeetingData) {
      setAllData(allMeetingData);
      setViewagenda(allMeetingData?.meeting_agenda);
      setViewagenda2(allMeetingData?.meeting_agenda_topics);
      setNotes(allMeetingData?.notes);
      setdecNotes(allMeetingData?.description);
      setTagVal(allMeetingData?.all_tags);
      setnotVoted(allMeetingData?.not_voted_followups);
      setVoted(allMeetingData?.voted_followups);
      setOverView(allMeetingData?.overview);
      setTimeline(allMeetingData?.timeLine);
      setpipeline_id(allMeetingData?.meeting?.event_pipeline
        );
      let data = {
        section: "user_active_status",
        user_attendee:permissions?.id,
        meeting_id: id,
        pipeline_id:allMeetingData?.meeting?.event_pipeline,
        presence:"true"
      };
      sendJsonMessage(data);
      const array1 = allMeetingData?.all_attendees;
      const array2 = allMeetingData?.members;
      if (Array.isArray(array1) && Array.isArray(array2)) {
        const merragedata = array1.concat(array2);
        setAttendence(merragedata);
      } else if (Array.isArray(array2)) {
        setAttendence(array2);
      } else {
        setAttendence(array1);
      }
      setMembers(allMeetingData?.members);
      setMeetingCreateId(allMeetingData?.createdUserID);
      setMediaitem_list(allMeetingData?.mediaData);
      setFileitem_list(allMeetingData?.filesData);
      setAction_Pipelines(allMeetingData);
      setAction_list(allMeetingData.actionEventsData);
      setUserTime(
        allMeetingData?.meeting_timer?.message
          ? `00:00:00`
          : allMeetingData?.meeting_timer[0]?.total_time
      );
    }
  }, [allMeetingData]);

  useEffect(() => {
    if (Auth_user) {
      if (Auth_user.message) {
        navigate(`/`);
      }
    }
  }, [Auth_user]);
  useEffect(() => {
    if (meeting) {
      axios
        .get(`${config.apiEndPoint}getviewDecisions/${id}`)
        .then((response) => {
          let data = {
            section: "Decisions",
            user_id: "1",
            meeting_id: id,
            data: response?.data,
          };
          sendJsonMessage(data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [meeting]);

  useEffect(() => {
    if (showState) {
      axios
        .get(`${config.apiEndPoint}getAllAttendeesByMeeting/${id}`)
        .then((response) => {
          let data = {
            section: "Attendence",
            user_id: "1",
            meeting_id: id,
            data: response?.data,
          };
          sendJsonMessage(data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [showState]);

  const handleJustifyClick2 = (value) => {
    if (value == justifyActive2) {
        return;
    }
    setJustifyActive2(value);
};
  const HandleAuth = () => {
    axios
      .get(
        `${config.apiEndPoint}getEditViewMeeting/id=${id}&timezone=${timeZone3[0]}-${timeZone3[1]}`
      )
      .then((response) => {
        setAuth_user(response.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const timeLine = () => {
    axios
      .get(`${config.apiEndPoint}getTimelineMeeting/${id}`)
      .then((response) => {
        setTimeline(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const listItems = Array(10)
    .fill(0)
    .map((_, i) => i);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const [items, setItems] = useState(listItems);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const updatedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(updatedItems);
  };

  let imageFileExtensions = [
    ".png",
    ".jpeg",
    ".jpg",
    ".gif",
    ".bmp",
    ".tiff",
    ".tif",
    ".svg",
    ".raw",
    ".mp4",
    ".mov",
    ".avi",
    ".mkv",
  ];

  function getFileExtension(filename) {
    return "." + filename.split(".").pop();
  }

  useEffect(() => {
    if (Array.isArray(category_select_list1Media)) {
      let data = category_select_list1Media.map((val) => {
        return {
          value: val.cat_id,
          label: val.cat_name,
        };
      });
    }
  }, [category_select_list1Media]);

  //  updated code for modal ...........................................................................................

  useEffect(() => {
    if (rescreate.data) {
      if (Typeof === "agenda") {
        setStates(rescreate.data);
      }
    }
  }, [rescreate.data]);
  useEffect(() => {
    if (rescreate.data) {
      if (Typeof === "sugst_topc") {
        seTsuggested(rescreate.data);
      }
    }
  }, [rescreate.data]);
  useEffect(() => {
    if (resDeleteagenda.data) {
      setStates(resDeleteagenda.data);
    }
  }, [resDeleteagenda.data]);
  function geteditvalues(e) {
    let updatedata = new FormData();
    updatedata.append("item_id", EditModalData.db_id);
    updatedata.append("meeting_id", id);
    updatedata.append("item_user", permissions?.id);
    updatedata.append("item_title", e);
    updatedata.append("uMeet29", "typeAgendaItemTitle");
    updatedata.append("typeAgendaItemTitle", "typeAgendaItemTitle");
    apiMethodcreate("postUpdateTitleAgenda", updatedata);
    setTitelinput(e);
  }
  const handleInputChange2 = (event) => {
    setSubinput(event.target.value);
  };
  const handleInputChange3 = (event) => {
    setsubSubinput(event.target.value);
  };
  function handleFileChange(event) {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  }
  const decUpdate = () => {
    let updatedata = new FormData();
    updatedata.append("item_id", EditModalData.db_id);
    updatedata.append("meeting_id", id);
    updatedata.append("item_user", permissions?.id);
    updatedata.append("uMeet26", "typeAgendaEditDesc");
    updatedata.append("typeAgendaEditDesc", "typeAgendaEditDesc");
    updatedata.append("item_description", editorvalue2);
    apiMethodUpdate("postUpdateDescriptionAgenda", updatedata);
    setShowModal(false);
  };
  const deleteAgendaModal = (item) => {
    swal({
      text: "Are you sure to delete the Agenda?",
      icon: "warning",
      buttons: ["Close", true],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let delData = new FormData();
        delData.append("item_id", item.db_id);
        delData.append("meeting_id", id);
        delData.append("item_user", permissions?.id);
        delData.append("uMeet23", "typeAgendaRem");
        delData.append("typeAgendaRem", "typeAgendaRem");
        apiMethodDeleteAgenda("postDeletedAgenda", delData);
      }
    });
  };

  useEffect(() => {
    if (res_postCreateMeetingMedia?.data) {
      toast.success("Upload Media Successfully");
      setMediaitem_list(res_postCreateMeetingMedia?.data);
      let data = {
        section: "Meeting Media",
        user_id: "1",
        meeting_id: id,
        data: res_postCreateMeetingMedia?.data,
      };
      sendJsonMessage(data);
    }
  }, [res_postCreateMeetingMedia]);

  useEffect(() => {
    if (res_postCreateMeetingFiles?.data) {
      toast.success("Files Created successfully");
      let data = {
        section: "Meeting_Files",
        user_id: "1",
        meeting_id: id,
        data: res_postCreateMeetingFiles?.data,
      };
      sendJsonMessage(data);
    }
  }, [res_postCreateMeetingFiles]);
  useEffect(() => {
    if (resDeleteAsset?.data) {
      let data = {
        section: "Meeting_Files_delete",
        user_id: "1",
        meeting_id: id,
        data: resDeleteAsset?.data,
      };
      sendJsonMessage(data);
    }
  }, [resDeleteAsset]);

  useEffect(() => {
    if (notVoted) {
      console.log(notVoted, "checking ");
    }
  }, [notVoted]);

  // ....................................................................................................end  //////////////////////

  
 

  const updateProperties = (target, source) => {
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        target[key] = source[key];
      }
    }
  };
  const node_repeater_edit = (data, obj) => {
    data.forEach((val) => {
      if (val.sub_items.length && val.id) {
        node_repeater_edit(val.sub_items, obj);
      } else {
        if (val.id) {
          obj.sub_items = [...val.sub_items];
          updateProperties(val, obj);
          console.log(val);
        }
      }
    });
  };

  function TimerComponent({ time_value, data }) {
    const [timerss, settimerss] = useState({
      min: Number(time_value),
      sec: 0,
    });
    const [isRunning, setIsRunning] = useState(false);
    const intervalRef = useRef(null);

    return (
      <div>
        <span>
          {timerss.min}:{timerss.sec}
        </span>
      </div>
    );
  }

  const ListItem = ({ text, index, moveListItem, item }) => {
    return (
      <div className="">
        <div className="relative">
          <ul className="agenda1appendBox list">
            <li className="mb-2 agenda1appendBoxp">
              <div
                style={{
                  backgroundColor: item.play_status === "1" ? "#ff6868" : "",
                }}
                className="agenda1appendBox_ py-2 px-3 d-flex align-item-center justify-content-between"
              >
                <div className="agenda1appendBox__left d-flex align-item-center">
                  <div className="agenda1appendBox_drag ">
                    <FaBars />
                  </div>
                  <div className="agenda1appendBox_i">
                    <FaRegCircle />
                  </div>
                  <div className="agenda1appendBox_txt">
                    <h6 className="m-0">{item.item_title}</h6>
                  </div>
                </div>
                <div className="agenda1appendBox__right">
                  <ul className="list d-flex align-item-center">
                    <li>
                     {item?.item_timer}
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
          {Array.isArray(item.sub_items) &&
            item.sub_items.map((item, index) => (
              <div key={index} className="agenda1appendBoxpsubdiv">
                <ul className="list sortable ui-sortable">
                  <li
                    className="agenda1appendBoxp my-2"
                    data-order="96"
                    data-item="item"
                  >
                    <div
                      style={{
                        backgroundColor:
                          item.play_status === "1" ? "#ff6868" : "",
                      }}
                      className="agenda1appendBox_ py-2 px-3 d-flex align-item-center justify-content-between"
                    >
                      <div className="agenda1appendBox__left d-flex align-item-center">
                        <div className="agenda1appendBox_drag ui-sortable-handle">
                          <i className="fa-solid fa-bars"></i>
                        </div>
                        <div className="agenda1appendBox_i">
                          <i className="fa-regular fa-circle"></i>
                        </div>
                        <div className="agenda1appendBox_txt">
                          <h6 className="m-0">{item.item_title}</h6>
                        </div>
                      </div>
                      <div className="agenda1appendBox__right">
                        <ul className="list d-flex align-item-center">
                          <li>
                            <div className="agenda1appendBox_time">
                            {item?.item_timer}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="agenda1appendBoxpsubdiv  agenda1appendBoxpsubdivZ">
                      <ul className="list sortable ui-sortable"></ul>
                    </div>
                  </li>
                </ul>
                {Array.isArray(item.sub_items) &&
                  item.sub_items.map((item, index) => (
                    <div key={index} className="agenda1appendBoxpsubdiv">
                      <ul className="list sortable">
                        <li
                          className="agenda1appendBoxp my-2"
                          data-order="19"
                          data-item="item"
                        >
                          <div
                            style={{
                              backgroundColor:
                                item.play_status === "1" ? "#ff6868" : "",
                            }}
                            className="agenda1appendBox_ py-2 px-3 d-flex align-item-center justify-content-between"
                          >
                            <div className="agenda1appendBox__left d-flex align-item-center">
                              <div className="agenda1appendBox_drag ui-sortable-handle">
                                <i className="fa-solid fa-bars"></i>
                              </div>
                              <div className="agenda1appendBox_i">
                                <i className="fa-regular fa-circle"></i>
                              </div>
                              <div className="agenda1appendBox_txt">
                                <h6 className="m-0">{item.item_title}</h6>
                              </div>
                            </div>

                            <div className="agenda1appendBox__right">
                              <ul className="list d-flex align-item-center">
                                <li>
                                <div className="agenda1appendBox_time">
                            {item?.item_timer}
                            </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="agenda1appendBoxpsubdiv  agenda1appendBoxpsubdivZ"></div>
                        </li>
                      </ul>
                    </div>
                  ))}
              </div>
            ))}
        </div>
      </div>
    );
  };
  const ListItem2 = ({ text, index, moveListItem, item }) => {
    return (
      <div className="">
        <div className="relative">
          <ul className="agenda1appendBox list">
            <li className="mb-2 agenda1appendBoxp">
              <div className="agenda1appendBox_ py-2 px-3 d-flex align-item-center justify-content-between">
                <div className="agenda1appendBox__left d-flex align-item-center">
                  <div className="agenda1appendBox_drag ">
                    <FaBars />
                  </div>
                  <div className="agenda1appendBox_i">
                    <FaRegCircle />
                  </div>
                  <div className="agenda1appendBox_txt">
                    <h6 className="m-0">{item.item_title}</h6>
                  </div>
                </div>
                <div className="agenda1appendBox__right">
                  <ul className="list d-flex align-item-center">
                    <li>
                    <div className="agenda1appendBox_time">
                            {item?.item_timer}
                            </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
          {Array.isArray(item.sub_items) &&
            item.sub_items.map((item, index) => (
              <div key={index} className="agenda1appendBoxpsubdiv">
                <ul className="list sortable ui-sortable">
                  <li
                    className="agenda1appendBoxp my-2"
                    data-order="96"
                    data-item="item"
                  >
                    <div className="agenda1appendBox_ py-2 px-3 d-flex align-item-center justify-content-between">
                      <div className="agenda1appendBox__left d-flex align-item-center">
                        <div className="agenda1appendBox_drag ui-sortable-handle">
                          <i className="fa-solid fa-bars"></i>
                        </div>
                        <div className="agenda1appendBox_i">
                          <i className="fa-regular fa-circle"></i>
                        </div>
                        <div className="agenda1appendBox_txt">
                          <h6 className="m-0">{item.item_title}</h6>
                        </div>
                      </div>
                      <div className="agenda1appendBox__right">
                        <ul className="list d-flex align-item-center">
                          <li>
                          <div className="agenda1appendBox_time">
                            {item?.item_timer}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="agenda1appendBoxpsubdiv  agenda1appendBoxpsubdivZ">
                      <ul className="list sortable ui-sortable"></ul>
                    </div>
                  </li>
                </ul>
                {Array.isArray(item.sub_items) &&
                  item.sub_items.map((item, index) => (
                    <div key={index} className="agenda1appendBoxpsubdiv">
                      <ul className="list sortable">
                        <li
                          className="agenda1appendBoxp my-2"
                          data-order="19"
                          data-item="item"
                        >
                          <div className="agenda1appendBox_ py-2 px-3 d-flex align-item-center justify-content-between">
                            <div className="agenda1appendBox__left d-flex align-item-center">
                              <div className="agenda1appendBox_drag ui-sortable-handle">
                                <i className="fa-solid fa-bars"></i>
                              </div>
                              <div className="agenda1appendBox_i">
                                <i className="fa-regular fa-circle"></i>
                              </div>
                              <div className="agenda1appendBox_txt">
                                <h6 className="m-0">{item.item_title}</h6>
                              </div>
                            </div>

                            <div className="agenda1appendBox__right">
                              <ul className="list d-flex align-item-center">
                                <li>
                                <div className="agenda1appendBox_time">
                            {item?.item_timer}
                            </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="agenda1appendBoxpsubdiv  agenda1appendBoxpsubdivZ"></div>
                        </li>
                      </ul>
                    </div>
                  ))}
              </div>
            ))}
        </div>
      </div>
    );
  };

  const moveViewAgendaListItem = useCallback(
    (dragIndex, hoverIndex) => {
      if (dragIndex !== hoverIndex) {
        const dragItem = viewAgenda[dragIndex];
        const hoverItem = viewAgenda[hoverIndex];

        // Swap places of dragItem and hoverItem in the viewAgenda array
        setViewagenda((viewAgenda) => {
          const updatedviewAgenda = [...viewAgenda];
          updatedviewAgenda[dragIndex] = hoverItem;
          updatedviewAgenda[hoverIndex] = dragItem;
          return updatedviewAgenda;
        });

        const updatedviewAgenda = [...viewAgenda];
        updatedviewAgenda[dragIndex] = hoverItem;
        updatedviewAgenda[hoverIndex] = dragItem;
        const formdata = new FormData();
        formdata.append("meeting_id", id);
        formdata.append("uMeet21", "typeAgendaSort");
        formdata.append("typeAgendaSort", "typeAgendaSort");
        formdata.append("type", "agenda");
        Array.isArray(updatedviewAgenda) &&
          updatedviewAgenda.map((obj, index) => {
            console.log(`sort_item[${obj.db_id}]`, index + 1);
            formdata.append(`sort_items[${obj.db_id}]`, index + 1);
          });
        apiMethod_postDragAndDropAgenda("postDragAndDropAgenda", formdata);
      }
    },
    [viewAgenda]
  );
  const moveViewAgendaListItem2 = useCallback(
    (dragIndex, hoverIndex) => {
      if (dragIndex !== hoverIndex) {
        const dragItem = viewAgenda2[dragIndex];
        const hoverItem = viewAgenda2[hoverIndex];

        // Swap places of dragItem and hoverItem in the viewAgenda array
        setViewagenda2((viewAgenda2) => {
          const updatedviewAgenda = [...viewAgenda2];
          updatedviewAgenda[dragIndex] = hoverItem;
          updatedviewAgenda[hoverIndex] = dragItem;
          return updatedviewAgenda;
        });

        const updatedviewAgenda = [...viewAgenda2];
        updatedviewAgenda[dragIndex] = hoverItem;
        updatedviewAgenda[hoverIndex] = dragItem;
        const formdata = new FormData();
        formdata.append("meeting_id", id);
        formdata.append("uMeet21", "typeAgendaSort");
        formdata.append("typeAgendaSort", "typeAgendaSort");
        formdata.append("type", "sugst_topc");
        Array.isArray(updatedviewAgenda) &&
          updatedviewAgenda.map((obj, index) => {
            console.log(`sort_item[${obj.db_id}]`, index + 1);
            formdata.append(`sort_items[${obj.db_id}]`, index + 1);
          });
        apiMethod_postDragAndDropAgenda2("postDragAndDropAgenda", formdata);
      }
    },
    [viewAgenda2]
  );

  const deleteAssetFile = (item) => {
    swal({
      text: "Are you sure to delete the File?",
      icon: "warning",
      buttons: ["Close", true],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let field = {
          general: "delete_file",
          fieldId: item.db_file_id,
          filelead: item.file_lead,
          filename: item.file_name,
          module: "event_meeting",
          type: "file",
        };
        apiMethodDeleteAsset("postDeleteLeadAssets", field);
        // const updatedFileitemList = Fileitem_list.filter((its) => its.db_file_id !== item.db_file_id);
        // setFileitem_list(updatedFileitemList);
      }
    });
  };

  return (
    <div className="MeetingPage">
      <div className="container-fluid">
        <Formik>
          <Form name="myform">
            <div className="row row-cards">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <button
                      type="button"
                      className="btn btn-success ml-2 d-flex"
                      id="timer"
                    >
                      Scheduled {userTime}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-collapsed">
              <div className="card-status bg-blue"></div>
              <div className="card-header">
                <h3 className="card-title">Description</h3>
                <div className="card-options">
                  <a
                    onClick={handleToggle}
                    className="card-options-collapse nxs"
                    data-toggle="card-collapse"
                  >
                    <i className="fe fe-chevron-down"></i>
                  </a>
                  <a
                    onClick={handleFullScreen}
                    className="card-options-fullscreen nxs"
                    data-toggle="card-fullscreen"
                  >
                    <i className="fe fe-maximize"></i>
                  </a>
                </div>
              </div>
              <div className="card-body">
                {leadPermission?.super_admin ||
                  (leadPermission?.meeting?.fields?.card_description ===
                    "-1" ||
                    leadPermission?.meeting?.fields?.card_description ===
                      "true") ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: allMeetingData?.description,
                      }}
                    ></span>
                  ):<></>}
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-lg-8 col-md-6 col-sm-12">
                <div className="card box_shadow card-collapsed">
                  <div className="card-status bg-blue"></div>

                  <div className="card-header">
                    <h3 className="card-title">
                      <i className="fa fa-users text-muted"></i> Overview
                      <small>Meeting Details</small>
                    </h3>
                    <div className="card-options">
                      <a
                        onClick={handleToggle}
                        className="card-options-collapse nxs"
                        data-toggle="card-collapse"
                      >
                        <i className="fe fe-chevron-down"></i>
                      </a>
                      <a
                        onClick={handleFullScreen}
                        className="card-options-fullscreen nxs"
                        data-toggle="card-fullscreen"
                      >
                        <i className="fe fe-maximize"></i>
                      </a>
                    </div>
                  </div>
                  <div className="card-body justify-content-center">
                  <div className="card_">
                                        <div className="section-body_">
                                            <div className="container-fuild">
                                                <div className="d-lg-flex justify-content-between">
                                                    <MDBTabs
                                                        justify
                                                        className="py-1 px-4 nav d-flex nav-tabs page-header-tab"
                                                    >
                                                        {allMeetingData && Object.keys(allMeetingData?.all_fields).map((item, index) => {
                                                            return (
                                                                <MDBTabsItem key={index}>
                                                                    <MDBTabsLink
                                                                        onClick={() =>
                                                                            handleJustifyClick2(`tab2${index}`)
                                                                        }
                                                                        active={justifyActive2 === `tab2${index}`}
                                                                    >
                                                                        {item.replaceAll('_', ' ')}
                                                                    </MDBTabsLink>
                                                                </MDBTabsItem>
                                                            );
                                                        })}
                                                    </MDBTabs>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="section-body">
                                            <div className="container-fuild">
                                                <div className="row clearfix">
                                                    <div className="col-md-12">
                                                        {allMeetingData && !allMeetingData?.all_fields?.message ? (
                                                            <div className="innerNav">
                                                                <MDBTabsContent>
                                                                    {Object.keys(allMeetingData?.all_fields).map(function (
                                                                        item,
                                                                        i
                                                                    ) {
                                                                        return (
                                                                            <MDBTabsPane
                                                                                key={i}
                                                                                show={justifyActive2 === `tab2${i}`}
                                                                            >
                                                                                <div className="card">
                                                                                    <div className="card-body">
                                                                                        {Object.keys(
                                                                                            allMeetingData.all_fields[item]
                                                                                        ).map((item1, index) => {

                                                                                            return (
                                                                                                <div key={index}>
                                                                                                    <h3 className="card-title mb-3">
                                                                                                        {Translation(
                                                                                                            translations,
                                                                                                            item1.replace(/_/g, " ")
                                                                                                        )}
                                                                                                    </h3>
                                                                                                    {Object.keys(allMeetingData.all_fields[item][item1]).map((item2, index2) => {
                                                                                                        let labelName = `meeting_pip_${allMeetingData.all_fields[item][item1][item2]?.label.replaceAll(' ', '_')}`
                                                                                                  
                                                                                                        return (
                                                                                                            (leadPermission?.super_admin || leadPermission?.meeting?.fields[labelName] === 'true' || leadPermission?.meeting?.fields[labelName] === '-1') && allMeetingData.all_fields[item][item1][item2].value && (
                                                                                                                <div key={index2} className="col-md-6" >
                                                                                                                    <div className="form-group">
                                                                                                                        <label className="form-label">
                                                                                                                            {Translation(translations, allMeetingData.all_fields[item][item1][item2]?.label)}
                                                                                                                        </label>
                                                                                                                        {Translation(translations, allMeetingData.all_fields[item][item1][item2]?.value
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        );
                                                                                                    })}
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                    </div>
                                                                                </div>
                                                                            </MDBTabsPane>
                                                                        );
                                                                    })}
                                                                </MDBTabsContent>
                                                            </div>
                                                        ) : (
                                                            <Skeleton count={5} />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-status bg-blue"></div>
                  <div className="card-header">
                    <h3 className="card-title">
                      <i className="fa fa-list text-muted"></i> Agenda
                      <small>Meeting Plan</small>
                    </h3>
                    <div className="card-options">
                      <a
                        onClick={handleToggle}
                        className="card-options-collapse nxs"
                        data-toggle="card-collapse"
                      >
                        <i className="fe fe-chevron-down"></i>
                      </a>
                      <a
                        onClick={handleFullScreen}
                        className="card-options-fullscreen nxs"
                        data-toggle="card-fullscreen"
                      >
                        <i className="fe fe-maximize"></i>
                      </a>
                    </div>
                  </div>
                  {leadPermission?.super_admin ||
                    (leadPermission?.meeting?.fields?.card_agenda === "-1" ||
                      leadPermission?.meeting?.fields?.card_agenda ===
                        "true")? (
                      <div className="card-body">
                        <div className="dargex">
                          {Array.isArray(viewAgenda) &&
                            viewAgenda.map((item, index) => (
                              <ListItem
                                key={item.db_id}
                                index={index}
                                text={item.item_title}
                                moveListItem={moveViewAgendaListItem}
                                item={item}
                              />
                            ))}
                        </div>

                        <div>Suggested Topics</div>
                        <div className="dropdown-divider"></div>
                        <div className="row clearfix agenda2append">
                          {" "}
                          <div className="dargex">
                            {Array.isArray(viewAgenda2) &&
                              viewAgenda2.map((item, index) => (
                                <ListItem2
                                  key={item.db_id}
                                  index={index}
                                  text={item.item_title}
                                  moveListItem={moveViewAgendaListItem2}
                                  item={item}
                                />
                              ))}
                          </div>
                        </div>
                      </div>
                    ):<></>}  </div>
                 {leadPermission?.super_admin ||
                    (leadPermission?.meeting?.fields?.card_notes === "-1" ||
                      leadPermission?.meeting?.fields?.card_notes ===
                        "true") ?  <div className="card">
                    <div className="card-status bg-blue"></div>
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="fa fa-sticky-note text-muted"></i> Notes{" "}
                        <small>Notes About the Meeting</small>
                      </h3>
                      <div className="card-options">
                        <a
                          onClick={handleToggle}
                          className="card-options-collapse nxs"
                          data-toggle="card-collapse"
                        >
                          <i className="fe fe-chevron-down"></i>
                        </a>
                        <a
                          onClick={handleFullScreen}
                          className="card-options-fullscreen nxs"
                          data-toggle="card-fullscreen"
                        >
                          <i className="fe fe-maximize"></i>
                        </a>
                      </div>
                    </div>
                    <div className="card-body"></div>
                    <div className="card_meeting_notes" id="card_meeting_notes">
                      <div className="card-body">
                        <div className="meeting_notes">
                          <ul className="recent_comments list-unstyled">
                            {Array.isArray(notes) &&
                              notes.map((item, index) => {
                                //  console.log(new Date(dayjs.tz(item.note_date,permissions["system-user_timezone"]?.setting_value).tz()))
                                const noteDate = moment(item.note_date);
                                const currentDate = moment();
                                const diffInMinutes = currentDate.diff(
                                  noteDate,
                                  "minutes"
                                );
                                const diffInHours = currentDate.diff(
                                  noteDate,
                                  "hours"
                                );
                                const diffInDays = currentDate.diff(
                                  noteDate,
                                  "days"
                                );
                                const diffInMonths = currentDate.diff(
                                  noteDate,
                                  "months"
                                );
                                const diffInYears = currentDate.diff(
                                  noteDate,
                                  "years"
                                );
                                let relativeDate = "";
                                if (diffInMinutes < 60) {
                                  relativeDate = `${diffInMinutes} min ago`;
                                } else if (diffInHours < 24) {
                                  relativeDate = `${diffInHours} hour ago`;
                                } else if (diffInDays < 30) {
                                  relativeDate = `${diffInDays} day ago`;
                                } else if (diffInMonths < 12) {
                                  relativeDate = `${diffInMonths} month ago`;
                                } else {
                                  relativeDate = `${diffInYears} year ago`;
                                }
                                return (
                                  <li key={index}>
                                    <div className="avatar_img">
                                      <img
                                        className="rounded img-fluid"
                                        src={
                                          item?.avatar &&
                                          item?.avatar.includes("http")
                                            ? item?.avatar
                                            : `${config.baseurl2}${item?.avatar} `
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="comment_body">
                                      <h6 className="">
                                        {`${item.f_name} ${item.l_name}`}
                                        {item.note_privacy === "1" && (
                                          <span className="text-left ml-1">
                                            {" "}
                                            <span className="tag tag-danger">
                                              Private Note
                                            </span>{" "}
                                          </span>
                                        )}{" "}
                                        <small
                                          className="float-right"
                                          title={noteDate.format(" ")}
                                        >
                                          {relativeDate}
                                        </small>
                                      </h6>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: item.note_value,
                                        }}
                                      ></p>
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>:<></>}
                 {leadPermission?.super_admin ||
                    (leadPermission?.meeting?.fields?.card_actions === "-1" ||
                      leadPermission?.meeting?.fields?.card_actions ===
                        "true") ? <div className="card">
                    <div className="card-status bg-blue"></div>
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="fa fa-calendar-check-o text-muted"></i>{" "}
                        Actions
                      </h3>
                      <div className="card-options">
                        <a
                          onClick={handleToggle}
                          className="card-options-collapse nxs"
                          data-toggle="card-collapse"
                        >
                          <i className="fe fe-chevron-down"></i>
                        </a>
                        <a
                          onClick={handleFullScreen}
                          className="card-options-fullscreen nxs"
                          data-toggle="card-fullscreen"
                        >
                          <i className="fe fe-maximize"></i>
                        </a>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        {Array.isArray(Action_list) && (
                          <ActionCard
                            lists={Action_list}
                            actionData={Action_Pipelines}
                          />
                        )}
                      </div>
                    </div>
                  </div>:<></>}
                 { leadPermission?.super_admin ||
                    (leadPermission?.meeting?.fields?.card_media === "-1" ||
                      leadPermission?.meeting?.fields?.card_media ===
                        "true") ?<div className="card">
                    <div className="card-status bg-blue"></div>
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="fa fa-film text-muted"></i> Media
                      </h3>
                      <div className="card-options">
                        <a
                          onClick={handleToggle}
                          className="card-options-collapse nxs"
                          data-toggle="card-collapse"
                        >
                          <i className="fe fe-chevron-down"></i>
                        </a>
                        <a
                          onClick={handleFullScreen}
                          className="card-options-fullscreen nxs"
                          data-toggle="card-fullscreen"
                        >
                          <i className="fe fe-maximize"></i>
                        </a>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="card-body p-2">
                        <div className="card-body">
                          <div className="card-body p-2">
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "center",
                              }}
                            >
                              {Array.isArray(Mediaitem_list?.data) &&
                                Mediaitem_list?.data?.map((items, i) => {
                                  return (
                                    <div
                                      style={{ width: 250, margin: 12 }}
                                      key={i}
                                    >
                                      <div className="card card__media p-1 card-custom">
                                        <Media_image_display data={items}>
                                          <img
                                            className="media_image_height"
                                            src={
                                              items?.file_value &&
                                              items?.file_value.includes("http")
                                                ? items?.file_value
                                                : `${config.baseurl2}${items?.file_value} `
                                            }
                                            alt=""
                                          />
                                        </Media_image_display>
                                        <div className="d-flex align-items-center px-2 mt-3">
                                          <img
                                            style={{
                                              width: 50,
                                              height: 50,
                                              borderRadius: 25,
                                              marginRight: "10px",
                                            }}
                                            alt=""
                                            src={
                                              items?.fileOwnerData[0]?.avatar &&
                                              items?.fileOwnerData[0]?.avatar.includes(
                                                "http"
                                              )
                                                ? items?.fileOwnerData[0]
                                                    ?.avatar
                                                : `${config.baseurl2}${items?.fileOwnerData[0]?.avatar} `
                                            }
                                          />
                                          <div>
                                            <div>
                                              {Translation(
                                                translations,
                                                `${
                                                  items?.fileOwnerData[0]
                                                    ?.f_name +
                                                  " " +
                                                  items?.fileOwnerData[0]
                                                    ?.l_name
                                                }`
                                              )}
                                            </div>
                                            <small className="d-block text-muted">
                                              {Translation(
                                                translations,
                                                `${items?.fileCreatedDate}`
                                              )}
                                            </small>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>:<></>}
                  {Array.isArray(notVoted) &&
                    notVoted.map((item, index) => {
                      return (
                        <div className="card" key={index}>
                          <div className="card-status bg-blue"></div>
                          <div className="card-header">
                            <h3 className="card-title">
                              <i className={item.pipeline_icon}></i>
                              {item.pipeline_title}
                            </h3>
                            <div className="card-options">
                              <a
                                onClick={handleToggle}
                                className="card-options-collapse nxs"
                                data-toggle="card-collapse"
                              >
                                <i className="fe fe-chevron-down"></i>
                              </a>
                              <a
                                onClick={handleFullScreen}
                                className="card-options-fullscreen nxs"
                                data-toggle="card-fullscreen"
                              >
                                <i className="fe fe-maximize"></i>
                              </a>
                            </div>
                          </div>
                          <div className="card-body">
                            {leadPermission?.super_admin ||
                    ( leadPermission?.meeting?.fields[`card_${(item?.pipeline_title).replaceAll(" ","_")}`] === "true"||leadPermission?.meeting?.fields[`card_${(item?.pipeline_title).replaceAll(" ","_")}`] === "-1")?<div className="row">
                              {Array.isArray(notVoted) && (
                                <ActionCard
                                  lists={item.not_voted_followup_items}
                                />
                              )}
                            </div>:<></>}
                          </div>
                        </div>
                      );
                    })}
                  <div className="card">
                    <div className="card-status bg-blue"></div>

                    <div className="card-header">
                      <h3 className="card-title">
                        <FaListOl /> Projects (#)
                        <small>Working For Them</small>
                      </h3>

                      <div className="card-options align-item-center">
                        <Link
                          className="card-options-collapse"
                          onClick={(e) => handleToggle(e)}
                          data-toggle="card-collapse"
                        >
                          <i className="fe fe-chevron-down"></i>
                        </Link>
                        <Link
                          className="card-options-fullscreen"
                          onClick={(e) => handleFullScreen(e)}
                          data-toggle="card-fullscreen"
                        >
                          <i className="fe fe-maximize"></i>
                        </Link>
                      </div>
                    </div>

                    <div className="card-body">
                      {Array.isArray(allData?.project) &&
                        allData?.project.map((item, i) => {
                          return (
                            <div
                              key={i}
                              className=" col-lg-12 col-md-12 col-sm-12"
                            >
                              {" "}
                              <div className="c2_own">
                                <ul className="right_chat list-unstyled p-0 right_chat_vl">
                                  <li className="online mb-2">
                                    <Link
                                      to={`/${config.ddemoss}view/project/${item.prj_id}`}
                                    >
                                      <Link className="cc_cls" data-row="12">
                                        <i className="fa-solid fa-xmark"></i>
                                      </Link>
                                      <div className="media">
                                        <img
                                          className="media-object "
                                          src={item.project_feature_image}
                                          alt=""
                                        />
                                        <div className="media-body">
                                          <span className="name">
                                            {item?.project_title}{" "}
                                          </span>
                                          <div className="message">
                                            {item?.name}
                                          </div>
                                          {item.start_date && (
                                            <span className="message">
                                              {item.start_date}
                                            </span>
                                          )}{" "}
                                          <span className="dashsymbol">
                                            {" "}
                                            | - |{" "}
                                          </span>{" "}
                                          {item.end_date && (
                                            <span className="message">
                                              {item.end_date}
                                            </span>
                                          )}
                                          <span className="badge badge-outline status"></span>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                </ul>
                              </div>{" "}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="card box_shadow card-collapsed">
                    <div className="card-status bg-blue"></div>
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="fa fa-user-secrect text-muted"></i> Admin{" "}
                        <small>Admin &amp; Timeline</small>
                      </h3>
                      <div className="card-options">
                        <a
                          onClick={handleToggle}
                          className="card-options-collapse nxs"
                          data-toggle="card-collapse"
                        >
                          <i className="fe fe-chevron-down"></i>
                        </a>
                        <a
                          onClick={handleFullScreen}
                          className="card-options-fullscreen nxs"
                          data-toggle="card-fullscreen"
                        >
                          <i className="fe fe-maximize"></i>
                        </a>
                      </div>
                    </div>
                    <div className="card-body">
                      <ul className="nav nav-tabs page-header-tab">
                        <li className="nav-item">
                          <Link
                            onClick={() => setTimelineShow(false)}
                            className={`nav-link ${
                              timelineShow ? "" : "show active"
                            }`}
                            data-toggle="tab"
                          >
                            Overview
                          </Link>
                        </li>
                        <li
                          onClick={() => setTimelineShow(true)}
                          className="nav-item"
                        >
                          <Link
                            className={`nav-link ${
                              timelineShow ? "show active" : ""
                            }`}
                            data-toggle="tab"
                          >
                            Timeline
                          </Link>
                        </li>
                      </ul>
                      {timelineShow === false ? (
                        <div className="col-md-12">
                          <div className="tab-content">
                            <div
                              className="tab-pane show active"
                              id="admin_overview"
                            >
                              <ul className="list-unstyled">
                                <li>
                                  <div className="row align-items-center">
                                    <div className="col-auto">
                                      <div className="h5 mb-0">
                                        Created Date
                                      </div>
                                      <span className="small text-muted">
                                        {overview?.created_date}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                                <li className="mt-4">
                                  <div className="row align-items-center">
                                    <div className="col-auto">
                                      <div className="h5 mb-0">
                                        Updated Date
                                      </div>
                                      <span className="small text-muted">
                                        {overview?.updated_date}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                                <li className="mt-4">
                                  <div className="row align-items-center">
                                    <div className="col-auto">
                                      <div className="h5 mb-0">
                                        Event Stage Dates{" "}
                                      </div>
                                      <table className="table table-bordered mt-2">
                                        <thead>
                                          <tr>
                                            <th>Stage Name</th>
                                            <th>Assigned On</th>
                                            <th>No of Days </th>
                                          </tr>
                                        </thead>
                                      </table>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="tab-pane" id="lead_timeline">
                          {timeline &&
                            Array.isArray(timeline) &&
                            timeline.map((item, index) => {
                              return (
                                <div key={index} className="timeline_item ">
                                  <div>{item.f_name}</div>
                                  <span>
                                    <a>
                                      {" "}
                                      {item.f_name} {item.l_name}{" "}
                                    </a>{" "}
                                    ({item.email}){" "}
                                    <small className="float-right text-right">
                                      {" "}
                                      {item.activity_date_time}
                                    </small>
                                  </span>
                                  <div className="msg">
                                    <div>
                                      <p
                                        className="mb-0"
                                        dangerouslySetInnerHTML={{
                                          __html: item.activity_value,
                                        }}
                                      ></p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  </div>
                
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Attendees
                  setAttendence={setAttendence}
                  users={attendences}
                  setShowState={setShowState}
                  meetingCreateiD={meetingCreateiD}
                  pipeline_id={pipeline_id}
                  allData={allData}
                  view={true}
                />
                <Tag
                  del={handletagDelete}
                  savMess={() => transmitMessage()}
                  tagdatas={tagVal}
                  tagvl={tagVal}
                  tagvlUpd={setCreTval}
                  view={true}
                />
                <div className="card">
                  <div className="card-status bg-blue"></div>
                  <div className="card-header">
                    <h3 className="card-title">
                      <i className="fa fa-comments text-muted"></i>{" "}
                      Conversations
                    </h3>
                    <div className="card-options">
                      <a
                        onClick={handleToggle}
                        className="card-options-collapse nxs"
                        data-toggle="card-collapse"
                      >
                        <i className="fe fe-chevron-down"></i>
                      </a>
                      <a
                        onClick={handleFullScreen}
                        className="card-options-fullscreen nxs"
                        data-toggle="card-fullscreen"
                      >
                        <i className="fe fe-maximize"></i>
                      </a>
                    </div>
                  </div>
                  <div className="card-body">
                    Web hook to load content in future
                  </div>
                </div>
              {leadPermission?.super_admin ||
                    (leadPermission?.meeting?.fields?.card_files === "-1" ||
                      leadPermission?.meeting?.fields?.card_files ===
                        "true") ?  <div className="card">
                  <div className="card-status bg-blue"></div>
                  <div className="card-header">
                    <h3 className="card-title">
                      <i className="fa fa-folder text-muted"></i> Files
                      <small>Upload file</small>
                    </h3>
                    <div className="card-options align-item-center">
                      <a
                        onClick={handleToggle}
                        className="card-options-collapse nxs"
                        data-toggle="card-collapse"
                      >
                        <i className="fe fe-chevron-down"></i>
                      </a>
                      <a
                        onClick={handleFullScreen}
                        className="card-options-fullscreen nxs"
                        data-toggle="card-fullscreen"
                      >
                        <i className="fe fe-maximize"></i>
                      </a>
                    </div>
                  </div>
                  <div className="card-body">
                    {Array.isArray(Fileitem_list?.data) && (
                      <div className="table-responsive">
                        <table className="table table-hover table-vcenter table_custom text-nowrap spacing5 text-nowrap mb-0">
                          <thead>
                            <tr>
                              <th></th>
                              <th>{Translation(translations, "Name")}</th>
                              <th>{Translation(translations, "Share With")}</th>
                              <th>{Translation(translations, "Owner")}</th>
                              <th>
                                {Translation(translations, "Last Update")}
                              </th>
                              <th>{Translation(translations, "File Size")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(Fileitem_list.data) &&
                              Fileitem_list.data.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="width45">
                                      <i className="fa fa-file-excel-o text-success"></i>
                                    </td>
                                    <td>
                                      <span className="folder-name">
                                        <a
                                          href={item.file_value}
                                          download={
                                            item?.file_value?.includes("http")
                                              ? item?.file_value
                                              : `${config.baseurl2}${item?.file_value}`
                                          }
                                        >
                                          {Translation(
                                            translations,
                                            item.file_name
                                          )}
                                        </a>
                                      </span>
                                    </td>
                                    <td>
                                      {Translation(
                                        translations,
                                        item.file_name
                                      )}
                                    </td>
                                    <td className="width100">
                                      <span>
                                        {Translation(
                                          translations,
                                          `${item.fileOwnerData[0].f_name} ${item.fileOwnerData[0].l_name}`
                                        )}
                                      </span>
                                    </td>
                                    <td className="width100">
                                      <span>
                                        {Monthss(item.file_updated_date)}{" "}
                                        {Translation(translations, "23, 2023")}
                                      </span>
                                    </td>
                                    <td className="width100 text-center">
                                      <span className="size">
                                        {Translation(
                                          translations,
                                          item.file_size
                                        )}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>:<></>}
                {voted &&
                  Object.keys(voted).map((item, index) => {
                    return (
                      <SidebarDynamic
                        key={index}
                        setMeeting={setMeeting}
                        icon={voted[item].pipeline_icon}
                        title={voted[item].pipeline_title}
                        count={voted[item].count_label}
                        item={voted[item]}
                        voted={voted[item].in_votes}
                        notvoted={voted[item].voted_followup_items}
                        view={true}
                      />
                    );
                  })}
              </div>
            </div>
          </Form>
        </Formik>
      </div>
      {showModal && (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <label className="modal-labels my_labels">
                  <label>Agenda Title</label>
                  <input
                    defaultValue={EditModalData.item_title}
                    // value={EditModalData.item_title}
                    type="text"
                    className="form-control agenda1appendinputmin"
                    name="example-text-input"
                    placeholder="What do you wand to discuss?"
                    onChange={(event) => {
                      geteditvalues(event.target.value);
                    }}
                  />
                  <div className="my_tabs">
                    <ul className="nav nav-tabs page-header-tab">
                      <li className="nav-item">
                        <Link
                          onClick={() => setTimelineShow2("1")}
                          className={`nav-link ${
                            timelineShow2 === "1" ? "active" : ""
                          }`}
                          data-toggle="tab"
                        >
                          Description
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => setTimelineShow2("2")}
                          className={`nav-link ${
                            timelineShow2 === "2" ? "active" : ""
                          }`}
                          data-toggle="tab"
                        >
                          Sub Items
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={() => setTimelineShow2("3")}
                          className={`nav-link ${
                            timelineShow2 === "3" ? "active" : ""
                          }`}
                          data-toggle="tab"
                        >
                          Files
                        </Link>
                      </li>
                    </ul>
                  </div>
                </label>{" "}
              </Col>
            </Row>
            <Row>
              {timelineShow2 === "1" && (
                <Col xs={12} md={12} lg={12}>
                  <div>
                    <h6>Description</h6>
                  </div>
                </Col>
              )}
              {timelineShow2 === "2" && (
                <Col>
                  <h6>Sub-items (Agenda)</h6>
                  {EditModalData.sub_items &&
                    Array.isArray(EditModalData.sub_items) &&
                    EditModalData.sub_items.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="tab-pane fadeIn active"
                          id="agenda_sub_items"
                          aria-expanded="false"
                        >
                          <div className="input-group mb-1 _sub_item_agenda_">
                            <input
                              onChange={handleInputChange2}
                              type="text"
                              className="form-control agenda1appendinputtxt"
                              name="example-text-input"
                              defaultValue={item.item_title}
                            />
                          </div>
                          {item.sub_items &&
                            Array.isArray(item.sub_items) &&
                            item.sub_items.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="input-group mb-1 _sub_item_agenda_"
                                >
                                  <input
                                    onChange={handleInputChange3}
                                    type="text"
                                    className="form-control agenda1appendinputtxt sub_item_agenda_ sub_sub_item_agenda_"
                                    name="example-text-input"
                                    defaultValue={item.item_title}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      );
                    })}
                </Col>
              )}

              {timelineShow2 === "3" && (
                <Col>
                  <div>
                    <label htmlFor="file-input">Choose file </label>{" "}
                    <input
                      id="file-input"
                      type="file"
                      onChange={handleFileChange}
                    />
                    {file && <p>Selected file: {file.name}</p>}
                  </div>
                </Col>
              )}
            </Row>
          </Modal.Body>

          {timelineShow2 === "1" && (
            <Modal.Footer>
              <Button onClick={decUpdate} variant="primary">
                {" "}
                <FaSave style={{ fontSize: 16 }} />{" "}
                {Translation(translations, "Update Description")}
              </Button>
            </Modal.Footer>
          )}
          {timelineShow2 === "3" && (
            <Modal.Footer>
              <Button onClick={geteditvalues} variant="primary">
                {" "}
                <FaSave style={{ fontSize: 16 }} />{" "}
                {Translation(translations, "Upload Media ")}
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      )}
    </div>
  );
}

export default ViewMeeting;
