import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import usePost from "../customHooks/usePost";
import { FaUsers, FaSearch } from "react-icons/fa";
import Dropdown from "../components/form/Dropdown";
import Dropdown2 from "react-bootstrap/Dropdown";
import swal from "sweetalert";
import axios from "axios";
import config from "../services/config.js";
import { useParams } from "react-router-dom";
import { handleFullScreen } from "../components/AllCustomFuntion";
import { getTokenSession } from "../utils/common";
import { MainAuthPermissionsContext } from "../context/MainAuthPermissionsContext";
import { set } from "lodash";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Translation } from "../components/Translation";
import { MainTranslationContexts } from "../context/MainTranslationContexts";
import FormControl from "../components/form/FormControl";
import useFetch from "../customHooks/useFetch";
import File3 from "../components/form/File3";
import gravatar from 'gravatar'
import md5 from "md5";
import { toast } from "react-toastify";
import { MainLeadPermissionContext } from "../context/MainLeadPermissionContext";
function Attendees({
  allData,
  users,
  setShowState,
  meetingCreateiD,
  pipeline_id,
  view,
  setAttendence
}) {
  const { id } = useParams();
  const [userList, setUserList] = useState(users);
  useEffect(() => {
    if (users) {
     
      setUserList(users);
    }
  }, [users]);
  const { data: rolessss, loading3, error13 } = useFetch("", "getAttendeesRole");
  
 useEffect(() => {
 if(rolessss) {
  setRoles(rolessss);
 }
 }, [rolessss])
  
  const { permissions } = useContext(MainAuthPermissionsContext);
  const { translations } = useContext(MainTranslationContexts);
  const { leadPermission } = useContext(MainLeadPermissionContext);

  const [resSearch, apiMethodSearch] = usePost();
  const [serchData, setSearchData] = useState("");
  const [resSearch2, apiMethodSearch2] = usePost();
  const [presentAbsent, apiMethodpresentAbsent] = usePost();
  const [attendenceDelete, apiMethoddelete] = usePost();
  const [postSendInvites, apiMethodpostSendInvites] = usePost();
  const [listOpen, setListOpen] = useState(false);
  const [showtagModal, setShowtagModal] = useState("");
  const [searchval, setSearchval] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [invitesModal, setInvitesModal] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [viewSendinvites, setViewSendinvites] = useState("");
  axios.defaults.headers = {
    "Content-Type": "multipart/form-data",
    authentication: `${getTokenSession()}`,
  };
  const handleModalInvites = () => {
    setInvitesModal(true);
  };
  const handleModalInvitesclose = () => {
    setInvitesModal(false);
  };

  useEffect(() => {
    if (presentAbsent.data) {
      setShowState(presentAbsent.data);
    }
  }, [presentAbsent.data]);

  useEffect(() => {
    if (postSendInvites.data) {
      setViewSendinvites(postSendInvites?.data);
      handleModalInvites();
    }
  }, [postSendInvites.data]);
  useEffect(() => {
    if (attendenceDelete.data) {
      setShowState(attendenceDelete.data);
    }
  }, [attendenceDelete.data]);
  useEffect(() => {
    if (resSearch2.data) {
      if (
        resSearch2.data.message ==
        "Attendee is already added in the meeting room!"
      ) {
        swal({
          text: resSearch2.data.message,
          icon: "error",
          buttons: ["Close", true],
          dangerMode: true,
        });
      } else {
        setShowState(resSearch2.data);
        swal({
          text: resSearch2.data.message,
          icon: "success",
          buttons: ["Close", true],
          dangerMode: true,
        });
        setListOpen(false);
      }
    }
  }, [resSearch2]);

  const handleList = (e) => {
    e.stopPropagation();
    let tagdata = new FormData();
    tagdata.append("userType", "typeSearch");
    tagdata.append("query", searchval);
    tagdata.append("getType", "typeSearch1");
    apiMethodSearch("postSpecifiesUsers", tagdata);
    setListOpen(!listOpen);
   
  };
  const handleModalShow = () => {
    setShowtagModal(true);
  };
  const handleModalClose = () => {
    setShowtagModal(false);
    setListOpen(false);
  };
  const hanleAdd = (item) => {
    swal({
      text: "Are you sure to add an attendee in the Meeting?",
      icon: "warning",
      buttons: ["Close", true],
      dangerMode: true,
    }).then(async (Added) => {
      let added = new FormData();
      added.append("attendy_type", selectedRole.value??"");
      added.append("user_id", item.id);
      added.append("pipeline_id", pipeline_id);
      apiMethodSearch2(`postAddAttendee/${id}`, added);
      setListOpen(false);
    });
  };
  const handleSelect = (index, value) => {
    setSelectedRole(value);
  };
  const options = [
  {label:"Owner",value:"owner"},
  {label:"Member",value:"member"},
  {label:"Follower",value:"follower"},
  {label:"Guest",value:"guest"},
]
  const handleToggle = (e) => {
    e.preventDefault();
    let closestCard = e.target.closest(".card");
    if (closestCard.classList.contains("card-collapsed")) {
      closestCard.classList.remove("card-collapsed");
    } else {
      closestCard.classList.add("card-collapsed");
    }
  };
  const toggleStatus = (e, item, index) => {
    const updatedData = [...userList]; // Create a copy of the array

    if (updatedData.length > 0) {
      updatedData[index] = {
        ...updatedData[index],
        meeting_presence: item.meeting_presence === "0" ? "1" : "0",
      }; // Update the name property
      setUserList(updatedData); // Update the state with the updated array
    }
    let tagdata = new FormData();
    tagdata.append("user_attendee", item.contact_id || item.id);
    tagdata.append("meeting_id", id);
    tagdata.append("pipeline_id", pipeline_id);
    tagdata.append("presence", e.target.checked.toString());
    tagdata.append("uMeet6", "typeSrAttSetPr");
    tagdata.append("typeSrAttSetPr", "q1typetypeSrAttSetPrMeetq2");
    apiMethodpresentAbsent("postTypeAttendeesSetPresent", tagdata);
  };
  const handleDelete = (item) => {
    swal({
      text: "Are you sure want to delete?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let deletedata = new FormData();
        deletedata.append("user_id", item.contact_id || item.id);
        deletedata.append("meeting_id", id);
        deletedata.append("pipeline_id", pipeline_id);
        apiMethoddelete(`postRemoveAttendeesByuserId/${id}`, deletedata);
      }
    });
  };
  const sendInvites = () => {
    let formdata = new FormData();
    formdata.append("meeting_id", id);
    users &&
      users.map((item, i) => {
        formdata.append(
          `user_id[${i}]`,
          item?.user_id ? item?.contact_id : item?.id
        );
      });
    apiMethodpostSendInvites(`postSendInvites`, formdata);
  };

  useEffect(() => {
    if (resSearch.data) {
      setSearchData(resSearch.data);
    }
  }, [resSearch.data]);
  
  const [uploadImage, setUploadImage] = useState()
  const [emailse, setEmailse] = useState()

  const [res1, apiMethodres1] = usePost();
  const [phoneNumber, setPhoneNumber] = useState({ number: "" });
  const { data: settingData, loading, error1 } = useFetch("", "getAllViewTypeContact");
  const filteredTypes = settingData?.filter(type => type.type_module === 'Contact');
  // if(loading) return "";
  const options2 = filteredTypes?.map(({ db_id, type_name }) => ({
    value: db_id,
    label: type_name,
  }));
  const [initialValues, setInitialValues] = useState({
    contact_name: "",
    email: "",
    contact_type: "",
    contact_note: "",
    role_id:  "",
  })

  const handleContactModal = () => {
    setContactModal(true);
  };
  const handleContactModalClose = () => {
    setContactModal(false);
  };
  const handleScontact = () => {
    if (emailse) {
      {
        swal({
          title: "Email already used:",
          text: initialValues.email,
          icon: "warning",
          dangerMode: true,
        });
        return false;
      }
    }
    else {
      let b = []
      let a= { 
      contact_name: "Name",
      email: "E-mail ",
      contact_type: "Type of Contact",
      contact_note: "Note",
      role_id: "Role",
    }
      let formData = new FormData();
      for (let item in initialValues) {
        if(initialValues[item] === "") {
          b.push(a[item])
        }
        formData.append(item, initialValues[item]);
      }
      if(phoneNumber.number === "") {
        b.push("Phone Number")
      }
      if(uploadImage === "") {
        b.push("Avatar")
      }
      if(b.length === 0) {
        if (typeof ((uploadImage)) === "object") {
          formData.append("avatar", uploadImage)
          formData.append("avatarURL", "")
        }
        else {
          formData.append("avatar", "")
          formData.append("avatarURL", uploadImage)
        }
        
        formData.append("contact_phone", phoneNumber.number)
        formData.append("pipeline_id", pipeline_id);
        formData.append("meeting_id", id);
        formData.append("create_contact", "create_contact");
        apiMethodres1("CreateNewMeetingAttendeesApi", formData)
      }
      else {
        swal({
          title: "Required Fields are empty! Please fill and try again",
          text: b.join(","),
          icon: "warning",
          dangerMode: true,
        });
        return false;
      }
    
    }

  }
useEffect(() => {
 if(res1.data) {
   setListOpen(false)
   setContactModal(false)
   setInitialValues({
    contact_name: "",
    email: "",
    contact_type: "",
    contact_note: "",
    role_id:  "",
  })
  setUploadImage()
  setPhoneNumber({ number: "" });
  const array1 = res1?.data?.data?.all_attendees;
      const array2 = res1?.data?.data?.member;
      if (Array.isArray(array1) && Array.isArray(array2)) {
        const merragedata = array1.concat(array2)
        setAttendence(merragedata);
      }
      else if (Array.isArray(array2)) {
        setAttendence(array2);
      } else {
        setAttendence(array1);
      }
  // setAttendence([res1?.data.all_attendees]);

  res1.data.message && toast.success(res1.data.message);
 }
}, [res1.data])

  const handleSelect2 = (item) => {
    if(item.label === "--select--") {
      setInitialValues({...initialValues,"role_id":""})

    }
    else {
      setInitialValues({...initialValues,"role_id":item.value})

    }
  }
  const handleEmail = (e) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const gravatarUrl = `https://www.gravatar.com/avatar/${md5(e)}?d=404`;

    if (emailRegex.test(e)) {
      const avatarUrl = gravatar.url(e, { s: '200', r: 'pg', d: 'identicon' }, true);
      fetch(gravatarUrl)
        .then(response => {
          if (response.status === 404) {
            axios.defaults.headers = {
              "Content-Type": "multipart/form-data",
              authentication: `${getTokenSession()}`,
            };
            axios
              .get(`${config.apiEndPoint}getCheckEmailExistLead/${e}`)
              .then((res) => {

                setEmailse(res.data.aleady_exist);
                if (permissions["system-default-avatar-image"]?.setting_value) {
                  setUploadImage(permissions["system-default-avatar-image"]?.setting_value)
                }
                else {
                  setUploadImage("https://www.gravatar.com/avatar/b39d3037b9a666e9944ac081e76e3e28?s=160")
                }
              })
              .catch((err) => {
                console.log("create errr", err);
              });
          } else {
            setUploadImage(`${avatarUrl}`)
          }
        })
        .catch(error => {
          console.error('Error checking Gravatar image:', error);
        });
    }
    else {
      axios.defaults.headers = {
        "Content-Type": "multipart/form-data",
        authentication: `${getTokenSession()}`,
      };
      axios
        .get(`${config.apiEndPoint}getCheckEmailExistLead/${e}`)
        .then((res) => {
          setEmailse(res.data.aleady_exist);
          if (uploadImage === '') {
            if (permissions["system-default-avatar-image"]?.setting_value) {
              setUploadImage(permissions["system-default-avatar-image"]?.setting_value)
            }
            else {
              setUploadImage("https://www.gravatar.com/avatar/b39d3037b9a666e9944ac081e76e3e28?s=160")
            }
          }
        })
        .catch((err) => {
          console.log("create errr", err);
        });
    }

    setInitialValues({...initialValues,"email":e})
  };

  return (
    <div>
      <div className="card">
        <div className="card-status bg-blue"></div>
        <div className="card-header">
          <h3 className="card-title">
            <FaUsers /> Attendees (#)
            <small>Who is in the meeting</small>
          </h3>
          <div className="card-options">
            <a
              onClick={handleToggle}
              className="card-options-collapse nxs"
              data-toggle="card-collapse"
            >
              <i className="fe fe-chevron-down"></i>
            </a>
            <a
              onClick={handleFullScreen}
              className="card-options-fullscreen nxs"
              data-toggle="card-fullscreen"
            >
              <i className="fe fe-maximize"></i>
            </a>
          </div>
        </div>
        <div className="card-body justify-content-center meetingmain">
         {leadPermission?.super_admin ||
                    ( leadPermission?.meeting?.fields ?.card_attendees === "true"||leadPermission?.meeting?.fields ?.card_attendees === "-1")?( <div className="relative my-3">
            {/* {allData && (
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip>
                    {allData?.createdUserData?.f_name ??
                      "" + " " + allData?.createdUserData?.l_name ??
                      ""}
                  </Tooltip>
                }
              >
                <span className="ownerBox">
                  <img
                    src={`${
                      allData.createdUserData.avatar
                        ? allData.createdUserData.avatar.includes("http")
                          ? allData.createdUserData.avatar
                          : `${config.baseurl2}${allData.createdUserData.avatar}`
                        : "https://www.gravatar.com/avatar/b39d3037b9a666e9944ac081e76e3e28?s=160"
                    }`}
                    alt=""
                  />
                  <span
                              className={`avatar-status ownerbox_active
                                `}
                            ></span>
                </span>
              </OverlayTrigger>
            )} */}

            <div className="meetingBox">
              <div id="prmeet">
                <div>
                  <div>
                    {users &&
                      Array.isArray(users) &&
                      users?.map((item, index) => (
                        <OverlayTrigger
                          placement={"top"}
                          overlay={
                            <Tooltip>{item?.username ?? item?.name}</Tooltip>
                          }
                          key={index}
                        >
                          <span
                            key={index}
                            className={`avatar attendee attendee-${index} ${
                              item.meeting_presence === "1"
                                ? "online"
                                : "offline"
                            }`}
                          >
                            <img
                              className="avatar"
                              src={`${
                                item.avatar
                                  ? item.avatar.includes("http")
                                    ? item.avatar
                                    : `${config.baseurl2}${item.avatar}`
                                  : "https://www.gravatar.com/avatar/b39d3037b9a666e9944ac081e76e3e28?s=160"
                              }`}
                              alt=""
                              data-toggle="tooltip"
                            />
                            <span
                              className={`avatar-status ${
                                item.meeting_presence === "1"
                                  ? "bg-green"
                                  : "bg-red1"
                              }`}
                            ></span>
                          </span>
                        </OverlayTrigger>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>):<></>}
       {leadPermission?.super_admin ||
                    ( leadPermission?.meeting?.fields ?.card_attendees === "true")?(  <> {meetingCreateiD == permissions?.id ? (
            <div className="text-center d-flex gap-2 justify-content-center">
              <button
                type="button"
                onClick={() => handleModalShow()}
                className="btn btn-primary managebtn"
              >
                <i className="fe fe-user mr-2"></i>Manage
              </button>
              {view ? (
                ""
              ) : (
              <>
              {postSendInvites.isLoading? <span className="span_loader">
          <i className="fa fa-pulse fa-spinner"></i>
        </span>:<></>}
                <button
                  onClick={sendInvites}
                  type="button"
                  className="btn btn-primary"
                >
                  <i className="fe fe-mail mr-2"></i>Send Invites
                </button>
              </>
              )}
            </div>
          ) : (
            ""
          )}</>):<></>}
        </div>
      </div>
      {showtagModal && (
        <Modal show={showtagModal} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Manage Attendees</Modal.Title>
          </Modal.Header>
          <Modal.Body onClick={() => {}}>
            {view ? (
              ""
            ) : (
              <>
                <div className="searchDropDown">
                  <input
                    type="text"
                    className="form-control"
                    value={searchval}
                    onChange={(e) => setSearchval(e.target.value)}
                  />
                  <button
                    className="nav-link clickButton"
                    type="button"
                    id="dropdownMenuButton"
                    onClick={(e) => handleList(e)}
                  >
                    <FaSearch />
                  </button>

                  <div className={`dropDownCustom ${listOpen && "active"}`}>
                    {serchData && (
                      <ul className="list">
                        {serchData ? (
                          Array.isArray(serchData) &&
                          serchData.map((item, index) => {
                            return (
                              <li
                                className="attenMian"
                                onClick={(e) => e.stopPropagation()}
                                key={index}
                              >
                                <div className="atten">
                                  <div className="attenimg">
                                    <img
                                      className="avatar avatarpro avatar-md w-50 h-50"
                                      src={`${
                                        item.avatar
                                          ? item.avatar.includes("http")
                                            ? item.avatar
                                            : `${config.baseurl2}${item.avatar}`
                                          : "https://www.gravatar.com/avatar/b39d3037b9a666e9944ac081e76e3e28?s=160"
                                      } `}
                                      alt=""
                                    />
                                  </div>
                                  <div className="atteendetails">
                                    <strong>{item.uname}</strong>
                                    <small className="d-block text-muted">
                                      {item.role_name}
                                    </small>
                                  </div>
                                  <div className="attenright input-group loat-right">
                                    <Dropdown
                                      list={options}
                                      onChange={(value) =>
                                        handleSelect(index, value)
                                      }
                                    />
                                  </div>
                                  <div className="input-group-append">
                                    <button
                                      className="btn btn-outline-secondary add_att"
                                      type="button"
                                      onClick={() => hanleAdd(item)}
                                    >
                                      <span className="fe fe-plus"></span>
                                    </button>
                                  </div>
                                </div>
                              </li>
                            );
                          })
                        ) : (
                          <li> {resSearch.data.message}</li>
                        )}
                      </ul>
                    )}
                  </div>
                </div>
                {/* <button
                  className=" mt-3 otherUser btn btn-primary"
                  onClick={handleContactModal}
                >
                  Other User
                </button> */}
              </>
            )}

            <hr />
            <h6>Attendees</h6>
            {/* {allData && (
              <ul className="right_chat list-unstyled p-0">
                <li className="online mb-2">
                  <div className="d-flex justify-content-between align-content-center">
                    <div className="media Attendees__left">
                      <img
                        className="media-object "
                        src={`${
                          allData?.createdUserData.avatar
                            ? allData?.createdUserData.avatar.includes("http")
                              ? allData?.createdUserData.avatar
                              : `${config.baseurl2}${allData?.createdUserData.avatar}`
                            : "https://www.gravatar.com/avatar/b39d3037b9a666e9944ac081e76e3e28?s=160"
                        } `}
                        alt=""
                      />
                      <div className="media-body">
                        <span className="name">
                          {allData?.createdUserData.username ??
                            allData?.createdUserData.name}
                          <span className="font-11 px-2 py-1 badgecus">
                            {allData?.createdUserData.att_label ??
                              allData?.createdUserData.type}
                          </span>
                        </span>
                        <span className="message">
                          {allData?.createdUserData.email}
                        </span>
                      </div>
                    </div>
                    <div className="Attendees__right">
                      <ul className="list d-flex align-item-center">
                        <li>
                          <p className="m-0">Present</p>
                        </li>
                        {view ? (
                          ""
                        ) : (
                          <>
                            <li>
                              <div className="form-group m-0">
                                <label className="custom-switch">
                                  <input
                                    // onChange={(e) => toggleStatus(e, allData?.createdUserData, )}
                                    checked={true}
                                    type="checkbox"
                                    className="custom-switch-input"
                                    value={"1"}
                                    id={`checkbox`}
                                  />
                                  <span className="custom-switch-indicator custom-switch-indicator_new"></span>
                                </label>
                              </div>
                            </li>
                            <li>
                              <Dropdown2 className="item-action dropdown ml-2">
                                <div className="none">
                                  <Dropdown2.Toggle className="">
                                    <i
                                      style={{ color: "#000" }}
                                      className="fe fe-more-vertical"
                                    ></i>
                                  </Dropdown2.Toggle>
                                </div>
                              </Dropdown2>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              ></div>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            )} */}

            {userList &&
              Array.isArray(userList) &&
              userList.map((item, index) => {
                return (
                  <div key={index} id="slatt">
                    <ul className="right_chat list-unstyled p-0">
                      <li className="online mb-2">
                        <div className="d-flex justify-content-between align-content-center">
                          <div className="media Attendees__left">
                            <img
                              className="media-object "
                              src={`${
                                item.avatar
                                  ? item.avatar.includes("http")
                                    ? item.avatar
                                    : `${config.baseurl2}${item.avatar}`
                                  : "https://www.gravatar.com/avatar/b39d3037b9a666e9944ac081e76e3e28?s=160"
                              } `}
                              alt=""
                            />
                            <div className="media-body">
                              <span className="name">
                                {item.username ?? item.name}
                                <span className="font-11 px-2 py-1 badgecus">
                                  {item.att_label ?? item.type}
                                </span>
                              </span>
                              <span className="message">{item.email}</span>
                            </div>
                          </div>
                          <div className="Attendees__right">
                            <ul className="list d-flex align-item-center">
                              <li>
                        {  item.meeting_presence === "1"? <p className="m-0">Present</p>:<p>Absent</p>}
                              </li>
                              {view ? (
                                ""
                              ) : (
                                <>
                                  <li>
                                    <div className="form-group m-0">
                                      <label className="custom-switch">
                                        <input
                                        readOnly
                                          onChange={(e) =>
                                         {if(item?.contact_id !== allData?.createdUserID)    toggleStatus(e, item, index)}
                                          }
                                          checked={
                                            item.meeting_presence === "1"
                                              ? true
                                              : false
                                          }
                                          type="checkbox"
                                          className="custom-switch-input"
                                          value={"1"}
                                          id={`checkbox`}
                                        />
                                        <span className="custom-switch-indicator custom-switch-indicator_new"></span>
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <Dropdown2 className="item-action dropdown ml-2">
                                      <div className="none">
                                        <Dropdown2.Toggle className="">
                                          <i
                                            style={{ color: "#000" }}
                                            className="fe fe-more-vertical"
                                          ></i>
                                        </Dropdown2.Toggle>
                                      </div>
                                     {item?.contact_id !== allData?.createdUserID ? <Dropdown2.Menu>
                                        <button
                                          onClick={() => handleDelete(item)}
                                          className="dropdown-item myIcon"
                                        >
                                          <i className="dropdown-icon fa fa-trash myicon"></i>{" "}
                                          Delete
                                        </button>
                                      </Dropdown2.Menu>:<></>}
                                    </Dropdown2>
                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton"
                                    ></div>
                                  </li>
                                </>
                              )}
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                );
              })}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => handleModalClose()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {Array.isArray(viewSendinvites.attendees_emails) && (
        <Modal show={invitesModal} onHide={handleModalInvitesclose}>
          <Modal.Header closeButton>
            <h3>Send Invites</h3>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                {viewSendinvites.attendees_emails &&
                  viewSendinvites?.attendees_emails.map((item, index) => {
                    const messageWithBrTags = item;
                    const messageWithoutBrTags = messageWithBrTags.replace(
                      /<br>/g,
                      ""
                    );
                    return (
                      <h6
                        key={index}
                        dangerouslySetInnerHTML={{
                          __html: messageWithoutBrTags,
                        }}
                      ></h6>
                    );
                  })}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {
        <Modal className="modal-xl" show={contactModal} onHide={handleContactModalClose}>
          <Modal.Header closeButton>
            <h3>Create Contact </h3>
          </Modal.Header>
          <Modal.Body>
            <div className="col-lg-12 display1">
              <div className="row clearfix fv-plugins-icon-container">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      {Translation(translations, "Name")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      onChange={(e) =>  setInitialValues({...initialValues,"contact_name":e.target.value})}
                      value={initialValues?.contact_name}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      {Translation(translations, "E-mail")}
                      <span style={{"color": "red"}}> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="E-mail"
                      onChange={(e) => handleEmail(e.target.value)}
                    />
                  </div>
                  {emailse && (
                                  <div id="email_err" style={{ color: "red" }}>
                                    Email already stored!
                                  </div>
                                )}
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormControl
                      className="form-control my-1"
                      updatess={(item) =>
                        setPhoneNumber({
                          ...phoneNumber,
                          number: `+${item}`,
                        })
                      }
                      datas={""}
                      label={Translation(translations, `${"Phone Number"}`)}
                      name="contact_phone"
                      onchange={(event) => setPhoneNumber(event.target.value)}
                      control="intl"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group  my-2">
                    <label className="form-label">
                      {Translation(translations, "Type of Contact")}
                    </label>
                    <select onChange={(e) =>  setInitialValues({...initialValues,"contact_type":e.target.value})} className="form-control"  value={initialValues?.contact_type} name="" id="">
                      <option value="">--select--</option>
                      {options2 && options2.map((item) => {
                        return (
                          <option key={item.value} value={item.value}>{item.label}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group  my-2">
                    <label className="form-label">
                      {Translation(translations, "Role")}
                    </label>
                    <Dropdown
                                      list={options}
                                      onChange={(value) =>
                                        handleSelect2(value)
                                      }
                                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                              <label className="form-label">
                                {Translation(translations, "Note")}
                              </label>
                            <textarea 
                            value={initialValues?.contact_note}
                            onChange={(e) =>  setInitialValues({...initialValues,"contact_note":e.target.value})} 
                            className="form-control" name="" id="" cols="30" rows="10"></textarea>
                            </div>
                          </div>
              </div>
              <div className="col-lg-3">
                          <div className="row clearfix">
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <File3
                                  label={Translation(translations, "Avatar")}
                                  value={uploadImage ? typeof (uploadImage) !== "object" ? uploadImage.includes("http") ? uploadImage : `${config.baseurl2}${uploadImage}` : uploadImage : uploadImage}
                                  onUpload={setUploadImage}
                                  name={"upload_file"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                      

            </div>
            <div className="col-lg-12 mt-3">
              <button  className= "btn btn-primary" onClick={handleScontact}>{Translation(translations, "Create Contact")}</button>
                    
                      </div>
          </Modal.Body>
        </Modal>
      }
    </div>
  );
}

export default Attendees;
