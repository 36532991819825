let isoCountries = [
    { id: 'af.svg', text: 'Afghanistan'},
    { id: 'ax.svg', text: 'Aland Islands'},
    { id: 'al.svg', text: 'Albania'},
    { id: 'dz.svg', text: 'Algeria'},
    { id: 'as.svg', text: 'American Samoa'},
    { id: 'ad.svg', text: 'Andorra'},
    { id: 'ao.svg', text: 'Angola'},
    { id: 'ai.svg', text: 'Anguilla'},
    { id: 'aq.svg', text: 'Antarctica'},
    { id: 'ag.svg', text: 'Antigua And Barbuda'},
    { id: 'ar.svg', text: 'Argentina'},
    { id: 'am.svg', text: 'Armenia'},
    { id: 'aw.svg', text: 'Aruba'},
    { id: 'au.svg', text: 'Australia'},
    { id: 'at.svg', text: 'Austria'},
    { id: 'az.svg', text: 'Azerbaijan'},
    { id: 'bs.svg', text: 'Bahamas'},
    { id: 'bh.svg', text: 'Bahrain'},
    { id: 'bd.svg', text: 'Bangladesh'},
    { id: 'bb.svg', text: 'Barbados'},
    { id: 'by.svg', text: 'Belarus'},
    { id: 'be.svg', text: 'Belgium'},
    { id: 'bz.svg', text: 'Belize'},
    { id: 'bj.svg', text: 'Benin'},
    { id: 'bm.svg', text: 'Bermuda'},
    { id: 'bt.svg', text: 'Bhutan'},
    { id: 'bo.svg', text: 'Bolivia'},
    { id: 'ba.svg', text: 'Bosnia And Herzegovina'},
    { id: 'bw.svg', text: 'Botswana'},
    { id: 'bv.svg', text: 'Bouvet Island'},
    { id: 'br.svg', text: 'Brazil'},
    { id: 'io.svg', text: 'British Indian Ocean Territory'},
    { id: 'bn.svg', text: 'Brunei Darussalam'},
    { id: 'bg.svg', text: 'Bulgaria'},
    { id: 'bf.svg', text: 'Burkina Faso'},
    { id: 'bi.svg', text: 'Burundi'},
    { id: 'kh.svg', text: 'Cambodia'},
    { id: 'cm.svg', text: 'Cameroon'},
    { id: 'ca.svg', text: 'Canada'},
    { id: 'cv.svg', text: 'Cape Verde'},
    { id: 'ky.svg', text: 'Cayman Islands'},
    { id: 'cf.svg', text: 'Central African Republic'},
    { id: 'td.svg', text: 'Chad'},
    { id: 'cl.svg', text: 'Chile'},
    { id: 'cn.svg', text: 'China'},
    { id: 'cx.svg', text: 'Christmas Island'},
    { id: 'cc.svg', text: 'Cocos (Keeling) Islands'},
    { id: 'co.svg', text: 'Colombia'},
    { id: 'km.svg', text: 'Comoros'},
    { id: 'cg.svg', text: 'Congo'},
    { id: 'cd.svg', text: 'Congo}, Democratic Republic'},
    { id: 'ck.svg', text: 'Cook Islands'},
    { id: 'cr.svg', text: 'Costa Rica'},
    { id: 'ci.svg', text: 'Cote D\'Ivoire'},
    { id: 'hr.svg', text: 'Croatia'},
    { id: 'cu.svg', text: 'Cuba'},
    { id: 'cy.svg', text: 'Cyprus'},
    { id: 'cz.svg', text: 'Czech Republic'},
    { id: 'dk.svg', text: 'Denmark'},
    { id: 'dj.svg', text: 'Djibouti'},
    { id: 'dm.svg', text: 'Dominica'},
    { id: 'do.svg', text: 'Dominican Republic'},
    { id: 'ec.svg', text: 'Ecuador'},
    { id: 'eg.svg', text: 'Egypt'},
    { id: 'sv.svg', text: 'El Salvador'},
    { id: 'gq.svg', text: 'Equatorial Guinea'},
    { id: 'er.svg', text: 'Eritrea'},
    { id: 'ee.svg', text: 'Estonia'},
    { id: 'et.svg', text: 'Ethiopia'},
    { id: 'fk.svg', text: 'Falkland Islands (Malvinas)'},
    { id: 'fo.svg', text: 'Faroe Islands'},
    { id: 'fj.svg', text: 'Fiji'},
    { id: 'fi.svg', text: 'Finland'},
    { id: 'fr.svg', text: 'France'},
    { id: 'gf.svg', text: 'French Guiana'},
    { id: 'pf.svg', text: 'French Polynesia'},
    { id: 'tf.svg', text: 'French Southern Territories'},
    { id: 'ga.svg', text: 'Gabon'},
    { id: 'gm.svg', text: 'Gambia'},
    { id: 'ge.svg', text: 'Georgia'},
    { id: 'de.svg', text: 'Germany'},
    { id: 'gh.svg', text: 'Ghana'},
    { id: 'gi.svg', text: 'Gibraltar'},
    { id: 'gr.svg', text: 'Greece'},
    { id: 'gl.svg', text: 'Greenland'},
    { id: 'gd.svg', text: 'Grenada'},
    { id: 'gp.svg', text: 'Guadeloupe'},
    { id: 'gu.svg', text: 'Guam'},
    { id: 'gt.svg', text: 'Guatemala'},
    { id: 'gg.svg', text: 'Guernsey'},
    { id: 'gn.svg', text: 'Guinea'},
    { id: 'gw.svg', text: 'Guinea-Bissau'},
    { id: 'gy.svg', text: 'Guyana'},
    { id: 'ht.svg', text: 'Haiti'},
    { id: 'hm.svg', text: 'Heard Island & Mcdonald Islands'},
    { id: 'va.svg', text: 'Holy See (Vatican City State)'},
    { id: 'hn.svg', text: 'Honduras'},
    { id: 'hk.svg', text: 'Hong Kong'},
    { id: 'hu.svg', text: 'Hungary'},
    { id: 'is.svg', text: 'Iceland'},
    { id: 'in.svg', text: 'India'},
    { id: 'id.svg', text: 'Indonesia'},
    { id: 'ir.svg', text: 'Iran}, Islamic Republic Of'},
    { id: 'iq.svg', text: 'Iraq'},
    { id: 'ie.svg', text: 'Ireland'},
    { id: 'im.svg', text: 'Isle Of Man'},
    { id: 'il.svg', text: 'Israel'},
    { id: 'it.svg', text: 'Italy'},
    { id: 'jm.svg', text: 'Jamaica'},
    { id: 'jp.svg', text: 'Japan'},
    { id: 'je.svg', text: 'Jersey'},
    { id: 'jo.svg', text: 'Jordan'},
    { id: 'kz.svg', text: 'Kazakhstan'},
    { id: 'ke.svg', text: 'Kenya'},
    { id: 'ki.svg', text: 'Kiribati'},
    { id: 'kr.svg', text: 'Korea'},
    { id: 'kw.svg', text: 'Kuwait'},
    { id: 'kg.svg', text: 'Kyrgyzstan'},
    { id: 'la.svg', text: 'Lao People\'s Democratic Republic'},
    { id: 'lv.svg', text: 'Latvia'},
    { id: 'lb.svg', text: 'Lebanon'},
    { id: 'ls.svg', text: 'Lesotho'},
    { id: 'lr.svg', text: 'Liberia'},
    { id: 'ly.svg', text: 'Libyan Arab Jamahiriya'},
    { id: 'li.svg', text: 'Liechtenstein'},
    { id: 'lt.svg', text: 'Lithuania'},
    { id: 'lu.svg', text: 'Luxembourg'},
    { id: 'mo.svg', text: 'Macao'},
    { id: 'mk.svg', text: 'Macedonia'},
    { id: 'mg.svg', text: 'Madagascar'},
    { id: 'mw.svg', text: 'Malawi'},
    { id: 'my.svg', text: 'Malaysia'},
    { id: 'mv.svg', text: 'Maldives'},
    { id: 'ml.svg', text: 'Mali'},
    { id: 'mt.svg', text: 'Malta'},
    { id: 'mh.svg', text: 'Marshall Islands'},
    { id: 'mq.svg', text: 'Martinique'},
    { id: 'mr.svg', text: 'Mauritania'},
    { id: 'mu.svg', text: 'Mauritius'},
    { id: 'yt.svg', text: 'Mayotte'},
    { id: 'mx.svg', text: 'Mexico'},
    { id: 'fm.svg', text: 'Micronesia}, Federated States Of'},
    { id: 'md.svg', text: 'Moldova'},
    { id: 'mc.svg', text: 'Monaco'},
    { id: 'mn.svg', text: 'Mongolia'},
    { id: 'me.svg', text: 'Montenegro'},
    { id: 'ms.svg', text: 'Montserrat'},
    { id: 'ma.svg', text: 'Morocco'},
    { id: 'mz.svg', text: 'Mozambique'},
    { id: 'mm.svg', text: 'Myanmar'},
    { id: 'na.svg', text: 'Namibia'},
    { id: 'nr.svg', text: 'Nauru'},
    { id: 'np.svg', text: 'Nepal'},
    { id: 'nl.svg', text: 'Netherlands'},
    { id: 'an.svg', text: 'Netherlands Antilles'},
    { id: 'nc.svg', text: 'New Caledonia'},
    { id: 'nz.svg', text: 'New Zealand'},
    { id: 'ni.svg', text: 'Nicaragua'},
    { id: 'ne.svg', text: 'Niger'},
    { id: 'ng.svg', text: 'Nigeria'},
    { id: 'nu.svg', text: 'Niue'},
    { id: 'nf.svg', text: 'Norfolk Island'},
    { id: 'mp.svg', text: 'Northern Mariana Islands'},
    { id: 'no.svg', text: 'Norway'},
    { id: 'om.svg', text: 'Oman'},
    { id: 'pk.svg', text: 'Pakistan'},
    { id: 'pw.svg', text: 'Palau'},
    { id: 'ps.svg', text: 'Palestinian Territory}, Occupied'},
    { id: 'pa.svg', text: 'Panama'},
    { id: 'pg.svg', text: 'Papua New Guinea'},
    { id: 'py.svg', text: 'Paraguay'},
    { id: 'pe.svg', text: 'Peru'},
    { id: 'ph.svg', text: 'Philippines'},
    { id: 'pn.svg', text: 'Pitcairn'},
    { id: 'pl.svg', text: 'Poland'},
    { id: 'pt.svg', text: 'Portugal'},
    { id: 'pr.svg', text: 'Puerto Rico'},
    { id: 'qa.svg', text: 'Qatar'},
    { id: 're.svg', text: 'Reunion'},
    { id: 'ro.svg', text: 'Romania'},
    { id: 'ru.svg', text: 'Russian Federation'},
    { id: 'rw.svg', text: 'Rwanda'},
    { id: 'bl.svg', text: 'Saint Barthelemy'},
    { id: 'sh.svg', text: 'Saint Helena'},
    { id: 'kn.svg', text: 'Saint Kitts And Nevis'},
    { id: 'lc.svg', text: 'Saint Lucia'},
    { id: 'mf.svg', text: 'Saint Martin'},
    { id: 'pm.svg', text: 'Saint Pierre And Miquelon'},
    { id: 'vc.svg', text: 'Saint Vincent And Grenadines'},
    { id: 'ws.svg', text: 'Samoa'},
    { id: 'sm.svg', text: 'San Marino'},
    { id: 'st.svg', text: 'Sao Tome And Principe'},
    { id: 'sa.svg', text: 'Saudi Arabia'},
    { id: 'sn.svg', text: 'Senegal'},
    { id: 'rs.svg', text: 'Serbia'},
    { id: 'sc.svg', text: 'Seychelles'},
    { id: 'sl.svg', text: 'Sierra Leone'},
    { id: 'sg.svg', text: 'Singapore'},
    { id: 'sk.svg', text: 'Slovakia'},
    { id: 'si.svg', text: 'Slovenia'},
    { id: 'sb.svg', text: 'Solomon Islands'},
    { id: 'so.svg', text: 'Somalia'},
    { id: 'za.svg', text: 'South Africa'},
    { id: 'gs.svg', text: 'South Georgia And Sandwich Isl.'},
    { id: 'es.svg', text: 'Spain'},
    { id: 'lk.svg', text: 'Sri Lanka'},
    { id: 'sd.svg', text: 'Sudan'},
    { id: 'sr.svg', text: 'Suriname'},
    { id: 'sj.svg', text: 'Svalbard And Jan Mayen'},
    { id: 'sz.svg', text: 'Swaziland'},
    { id: 'se.svg', text: 'Sweden'},
    { id: 'ch.svg', text: 'Switzerland'},
    { id: 'sy.svg', text: 'Syrian Arab Republic'},
    { id: 'tw.svg', text: 'Taiwan'},
    { id: 'tj.svg', text: 'Tajikistan'},
    { id: 'tz.svg', text: 'Tanzania'},
    { id: 'th.svg', text: 'Thailand'},
    { id: 'tl.svg', text: 'Timor-Leste'},
    { id: 'tg.svg', text: 'Togo'},
    { id: 'tk.svg', text: 'Tokelau'},
    { id: 'to.svg', text: 'Tonga'},
    { id: 'tt.svg', text: 'Trinidad And Tobago'},
    { id: 'tn.svg', text: 'Tunisia'},
    { id: 'tr.svg', text: 'Turkey'},
    { id: 'tm.svg', text: 'Turkmenistan'},
    { id: 'tc.svg', text: 'Turks And Caicos Islands'},
    { id: 'tv.svg', text: 'Tuvalu'},
    { id: 'ug.svg', text: 'Uganda'},
    { id: 'ua.svg', text: 'Ukraine'},
    { id: 'ae.svg', text: 'United Arab Emirates'},
    { id: 'gb.svg', text: 'United Kingdom'},
    { id: 'us.svg', text: 'United States'},
    { id: 'um.svg', text: 'United States Outlying Islands'},
    { id: 'uy.svg', text: 'Uruguay'},
    { id: 'uz.svg', text: 'Uzbekistan'},
    { id: 'vu.svg', text: 'Vanuatu'},
    { id: 've.svg', text: 'Venezuela'},
    { id: 'vn.svg', text: 'Viet Nam'},
    { id: 'vg.svg', text: 'Virgin Islands}, British'},
    { id: 'vi.svg', text: 'Virgin Islands}, U.S.'},
    { id: 'wf.svg', text: 'Wallis And Futuna'},
    { id: 'eh.svg', text: 'Western Sahara'},
    { id: 'ye.svg', text: 'Yemen'},
    { id: 'zm.svg', text: 'Zambia'},
    { id: 'zw.svg', text: 'Zimbabwe'}
];
export default isoCountries;