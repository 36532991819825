import React,{useContext} from 'react'
import { Link } from "react-router-dom";
import config from "../services/config.js";
import { handleNormalDate, handleSpitTitle } from '../components/AllCustomFuntion';
import { MainAuthPermissionsContext } from '../context/MainAuthPermissionsContext';
function ListCard({ lists, deletes }) {
    const { permissions } = useContext(MainAuthPermissionsContext);

  return Array.isArray(lists) ?  lists.map((item, i) => {
          return (
            <div key={i} className="col-12">
    <div className="card _projects_ box_shadow">
    <div className="starBox">
        <Link  className="mail-star"><i className="fa fa-star"style={{ color: `${item.status_color}` }}></i></Link>
    </div>
    <div className="card-header">
        <h3 className="card-title">
            <Link className="txt" to={`/${config.ddemoss}view/project/${item?.prj_id}`}>{handleSpitTitle(item.project_title)}</Link>                                                           
            <small>{item.name}</small>
        </h3>
    </div>
    <div className="card-body">
        <div className="row _projects_Members">
            <div className="col-5 py-1"><strong>Members:</strong></div>
            <div className="col-7 py-1">
                <div className="avatar-list avatar-list-stacked">
                {item.member_ids.map((itemMember,index2) => {
                        return (
                             <img className="avatar avatar-sm" key={index2} onError={(e) => e.target.src = "https://phpstack-896782-3163986.cloudwaysapps.com/react_lead/assets/leads/images/b99836c9d3aaf35493f469af46e1e88e78e463d4.png"}  src={itemMember?.avatar ? itemMember?.avatar.includes("http") ? itemMember?.avatar : `${config.baseurl2}${itemMember?.avatar}`:
                             permissions ["system-default-avatar-image"]?.setting_value ? 
                             `${config.baseurl2}${permissions["system-default-avatar-image"]?.setting_value}`:
                             `https://www.gravatar.com/avatar/b39d3037b9a666e9944ac081e76e3e28?s=160`} title="check 1  check father "/>
                             )
                            })}  </div>
            </div>
        </div>
        <div className="row _projects_Followers">
            <div className="col-5 py-1"><strong>Followers:</strong></div>
            <div className="col-7 py-1">
            <div className="avatar-list avatar-list-stacked" >
                        {item.follower_ids.map((itemFollowers,index2) => {
                        return (
                             <img className="avatar avatar-sm" key={index2} 
                             onError={(e) => e.target.src = "https://phpstack-896782-3163986.cloudwaysapps.com/react_lead/assets/leads/images/b99836c9d3aaf35493f469af46e1e88e78e463d4.png"}  
                             src={itemFollowers?.avatar ? itemFollowers?.avatar.includes("http") ? itemFollowers?.avatar : `${config.baseurl2}${itemFollowers?.avatar}`:
                             permissions ["system-default-avatar-image"]?.setting_value ? 
                             `${config.baseurl2}${permissions["system-default-avatar-image"]?.setting_value}`:
                             `https://www.gravatar.com/avatar/b39d3037b9a666e9944ac081e76e3e28?s=160`} title="check 1  check father "/>
                             )
                            })}
                            </div>
        </div>
        <div className="row _projects_sd">
            <div className="col-5 py-1"><strong>Start Date:</strong></div>
            <div className="col-7 py-1">{handleNormalDate(item.start_date)}</div>
        </div>
        <div className="row _projects_ed">
            <div className="col-5 py-1"><strong>End Date:</strong></div>
            <div className="col-7 py-1">{handleNormalDate(item.end_date)}</div>
        </div>

    </div>
    <div className="card-footer">
        <div className="clearfix">
            <div className="float-left"><strong>15%</strong></div>
            <div className="float-right"><small className="text-muted">Progress</small></div>
        </div>
        <div className="progress progress-xs box_shadow">
            <div className="progress-bar bg-red" role="progressbar" style={{"width": "15%"}} aria-valuenow="36" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    </div>
</div>
</div>
</div>
          )
    })
    : "No any associated Project found!"
}
  

export default ListCard