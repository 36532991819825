import React,{useContext} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MainAuthPermissionsContext } from "../../context/MainAuthPermissionsContext";
import justcallLogo from '../../dist/webImages/justcall-logo.webp';
import config from "../../services/config.js";

function CardList({viewLink, editLink, lists }) {
  const { permissions } = useContext(MainAuthPermissionsContext);
  const handleError = (e) => {
    if(permissions ["system-default-avatar-image"]?.setting_value) {
      e.target.src = `${config.baseurl2}${permissions ["system-default-avatar-image"]?.setting_value}`

    }
    else {
      e.target.src = `https://www.gravatar.com/avatar/b39d3037b9a666e9944ac081e76e3e28?s=160`
    }
  } 
  return !lists.message ? (
    lists?.map((items, index) => {
      return (
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6" key={index}>
          <div className="card">
            <div className="card-body text-center ribbon">
            <div className="starBox">
             <Link  className="mail-star"><i className="fa fa-star"></i></Link>
              </div>
              <div className="card_img">
              <img  className="rounded-circle img-thumbnail w100" onError={handleError}
                src={items?.avatar ? items?.avatar.includes("http") ? items?.avatar :
                `${config.baseurl2}${items?.avatar}`:permissions["system-default-avatar-image"]?.setting_value ?`${config.baseurl2}${permissions["system-default-avatar-image"]?.setting_value}`:
                `https://www.gravatar.com/avatar/b39d3037b9a666e9944ac081e76e3e28?s=160`} 
                alt=""
                />
              </div>
              <div className="card_heading">
                <h6 className="mt-3 mb-0">
                  <Link to={`${viewLink}${items.leadid}`}>{items.fullname}</Link>
                </h6>
                <span>{items?.number?items?.number:""}</span>
              </div>
              <div className="emailBox">
                <span>{items?.email}</span>
              </div>
              <div className="leadst">
                <ul className="list">
                  <li>
                    <span>{items?.name}</span>
                  </li>
                  <li>
                    <span>{items?.uname}</span>
                  </li>
                </ul>
              </div>
              <div className="mb-1 socialBtn gap-2 justify-content-center">
              <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      {items?.email
                        ? `${items?.email}`
                        : "No email found"}
                    </Tooltip>
                  }
                >
                  <a
                    href={
                      items?.email ? `mailto:${items?.email}` : '#'
                    }
                    onClick={(e) => {
                      if (!items?.email) { e.preventDefault(); }
                    }}
                  >
                    <i className="fa fa-envelope"></i>
                  </a>
                </OverlayTrigger>
               
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      {items?.number
                        ? `${items?.number}`
                        : "No Number Found"}
                    </Tooltip>
                  }
                >
                  <a
                    href={
                      items?.number ? `tel:${items?.number}` : "#"
                    }
                    onClick={(e) => {
                      if (!items?.number) { e.preventDefault(); }
                    }}
                  >
                    <i className="fa fa-phone"></i>
                  </a>
                </OverlayTrigger>
                
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      {items?.mobile_phone
                        ? `${items?.mobile_phone}`
                        : "No Number Found"}
                    </Tooltip>
                  }
                >
                  <a
                    href={
                      items?.mobile_phone ? `sms:${items?.mobile_phone}` : "#"
                    }
                    onClick={(e) => {
                      if (!items?.mobile_phone) { e.preventDefault(); }
                    }}
                  >
                    <i className="fa fa-mobile"></i>
                  </a>
                </OverlayTrigger>
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      {items?.mobile_phone
                        ? `${items?.mobile_phone}`
                        : "No Number Found"}
                    </Tooltip>
                  }
                >
                  <a
                    href={
                      items?.mobile_phone ? `https://api.whatsapp.com/send?phone=${items?.mobile_phone?.substring(
                        1
                      )}` : "#"
                    }
                    onClick={(e) => {
                      if (!items?.mobile_phone) { e.preventDefault(); }
                    }}
                    target={"blank"}
                  >
                    <i className="fa fa-whatsapp"></i>
                  </a>
                </OverlayTrigger>
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      {items?.number
                        ? `${items?.number}`
                        : "No Number Found"}
                    </Tooltip>
                  }
                >
                  <a
                    href={
                      items?.number ? `https://justcall.io/app/macapp/dialpad_app.php?numbers=&${items?.number};` : "#"
                    }
                    onClick={(e) => {
                      if (!items?.number) { e.preventDefault(); }
                    }}
                    target={"blank"}
                  >
                  <img alt="" src={justcallLogo} style={{ width: "15px" }} />
                  </a>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      );
    })
  ) : (
    <div>No Data</div>
  );
}

export default CardList;
