import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import config from "../services/config.js";

function GoogleSyn_event_modal({setsync_user_modal, sync_user_modal, data,handleGoogleSync }) {
  const [show, setShow] = useState(sync_user_modal);

  const handleClose = () =>{setsync_user_modal(false); setShow(false)};
  const handleShow = () => setShow(true);

  return (
    <>

      <Modal className="media_modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <><h4>Following users are required to sync</h4></>
     
        </Modal.Header>
        <Modal.Body>
        {data?.sync_users?.map((email,index)=><span key={index} className="d-flex mt-4 align-items-baseline justify-content-between px-2 "> <p className="mr-2 ">{email}</p> <button onClick={handleGoogleSync} className="btn btn-primary">Google Sync</button> </span>)}
        </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GoogleSyn_event_modal;
