import React, { useContext, useState, useEffect } from "react";
import { MainTranslationContexts } from "../../context/MainTranslationContexts";
import { Translation } from "../../components/Translation";
import SubmitButton from "../../components/SubmitButton";
import config from "../../services/config.js";
import axios from "axios";
import { getTokenSession } from "../../utils/common";

function MarketingMappingIndex() {
    const { translations } = useContext(MainTranslationContexts);

    const [leadSource, setLeadSource] = useState('')
    const [leadMedium, setLeadMedium] = useState('')
    const [utmSource, setUtmSource] = useState('')
    const [utmMedium, setUtmMedium] = useState('')

    const [leadFinalSource, setFinalLeadSource] = useState('')
    const [leadFinalMedium, setFinalLeadMedium] = useState('')

    const [mediums, setMediums] = useState([]);
    const [sources, setSources] = useState([]);

    const getAllMediums = async () => {
        axios.defaults.headers = {
            "Content-Type": "multipart/form-data",
            authentication: `${getTokenSession()}`,
        };

        axios.get(`${config.apiEndPoint}getAllMediums`)
            .then((response) => {
                setMediums(response.data);
            }
        );
    }

    const getAllSources = async () => {
        axios.defaults.headers = {
            "Content-Type": "multipart/form-data",
            authentication: `${getTokenSession()}`,
        };

        axios.get(`${config.apiEndPoint}getAllSources`)
            .then((response) => {
                setSources(response.data);
            }
        );
    }

    const handleSubmit = async () => {
        console.log('Submit button clicked');
        if (leadFinalSource === '') {
            alert('Please select final lead source');
            return;
        }
    
        if (leadFinalMedium === '') {
            alert('Please select final lead medium');
            return;
        }
    
        axios.defaults.headers = {
            "Content-Type": "application/json",
            authentication: `${getTokenSession()}`,
        };
    
        const data = {
            lead_source: leadSource,
            lead_medium: leadMedium,
            utm_source: utmSource,
            utm_medium: utmMedium,
            final_lead_source: leadFinalSource,
            final_lead_medium: leadFinalMedium
        };
    
        try {
            await axios.post(`${config.serviceUrl}/marketing-mapping/store`, data);
            console.log('Form submitted successfully'); // Debugging line
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    useEffect(() => {
        getAllMediums();
        getAllSources();
    }, []);

    return (
        <div className="container-fluid">
            <div className="row clearfix">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header borderblue">
                            <h3 className="card-title">
                            {Translation(translations, "Marketing Mapping")}
                            </h3>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <h3>Source & Medium</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mt-3">
                                    <div className="mt-3">
                                        <label className="form-label">
                                            {Translation(translations, "Lead Source")}
                                        </label>
                                        <div className="searchDropDown">
                                            <input
                                                type="text"
                                                className="form-control"
                                                onChange={(e) => setLeadSource(e.target.value)}
                                                value={leadSource}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <label className="form-label">
                                            {Translation(translations, "Lead Medium")}
                                        </label>
                                        <div className="searchDropDown">
                                            <input
                                                type="text"
                                                className="form-control"
                                                onChange={(e) => setLeadMedium(e.target.value)}
                                                value={leadMedium}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <label className="form-label">
                                            {Translation(translations, "UTM Source")}
                                        </label>
                                        <div className="searchDropDown">
                                            <input
                                                type="text"
                                                className="form-control"
                                                onChange={(e) => setUtmSource(e.target.value)}
                                                value={utmSource}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <label className="form-label">
                                            {Translation(translations, "UTM Medium")}
                                        </label>
                                        <div className="searchDropDown">
                                            <input
                                                type="text"
                                                className="form-control"
                                                onChange={(e) => setUtmMedium(e.target.value)}
                                                value={utmMedium}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <div className="mt-3">
                                        <div className="form-group">
                                            <label className="form-label">{Translation(translations, "Final Lead Source")}</label>
                                            <select
                                                required=""
                                                value={leadFinalSource}
                                                className="form-control"
                                                name="source_parent"
                                                onChange={(e) => setFinalLeadSource(e.target.value)}
                                            >
                                            <option value="">--Select--</option>
                                            {sources.map((data, index) => (
                                                <option value={data.source_id} key={data.source_id}>
                                                {data.source_name}
                                                </option>
                                            ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <div className="form-group">
                                            <label className="form-label">{Translation(translations, "Final Lead Medium")}</label>
                                            <select
                                                required=""
                                                value={leadFinalMedium}
                                                className="form-control"
                                                name="source_parent"
                                                onChange={(e) => setFinalLeadMedium(e.target.value)}
                                            >
                                            <option value="">--Select--</option>
                                            {mediums.map((data, index) => (
                                                <option value={data.source_id} key={data.source_id}>
                                                {data.source_name}
                                                </option>
                                            ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div class="d-flex justify-content-between">
                                    <div className="text-right col-md-12 mt-4 mb-2">
                                        <button type="button" className="btn btn-primary m-2" onClick={handleSubmit}>Store Lead</button>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MarketingMappingIndex;