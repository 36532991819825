import { Form, Formik } from "formik";
import React, { useContext, useState, useEffect } from "react";
import { Translation } from "../components/Translation";
import { MainTranslationContexts } from "../context/MainTranslationContexts";
import { handleToggle } from "../components/AllCustomFuntion";
import { Link } from "react-router-dom";
import FormControl from "../components/form/FormControl";
import { MainLeadPermissionContext } from "../context/MainLeadPermissionContext";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import FullCalendar, { formatDate } from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction'
import CreateWorkScheduleModal from "./CreateWorkScheduleModal.jsx";
import Button from 'react-bootstrap/Button';
import config from "../services/config.js";
import axios from "axios";
import { getTokenSession } from "../utils/common";
import UpdateWorkScheduleModal from './UpdateWorkScheduleModal.jsx';

function WorkSchedule() {
    const { translations } = useContext(MainTranslationContexts);
    const { leadPermission } = useContext(MainLeadPermissionContext);
    const [selectedUser, setSelectedUser] = useState(null);
    const [events, setEvents] = useState([
        {
            title: "Event 1",
            id: "1",
            start: "2024-06-01",
            end: "2024-06-02",
            start_time: "10:00:00",
            end_time: "11:00:00",
            borderColor: "#3788d8",
            backgroundColor: "#3788d8",
        }
    ]);

    const [initialValues, setInitialValues] = useState({
        fname: "",
        lname: "",
    });

    const [users, setUsers] = useState([]);

    const handleSubmit = (values) => {
        console.log(values);
    }

    const selectList = [{
        db_id: "15",
        type_color: "#e5e4e2",
        type_module: "Lead,Prospect,Client",
        type_name: "Renter"
    }]

    const handleSelect = (selectInfo) => {
        console.log('select', selectInfo);
        setSelectedUser(selectInfo); // Define o usuário selecionado
    }

    const handleDatesSet = async (dateInfo) => {
        const params = {
            start: dateInfo.startStr,
            end: dateInfo.endStr
        };

        console.log('params', params);

        try {
            const response = await fetch(`https://stage-service.thesalesjourney.io/api/work-schedules?start=${dateInfo.startStr}&end=${dateInfo.endStr}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                // Pass start and end dates as query parameters to fetch events for the displayed month
                params: params
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = await response.json();
    
            const newEvents = data.map((item) => {
                return {
                    title: item.username + ' : ' + item.start_time + ' - ' + item.end_time,
                    start: item.day,
                    end: item.day,
                    start_time: item.start_time,
                    end_time: item.end_time,
                    borderColor: "#3788d8",
                    backgroundColor: "#3788d8",
                };
            });
    
            setEvents(newEvents);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const [showModal, setShowModal] = useState(false);
    const clickHandle = () => {
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const getAllUsers = async () => {
        try {
            axios.defaults.headers = {
                "Content-Type": "multipart/form-data",
                authentication: `${getTokenSession()}`,
            };

            const response = await axios.get(`${config.apiEndPoint}getAllUsers`);
            setUsers(response.data);
        } catch (err) {
            console.log('err', err);
        }
    }
    
    const handleSaveSchedule = async (obj) => {
        console.log('obj', obj);

        const formattedDate = obj.defaultCreateDate.format('YYYY-MM-DD');
        const startTime = obj.initialStartTime.format('HH:mm');
        const endTime = obj.initialEndTime.format('HH:mm');

        const postData = {
            user_id: selectedUser,
            day: formattedDate,
            start_time: startTime,
            end_time: endTime,
            frequency: obj.frequency,
            just_for_this_month: obj.justForThisMonth
        };

        try {
            const response = await fetch('https://stage-service.thesalesjourney.io/api/work-schedules/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = await response.json();
            refreshEvent();
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleUpdateSchedule = async (obj) => {
        console.log('obj', obj);
    }

    const refreshEvent = async () => {
        const response = await fetch('https://stage-service.thesalesjourney.io/api/work-schedules', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = await response.json();

        const newEvents = data.map((item) => {
            return {
                title: item.username + ' : ' + item.start_time + ' - ' + item.end_time,
                start: item.day,
                end: item.day,
                start_time: item.start_time,
                end_time: item.end_time,
                borderColor: "#3788d8",
                backgroundColor: "#3788d8",
            };
        });

        setEvents(newEvents);
    }

    const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const handleEventClick = (eventClickInfo) => {
        const event = eventClickInfo.event;
        console.log('event', event);
        setSelectedEvent(eventClickInfo);
        setShowEventDetailsModal(true);
    };

    const handleCloseEventDetailsModal = () => {
        setShowEventDetailsModal(false);
    };

    useEffect(() => {
        getAllUsers();
        refreshEvent();
    }, []);

    return (
        <div className="container-fluid">
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                <Form name="myForm">
                    <div className="row clearfix">
                        <div className="col-xl-12 col-lg-12">
                            <div className="card">
                                <div className="card-status bg-blue"></div>
                                <div className="card-header borderblue">
                                    <h3 className="card-title">
                                        {Translation(translations, "Work Schedule")}
                                    </h3>
                                    <div className="card-options">
                                        <Link
                                            onClick={(e) => handleToggle(e)}
                                            className="card-options-collapse"
                                        >
                                            <i className={`fe fe-chevron-down`} />
                                        </Link>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row fv-plugins-icon-container">
                                        <div className="col-md-11">
                                        {leadPermission?.super_admin ||
                                            leadPermission?.leads?.fields?.leads_contact_type ===
                                            "true" ? (
                                            <FormControl
                                            className="form-control my-1"
                                            firstSelect={"--Select--"}
                                            selectList={
                                                users
                                            }
                                            required={true}
                                            label={Translation(translations, `${"Type"}`)}
                                            name="contact_type"
                                            control="select_custom_options"
                                            custom_label_name="username"
                                            customer_value_name="id"
                                            onChange={(e) => handleSelect(e.target.value)}
                                            />
                                        ) : (
                                            ""
                                        )}
                                        </div>
                                        <div className="col-md-1">
                                            <div className="d-flex align-items-end justify-content-end" style={{ height: '90%' }}>
                                                <Button variant="primary" onClick={clickHandle}>
                                                    Add
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row fv-plugins-icon-container">
                                        <FullCalendar
                                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                            initialView="dayGridMonth"
                                            // timeZone={timezone}
                                            headerToolbar={{
                                                left: 'title',
                                                right: 'dayGridMonth,timeGridWeek,timeGridDay,prev,next'
                                            }}
                                            eventTimeFormat={{
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true
                                            }}
                                            // timeFormat= 'H(:mm)' // uppercase H for 24-hour clock
                                            // timeZone={`${timeZone3[0]}-${timeZone3[1]}`}

                                            editable={true}
                                            selectable={true}
                                            selectMirror={true}
                                            dayMaxEvents={3}
                                            select={handleSelect}
                                            eventClick={handleEventClick}
                                            events={events}
                                            datesSet={handleDatesSet}
                                            // nextDayThreshold='00:00:00' // Adjusted the nextDayThreshold value
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>

            <CreateWorkScheduleModal 
                show={showModal} 
                handleClose={handleCloseModal}     
                initialCreateDate="2024-06-17"
                initialStartTime="08:00 AM"
                initialEndTime="05:00 PM"
                selectedOption="do-not-repeat"
                onSave={handleSaveSchedule} />

            <UpdateWorkScheduleModal
                show={showEventDetailsModal}
                handleClose={handleCloseEventDetailsModal}
                initialCreateDate="2024-06-17"
                initialStartTime="08:00 AM"
                initialEndTime="05:00 PM"
                onSave={handleUpdateSchedule}
            />
        </div>
    )
}

export default WorkSchedule;