
let config = {}

console.log('here...')


if (process.env.REACT_APP_ENVIRONMENT === 'crm') {
    config = {
        "apiEndPoint":  "https://crm-backend.thesalesjourney.io/api-",
        "ddemoss": "",
        "baseurl": "https://crm.thesalesjourney.io/",
        "baseurl2": "https://crm.thesalesjourney.io/",
        "baseBackEndUrl": "https://crm-backend.thesalesjourney.io",
        "serviceUrl": "https://crm-service.thesalesjourney.io/api"
    }
} else if (process.env.REACT_APP_ENVIRONMENT === 'stage') {
    config = {
        "apiEndPoint":  "https://stage-backend.thesalesjourney.io/api-",
        "ddemoss": "",
        "baseurl": "https://stage.thesalesjourney.io/",
        "baseurl2": "https://stage.thesalesjourney.io/",
        "baseBackEndUrl": "https://stage-backend.thesalesjourney.io",
        "serviceUrl": "https://stage-service.thesalesjourney.io/api"
    }
} else if (process.env.REACT_APP_ENVIRONMENT === 'livetest') {
    config = {
        "apiEndPoint":  "https://livetest-backend.thesalesjourney.io/api-",
        "ddemoss": "",
        "baseurl": "https://livetest.thesalesjourney.io/",
        "baseurl2": "https://livetest.thesalesjourney.io/",
        "baseBackEndUrl": "https://livetest-backend.thesalesjourney.io",
        "serviceUrl": "https://livetest-service.thesalesjourney.io/api"
    }
}


console.log('config', config)
console.log('ENVIRONMENT', process.env.REACT_APP_ENVIRONMENT)

module.exports = config;