import React,{useState,useRef,useEffect} from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from './components/common/Loading';
import config from "./services/config.js";
import axios from "axios";
import { getTokenSession } from "./utils/common";
import { toast } from "react-toastify";
import { Progress } from 'antd';


function convertQueryParamsToObject(queryString) {
  const queryParamsObject = {};
  // Check if the first character is '&', '?', or any other character that is not part of the actual query parameters, and remove it
  if (queryString.startsWith('&') || queryString.startsWith('?')) {
      queryString = queryString.substring(1);
  }

  // Split the query string into individual key-value pairs
  const queryParams = queryString.split('&');

  // Iterate over each key-value pair
  queryParams.forEach((param) => {
      // Split each pair on the '=' to separate the key and value
      const [key, value] = param.split('=');
      // Decode the key and value, and add them to the object
      queryParamsObject[decodeURIComponent(key)] = decodeURIComponent(value);
  });

  return queryParamsObject;
}


function CalenderTest() {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  let GoogleSync = localStorage.getItem(`GoogleSync`);
  let syn_redirect_storage= localStorage.getItem("SyncRedirectPath")
  let syn_redirect_path=syn_redirect_storage? syn_redirect_storage.substring(1):"";
  let location = useLocation();
    const isComponentMounted = useRef(true);
    // console.log(location.search.replace('?', '&'))
    const { id } = useParams();
    useEffect(() => {
      const interval = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress === 60) {
            clearInterval(interval);
            return prevProgress;
          } else {
            return prevProgress + 10; // Increase the progress by 10%
          }
        });
      }, 1000);
        if (isComponentMounted.current) {
            axios.defaults.headers = {
                "Content-Type": "multipart/form-data",
                authentication: `${getTokenSession()}`,
              };
              console.log('GoogleSync', GoogleSync)
              console.log('location.search', location.search)
              const dataToSent = convertQueryParamsToObject(location.search)
              // axios.get(`${config.apiEndPoint}getGoogleSync/&id=${GoogleSync}${location.search  }`)
              axios.post(`${config.apiEndPoint}getGoogleSync`, {id: GoogleSync, ...dataToSent})
                .then((response) => {
                  console.log('response', response)
                  setProgress(100)
                  if(response.data.redirect_url === true) {
                    localStorage.removeItem(`GoogleSync`)
                    navigate(`/${config.ddemoss}${syn_redirect_path}`)
                    toast.success(response.data.message)
                    localStorage.removeItem(`SyncRedirectPath`)

                  }else if(response.data?.return_calendar_id){
                    // window.location.href=response.data

                    axios.get(`${config.apiEndPoint}getGoogleSync/&id=${response.data?.return_calendar_id}&type=calendar`)
                    .then((response) => {
                        window.location.href = `${response.data}`;
                    })
                    .catch((err) => {
                      console.log('eerr', err)
                    })
                  }
                 
                })
                .catch((err) => {
                  console.log('eerr', err)
                })
          }
          return () => {
            isComponentMounted.current = false;
          };
    }, [])  
      
  return (
    // <div className='progesst'>
    //     <h2>{progress === 100 ? "Complete" : "Please Wait..."}</h2>
    // <Progress percent={progress} />
    // </div>
    <span className="span_loader">
      <i className="fa fa-pulse fa-spinner"></i>
    </span>
  )
}

export default CalenderTest