import React, { useState } from "react";
export const MainOnlineStatusContext = React.createContext();

const MainOnlineStatusProvider = ({ children }) => {
  const [meetingOnlineStatus, setmeetingOnlineStatus] = useState(false);
  const addmeetingOnlineStatus = (newValue) => {
    setmeetingOnlineStatus(newValue);
  };
  return (
    <MainOnlineStatusContext.Provider value={{ meetingOnlineStatus, addmeetingOnlineStatus }}>
      {children}
    </MainOnlineStatusContext.Provider>
  );
};

export default MainOnlineStatusProvider;
